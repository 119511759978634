<script lang="ts" setup>
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Ref, computed, onBeforeUnmount, onMounted, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { isValidURL } from '@ankor-io/common/url/isValidURL'
import { HiOutlineUsers } from '@ankor-io/icons/hi_outline'
import {
  OutlineCabinDoorIcon,
  OutlineChevronLeft,
  OutlineChevronRight,
  OutlineCrew,
  OutlineShip,
} from '@ankor-io/icons/outline'

import Spinner from '@/components/Spinner.vue'
import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { StowageService, useStowageService } from '@/services/stowage/StowageService'

import { YachtSummaryCardLayoutTemplate, YachtSummaryCardSectionData } from './types/types'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The yacht summary card data
   */
  data: YachtSummaryCardSectionData
  /**
   * The yacht layout
   */
  layout: YachtSummaryCardLayoutTemplate
  /**
   * The proposal document
   */
  document: JsonProposal
  isHydrating: boolean
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()
const copyingAssets: Ref<boolean> = ref(false)
const stowageService: StowageService = useStowageService()

props.lifecycle({
  onHydrated: () => {
    copyingAssets.value = true
    stowageService
      .copyFiles(props.data.uri, props.proposalUri, ['assets'])
      .then(() => (copyingAssets.value = false))
      .catch((err) => {
        copyingAssets.value = false
        console.error(err)
      })
  },
} as unknown as EditableLifecycleHooks)

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<YachtSummaryCardSectionData>): void
}>()

const modules = [Pagination]

const descriptionLines: Ref<number | null> = ref(null)
const isClamped: Ref<boolean> = ref(true)
const toggleClamp = () => {
  isClamped.value = !isClamped.value
}

const url = computed(() => {
  if (!isValidURL(props.data.yachtButton?.link)) {
    return 'http://' + props.data.yachtButton?.link
  } else {
    return props.data.yachtButton?.link
  }
})

const handleResize = () => {
  const LINE_HEIGHT = 24 // The height of a single line in the description
  const description = document.getElementById('yacht-card-info-description')
  const descriptionRects = description?.getClientRects()
  if (descriptionRects?.item(0)?.height === 0) {
    descriptionLines.value = 0
  } else {
    descriptionLines.value = descriptionRects?.item(0)?.height! / LINE_HEIGHT || 0
  }
}

onMounted(() => {
  setTimeout(() => {
    handleResize()
  }, 0)
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

const currentImg: Ref<number> = ref(0)
const updateImg = (to: number): void => {
  const element = document.getElementById(to.toString())
  element?.scrollIntoView({ inline: 'center', behavior: 'smooth' })
  currentImg.value = to
}

const updateValue = (dataKey: string, value: string) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      [dataKey]: value,
    },
  })

  handleResize()
}

const showYachtProfile = (): boolean => {
  if (
    props.layout.options.yachtType ||
    props.layout.options.length ||
    props.layout.options.buildYearRefitYear ||
    props.layout.options.cruiseSpeed ||
    props.layout.options.numDecks ||
    props.layout.options.topSpeed ||
    props.layout.options.flag
  ) {
    return true
  }

  return false
}
</script>

<template>
  <div
    v-if="(props.isHydrating && Object.keys(props.data).length === 0) || !props.layout.options"
    class="flex items-center justify-center h-[70vh] @sm:h-[calc(100vh-7rem)]"
  >
    <Spinner />
  </div>
  <!--Yacht summary section-->
  <div
    v-else
    class="max-w-360 mx-auto p-4 @sm:p-8"
    @keyup.left="updateImg(currentImg - 1)"
    @keyup.right="updateImg(currentImg + 1)"
  >
    <div class="relative z-10 mx-4 @sm:mx-8">
      <!--Yacht title-->
      <div
        class="max-w-5xl w-full flex justify-between mx-auto bg-white rounded-lg p-8 shadow-lg border border-gray-200 dark:border-gray-600 flex-col @lg:flex-row gap-y-4 @lg:gap-y-0"
      >
        <!--Left side - Yacht name and details-->
        <div class="flex flex-col gap-y-4 items-center @lg:items-start">
          <h1 class="flex text-[2rem]">
            <SingleLineTextEditor
              :value="props.data.name"
              placeholder="Yacht name"
              @update:value="updateValue('name', $event)"
            />
          </h1>
          <div class="flex gap-x-5 items-center flex-wrap justify-center">
            <div v-if="props.layout.options?.length" class="flex gap-x-2">
              <OutlineShip class="w-5 stroke-theme-primary stroke-1" />
              <div class="flex">
                <SingleLineTextEditor
                  :value="props.data.length"
                  placeholder="Length"
                  @update:value="updateValue('length', $event)"
                />
              </div>
            </div>
            <div v-if="props.layout.options.numPassengers" class="flex gap-x-2">
              <HiOutlineUsers class="w-5 stroke-theme-primary stroke-1" />
              <SingleLineTextEditor
                :value="props.data.numPassengers"
                placeholder="Guest capacity"
                @update:value="updateValue('numPassengers', $event)"
              />
            </div>
            <div v-if="props.layout.options.maxCrew" class="flex gap-x-2">
              <OutlineCrew class="w-5 stroke-theme-primary stroke-1" />
              <SingleLineTextEditor
                :value="props.data.maxCrew"
                placeholder="Crews"
                @update:value="updateValue('maxCrew', $event)"
              />
            </div>
            <div v-if="props.layout.options.numCabins" class="flex gap-x-2">
              <OutlineCabinDoorIcon class="w-5 stroke-theme-primary stroke-1" />
              <SingleLineTextEditor
                :value="props.data.numCabins"
                placeholder="Cabins"
                @update:value="updateValue('numCabins', $event)"
              />
            </div>
          </div>
        </div>
        <!-- Right side - Yacht cost details -->
        <div
          v-if="props.layout.options.costAndFees"
          class="@lg:max-w-56 flex flex-col items-center @lg:items-end gap-y-2 @lg:gap-y-3.5"
        >
          <h1 class="text-lg">
            <SingleLineTextEditor
              :value="props.data.price"
              placeholder="Enter charter fee"
              @update:value="updateValue('price', $event)"
            />
          </h1>
          <h3 class="flex text-center @lg:text-right">
            <MultiLineTextEditor
              placeholder="Pricing caption here..."
              :value="props.data.priceSubtext"
              @update:value="updateValue('priceSubtext', $event)"
            />
          </h3>
        </div>
      </div>
    </div>

    <!-- Yacht images carousel -->
    <div v-if="props.layout.options.galleryCarousel" class="relative -mt-4 @lg:-mt-20">
      <!-- On desktop, the arrows show, and for mobile it changes to swiperjs swiping -->
      <div v-if="!copyingAssets" class="grow w-full hidden @sm:block">
        <div v-if="props.data.images?.length > 0" class="w-full h-full">
          <div
            v-for="(image, index) in props.data.images"
            class="w-full h-full"
            :id="`yachtSummaryCardImages-${index}`"
            :key="index"
            :class="{ hidden: currentImg !== index }"
          >
            <AssetViewerPlaceholder class="rounded-lg object-cover" :filename="image" :uri="props.proposalUri" />
          </div>
        </div>
        <AssetViewerPlaceholder v-else id="index:0" class="aspect-video" />
      </div>
      <div v-else class="grow w-full h-[50.625rem] items-center justify-center hidden @sm:flex">
        <Spinner />
      </div>
      <!-- Arrows for desktop -->
      <div v-if="props.data.images?.length > 1" class="hidden @sm:block">
        <!-- Previous -->
        <button
          v-if="currentImg > 0"
          class="absolute top-1/2 -translate-y-1/2 ml-12"
          @click="updateImg(currentImg - 1)"
        >
          <span
            class="inline-flex items-center justify-center rounded-full w-10 h-10 bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none"
          >
            <OutlineChevronLeft class="text-white w-5" />
            <span class="sr-only">Previous</span>
          </span>
        </button>
        <!-- Next -->
        <button
          v-if="currentImg < props.data.images.length - 1"
          class="absolute top-1/2 -translate-y-1/2 right-0 mr-12"
          @click="updateImg(currentImg + 1)"
        >
          <span
            class="inline-flex items-center justify-center rounded-full w-10 h-10 bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none"
          >
            <OutlineChevronRight class="text-white w-5" />
            <span class="sr-only">Previous</span>
          </span>
        </button>
      </div>
      <div class="block @sm:hidden">
        <Swiper
          v-if="props.data.images?.length > 0"
          class="cursor-grab active:cursor-grabbing"
          :space-between="12"
          :modules="modules"
          :pagination="{
            dynamicBullets: true,
            dynamicMainBullets: 6,
          }"
          :style="['--swiper-pagination-color: white', '--swiper-pagination-bullet-inactive-color: #D1D5DB']"
        >
          <Spinner v-if="copyingAssets" />
          <template v-else>
            <SwiperSlide v-for="image in props.data.images" class="!h-auto w-full" :key="image">
              <AssetViewerPlaceholder
                class="rounded-lg h-full object-cover"
                :filename="image"
                :uri="props.proposalUri"
              />
            </SwiperSlide>
          </template>
        </Swiper>
        <AssetViewerPlaceholder v-else />
      </div>
    </div>

    <!-- Yacht profile and description -->
    <div
      class="mt-8 flex gap-x-4 @lg:gap-x-8 gap-y-5 flex-col @sm:flex-row px-4 @sm:px-0"
      :class="{ 'justify-center': !showYachtProfile() && !props.layout.options.descriptionAndNotes }"
    >
      <!-- Left side - Yacht profile -->
      <div
        v-if="showYachtProfile()"
        class="@sm:max-w-[30rem] w-full grid text-sm gap-x-4 @lg:gap-x-16 h-min gap-y-2 grid-cols-1 @sm:grid-cols-2"
      >
        <div v-if="props.layout.options.yachtType" class="flex gap-x-1 items-center">
          <span class="font-bold">Yacht type:</span>
          <SingleLineTextEditor
            :value="props.data.yachtType || ''"
            placeholder="Enter value"
            @update:value="updateValue('yachtType', $event)"
          />
        </div>
        <div v-if="props.layout.options.length" class="flex gap-x-1 items-center">
          <span class="font-bold">Length:</span>
          <div class="flex">
            <SingleLineTextEditor
              :value="props.data.length"
              placeholder="Enter value"
              @update:value="updateValue('length', $event)"
            />
          </div>
        </div>
        <div v-if="props.layout.options.buildYearRefitYear" class="flex gap-x-1 items-center">
          <span class="font-bold">Build year:</span>
          <SingleLineTextEditor
            :value="props.data.buildYear"
            placeholder="Enter value"
            @update:value="updateValue('buildYear', $event)"
          />
        </div>
        <div v-if="props.layout.options.cruiseSpeed" class="flex gap-x-1 items-center">
          <span class="font-bold">Cruising speed:</span>
          <SingleLineTextEditor
            :value="props.data.cruiseSpeed || ''"
            placeholder="Enter value"
            @update:value="updateValue('cruiseSpeed', $event)"
          />
        </div>
        <div v-if="props.layout.options.numDecks" class="flex gap-x-1 items-center">
          <span class="font-bold">Decks:</span>
          <SingleLineTextEditor
            :value="props.data.numDecks"
            placeholder="Enter value"
            @update:value="updateValue('numDecks', $event)"
          />
        </div>
        <div v-if="props.layout.options.topSpeed" class="flex gap-x-1 items-center">
          <span class="font-bold">Top speed:</span>
          <SingleLineTextEditor
            :value="props.data.topSpeed || ''"
            placeholder="Enter value"
            @update:value="updateValue('topSpeed', $event)"
          />
        </div>
        <div v-if="props.layout.options.buildYearRefitYear" class="flex gap-x-1 items-center">
          <span class="font-bold">Refit year:</span>
          <SingleLineTextEditor
            :value="props.data.refitYear"
            placeholder="Enter value"
            @update:value="updateValue('refitYear', $event)"
          />
        </div>
        <div v-if="props.layout.options.flag" class="flex gap-x-1 items-center">
          <span class="font-bold">Flag:</span>
          <SingleLineTextEditor
            :value="props.data.flag || ''"
            placeholder="Enter value"
            @update:value="updateValue('flag', $event)"
          />
        </div>
      </div>

      <!-- Middle - Yacht description -->
      <div
        v-if="props.layout.options.descriptionAndNotes"
        class="relative w-full flex-2"
        :class="{ 'border-gray-300 border-l-0 @sm:border-l @sm:pl-4 @lg:pl-8': !showYachtProfile }"
      >
        <p
          id="yacht-card-info-description"
          :class="isClamped && descriptionLines! > 5 ? 'line-clamp-6' : 'line-clamp-none'"
        >
          <MultiLineTextEditor
            placeholder="Enter yacht description here"
            :value="props.data.description || ''"
            @update:value="updateValue('description', $event)"
          />
        </p>
        <div
          v-if="descriptionLines! >= 5 && isClamped"
          class="crossfade-gradient pointer-events-none h-full w-full z-10 absolute top-0"
        ></div>
        <div
          v-if="descriptionLines! >= 5"
          class="absolute z-20 w-full text-center text-theme-primary cursor-pointer"
          @click="toggleClamp"
        >
          {{ isClamped ? 'Read more' : 'Read less' }}
        </div>
      </div>

      <!-- Right side - Yacht button-->
      <button
        v-if="props.layout.options.yachtButton"
        class="yacht-button min-w-[12.938rem] w-min hidden @lg:flex justify-center break-all h-min text-center text-white font-medium text-sm p-4 rounded-lg"
        :class="!props.data.yachtButton?.link ? 'pointer-events-none bg-gray-200' : 'bg-theme-primary'"
      >
        <a class="break-all" target="_blank" :href="url || ''">
          {{ props.data.yachtButton?.title || 'Yacht Brochure' }}
        </a>
      </button>
    </div>

    <!-- Below profile and description - Yacht button -->
    <div
      v-if="props.layout.options.yachtButton"
      class="yacht-button block @lg:hidden min-w-[20rem] h-min w-fit mx-auto mt-8 text-sm text-center text-white font-medium p-4 rounded-lg"
      :class="!props.data.yachtButton?.link ? 'pointer-events-none bg-gray-200' : 'bg-theme-primary'"
    >
      <a class="break-all" target="_blank" :href="url || ''">
        {{ props.data.yachtButton?.title || 'Yacht Brochure' }}
      </a>
    </div>
    <hr class="border-t-2 mx-auto mt-8 border-theme-shading w-28" />
  </div>
</template>
<style scoped>
.crossfade-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 15.04%,
    v-bind('props.document.template.theme.color.background') 82.09%
  );
}
</style>
