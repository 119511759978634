import { BiArgsFunction } from '../lang/functional.types'
import { LoggerConfigurations } from './Config.types'

/**
 * The logger interface
 */
export interface Logger {
  /**
   * Debug level log
   */
  debug: BiArgsFunction<string, Object, void>
  /**
   * Info level log
   */
  info: BiArgsFunction<string, Object, void>
  /**
   * Warning level log
   */
  warn: BiArgsFunction<string, Object, void>
  /**
   * Error level log
   */
  error: BiArgsFunction<string, Object, void>
}

/**
 * The different log levels
 */
export enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

/**
 * Define the log message structure
 */
export type Log = {
  message: string
  file: string
  line: string
  logLevel: LogLevel
  timestamp: string
  args: any
}

/**
 * Transporter interface
 */
export interface Transporter {
  /**
   * Transport a log to a target destination
   * 
   * @param log the log to transport
   */
  transport(log: Log): void
}

/**
 * The different transport types available
 */
export enum TransporterType {
  DEFAULT = 'default',
}

/**
 * Implementation of the default transporter that simply takes
 * a log and prints it to the console.
 */
export class DefaultTransporter implements Transporter {
  transport(log: Log) {
    switch (log.logLevel) {
      case LogLevel.DEBUG:
        console.debug(log)
        break
      case LogLevel.INFO:
        console.log(log)
        break
      case LogLevel.WARN:
        console.warn(log)
        break
      case LogLevel.ERROR:
        console.error(log)
        break
      default:
        break
    }
  }
}

/**
 * Logger implementation
 */
export class LoggerImpl implements Logger {
  /**
   * The name of the file that's requesting this logger instance
   */
  private filename: string
  /**
   * The transporter used by this logger instance
   */
  private transporter: Transporter
  
  constructor(filename: string, config: LoggerConfigurations) {
    this.filename = filename
    this.transporter = config.transporter
  }

  /**
   * Create a log message from the arguments and invokes the transporter to delegate
   * logging
   * 
   * @param logLevel the log level
   * @param message the message
   * @param arg2 a vararg of objects to print with the log
   */
  log(logLevel: LogLevel, message: string, ...arg2: Object[]): void {
    // reduce all the arguments into an object
    // arguments with repeting key values will be overwritten
    const args = arg2 && arg2.length && (arg2[0] as Array<any>).length ? Array.from(arg2)
      .map((entry: any) => {
        return entry
      })
      .reduce((acc, comb) => {
        return Object.assign(acc, comb)
      })
      .reduce((acc: any, comb: any) => {
        return Object.assign(acc, comb)
      }) : {}
    // work out the line number
    const e: Error = new Error()
    const stack: string[] | '' = e.stack ? e.stack.toString().split(/\r\n|\n/) : ''
    const log: Log = {
      message: message,
      file: this.filename,
      line: stack.length && stack.length >= 4 ? stack[3].trim() : 'unkown',
      logLevel: logLevel,
      timestamp: new Date(Date.now()).toISOString(),
      args: args,
    }
    // let the transporter figure out where this log goes
    this.transporter.transport(log)
  }

  debug(arg1: string, ...arg2: Object[]): void {
    this.log(LogLevel.DEBUG, arg1, arg2)
  }
  
  info(arg1: string, ...arg2: Object[]): void {
    this.log(LogLevel.INFO, arg1, arg2)
  }
  
  warn(arg1: string, ...arg2: Object[]): void {
    this.log(LogLevel.WARN, arg1, arg2)
  }
  
  error(arg1: string, ...arg2: Object[]): void {
    this.log(LogLevel.ERROR, arg1, arg2)
  }
}

/**
 * Create a map of singletong {@link Transporter} binding
 */
const transporterBinding: Map<string, Transporter> = new Map<string, Transporter>()
transporterBinding.set(TransporterType.DEFAULT, new DefaultTransporter())

/**
 * Method to get a logger instance
 */
export const LoggerFactory = {
  /**
   * Provides a {@link Logger} instance with a transporter. 
   * Loads the {@link DefaultTransporter} when the transporterType argument
   * is not provided.
   * 
   * @param filename the name of the file requesting a logger instance
   * @param transporterType the type of transporter to use with this logger
   * @returns 
   */
  getLogger: (filename: string, transporterType?: TransporterType): Logger => {
    // load the singleton transporter. If that is not defined then load the default one
    const transporter = transporterType ? transporterBinding.get(transporterType) : transporterBinding.get(TransporterType.DEFAULT)
    if (!transporter) {
      throw new Error(`Cannot construct Logger. Transporter not found for type: ${transporterType || TransporterType.DEFAULT}`)
    }

    return new LoggerImpl(filename, { transporter: transporter }) as Logger
  },
}
