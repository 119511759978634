<script lang="ts" setup>
import { ChangeEvent, State } from '@ankor-io/common/events/Editor'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { HeroTitleData } from '@/sections/hero/types/types'

type Props = {
  data: HeroTitleData
  id: string
  state: State
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<any>): void
}>()

const update = (event: { value: HeroTitleData }): void => {
  emit('update:value', { sectionId: props.id, data: event.value })
}
</script>
<template>
  <div
    class="hero-title max-w-7xl w-full flex flex-col gap-y-4 z-10 absolute left-1/2 -translate-x-1/2 -top-24 bg-white rounded-lg @sm:rounded-2xl p-8 @sm:p-4 shadow-lg text-center border border-gray-200 dark:border-gray-600"
  >
    <h1 class="hero-title-heading flex justify-center text-3xl sm:text-[4rem]">
      <SingleLineTextEditor
        placeholder="Enter a title"
        :value="props.data.heading"
        @update:value="update({ value: { heading: $event, subheading: props.data.subheading } })"
      />
    </h1>
    <h3 class="hero-title-subheading flex justify-center text-base @sm:text-xl">
      <SingleLineTextEditor
        placeholder="Enter a sub heading"
        :value="props.data.subheading"
        @update:value="update({ value: { subheading: $event, heading: props.data.heading } })"
      />
    </h3>
    <hr class="border-2 border-theme-primary w-20 self-center" />
  </div>
</template>
