<script lang="ts" setup>
import { initPopovers } from 'flowbite'
import { onMounted } from 'vue'

import { DiscountTypeValues } from '@ankor-io/common/vessel/types'
import { OutlineInformationCircle } from '@ankor-io/icons/outline'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

import {
  InputAmountsTaxedValues,
  LineItemPricing,
  VesselPricingLayoutTemplate,
  VesselPricingSectionData,
} from './types/types'

type Props = {
  id: string
  proposalUri: string
  data: VesselPricingSectionData
  layout: VesselPricingLayoutTemplate
}

const props = defineProps<Props>()

const inputAmountTaxedOptions = [
  { label: 'Tax Inclusive', value: InputAmountsTaxedValues.INCLUSIVE },
  { label: 'Tax Exclusive', value: InputAmountsTaxedValues.EXCLUSIVE },
  { label: 'No Tax', value: InputAmountsTaxedValues.NONE },
]

const getTaxRateLabel = (taxRate: string): string => {
  return inputAmountTaxedOptions.find((option) => option.value === taxRate)?.label ?? '-'
}

const pricingLineColumns = [
  {
    key: 'item',
    label: 'Item',
    helpText:
      'The name and/or description of the item, for example "Charter Fee" or "Advanced Provisioning Allowance: the APA is an advance payment that covers variable expenses that can arise during your charter"',
  },
  {
    key: 'conditions',
    label: 'Conditions',
    helpText:
      'A description of any conditions related to the item, for example "Half Board includes seven breakfasts, four lunches, and three dinners in your charter fee"',
  },
  {
    key: 'quantity',
    label: 'Quantity',
    helpText:
      'The number or percentage of the item, for example APA may be "35%" or a Beverage Package for 20 guests would be "20". The default Quantity is "1"',
  },
  {
    key: 'unitPrice',
    label: 'Price',
    helpText:
      'The price per item, for example a $50k Charter Fee would be "50000", or a day beverage package per guest may be "99.95"',
  },
  {
    key: 'discount',
    label: 'Discount',
    helpText:
      'Any discount applied to the item, which can be a percentage or numeric amount, for example "5%" or "5000"',
  },
  {
    key: 'taxRate',
    label: 'Tax Rate',
    helpText: 'The tax type and percentage that applies, for example "VAT 20%"',
  },
  {
    key: 'amount',
    label: 'Amount',
    helpText: '',
  },
]

onMounted(() => initPopovers())

const getLineItemAttribute = (lineItemPricing: LineItemPricing, attribute: string): string => {
  if (attribute === 'quantity') {
    const suffix = lineItemPricing.displayQuantityAsPercent ? '%' : ''
    const quantityToDisplay = lineItemPricing.displayQuantityAsPercent
      ? (lineItemPricing[attribute] || 0) * 100
      : lineItemPricing[attribute]
    return lineItemPricing[attribute] ? `${Math.round(quantityToDisplay * 100) / 100}${suffix}` : '-'
  }

  if (attribute === 'taxRate') {
    return lineItemPricing[attribute]
      ? `${lineItemPricing[attribute]?.label} ${
          Math.round((lineItemPricing[attribute]?.value || 0) * 100 * 100) / 100
        }%`
      : '-'
  }

  if (attribute === 'discount') {
    const value =
      lineItemPricing[attribute]?.type === DiscountTypeValues.AMOUNT
        ? lineItemPricing[attribute]?.value
        : Math.round((lineItemPricing[attribute]?.value || 0) * 100 * 100) / 100
    return lineItemPricing[attribute]
      ? `${value}${lineItemPricing[attribute]?.type === DiscountTypeValues.PERCENTAGE ? '%' : ''}`
      : '-'
  }

  if (attribute === 'amount' || attribute === 'unitPrice') {
    return (Number(lineItemPricing[attribute]) / 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  if (attribute === 'item' || attribute === 'conditions') {
    return lineItemPricing[attribute]?.toString() ?? ''
  }

  return ''
}
</script>
<template>
  <div id="vessel-pricing-section">
    <h3 class="font-bold text-3xl">
      <SingleLineTextEditor :is-editable="false" :value="props.layout.options?.pricingLabel" />
    </h3>

    <div class="flex flex-col md:flex-row justify-between text-sm gap-2">
      <div class="basis-3/4">
        Note:
        <MultiLineTextEditor :is-editable="false" :value="props.data.pricing?.note || ''" />
      </div>

      <div class="grid grid-cols-2 h-fit self-end gap-x-1">
        Prices are:
        <span class="font-semibold text-gray-500">
          {{ props.data.pricing.unit || 'WEEK' }}
        </span>

        Currency:
        <span class="font-semibold text-gray-500">
          {{ props.data.pricing.currency }}
        </span>

        Amounts are:
        <span class="font-semibold text-gray-500">
          {{ getTaxRateLabel(props.data.pricing.inputAmountTaxed) }}
        </span>
      </div>
    </div>

    <div
      v-if="props.layout.options.enabledPricingColumns?.length"
      class="relative overflow-x-auto overflow-y-hidden drop-shadow-sm sm:rounded-lg mx-auto my-4 border border-gray-200 dark:border-gray-600"
    >
      <table class="w-full text-sm text-left whitespace-nowrap text-gray-700" aria-describedby="Pricing">
        <thead class="text-xs uppercase" :style="{ background: props.layout.options.pricingHeaderBackground }">
          <tr>
            <template
              v-for="(pricingLineColumn, index) of pricingLineColumns"
              :key="`${pricingLineColumn.key}-${index}`"
            >
              <th
                v-if="props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key)"
                scope="col"
                class="px-2 py-4"
                :class="[{ 'border-r border-gray-200': index !== pricingLineColumns.length - 1 }]"
              >
                <div
                  class="flex items-center gap-x-1"
                  :class="{
                    'opacity-20':
                      pricingLineColumn.key === 'taxRate' &&
                      props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.NONE,
                  }"
                >
                  {{ pricingLineColumn.label }}
                  <button
                    v-if="pricingLineColumn.helpText"
                    type="button"
                    data-popover-placement="bottom"
                    :data-popover-target="`popover-${pricingLineColumn.key}`"
                  >
                    <OutlineInformationCircle class="size-4" />
                  </button>
                </div>
                <div
                  data-popover
                  role="tooltip"
                  class="absolute z-50 invisible inline-block text-xs normal-case font-normal whitespace-normal text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0"
                  :id="`popover-${pricingLineColumn.key}`"
                >
                  <div class="max-w-[25rem] p-2">{{ pricingLineColumn.helpText }}</div>
                  <div data-popper-arrow></div>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody class="tracking-wider text-right" :style="{ background: props.layout.options.pricingBodyBackground }">
          <tr
            v-for="(lineItem, lineItemIndex) of props.data.pricing.lineItems"
            class="whitespace-nowrap border-t h-12 border-gray-200"
            :key="`${lineItem.item}-${lineItemIndex}`"
          >
            <template
              v-for="(pricingLineColumn, index) of pricingLineColumns"
              :key="`${index}:${pricingLineColumn.key}`"
            >
              <td
                v-if="props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key)"
                class="ml-3 p-2"
                :key="`${pricingLineColumn.key}-${lineItemIndex}-${index}`"
                :class="[
                  { 'border-r border-gray-200': index !== pricingLineColumns.length - 1 },
                  { 'text-left': ['item', 'conditions', 'taxRate'].includes(pricingLineColumn.key) },
                  { 'w-48': pricingLineColumn.key === 'unitPrice' },
                  {
                    'opacity-20':
                      pricingLineColumn.key === 'taxRate' &&
                      props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.NONE,
                  },
                ]"
              >
                <div v-if="pricingLineColumn.key === 'unitPrice'">
                  <span class="px-2 h-6 text-sm text-right rounded">
                    {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                  </span>
                </div>
                <SingleLineTextEditor
                  v-else-if="['item', 'conditions'].includes(pricingLineColumn.key)"
                  :is-editable="false"
                  :value="getLineItemAttribute(lineItem, pricingLineColumn.key)"
                />
                <div
                  v-else-if="
                    pricingLineColumn.key === 'taxRate' &&
                    props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.NONE
                  "
                >
                  -
                </div>
                <div v-else-if="pricingLineColumn.key === 'quantity'" class="cursor-default">
                  {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                </div>
                <div v-else-if="pricingLineColumn.key === 'taxRate'" class="cursor-default">
                  {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                </div>
                <div v-else-if="pricingLineColumn.key === 'discount'" class="cursor-default">
                  {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                </div>
                <div v-else-if="pricingLineColumn.key === 'amount'" class="text-right cursor-default pr-2">
                  {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="grid gap-x-4 gap-y-1 justify-self-end w-fit justify-items-end grid-cols-2 text-right">
      <template v-if="props.data.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
        <div class="opacity-60">Subtotal:</div>
        <div>
          <span class="font-bold">
            {{
              props.data.pricing.subTotal
                ? (props.data.pricing.subTotal / 100).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0
            }}
          </span>
          {{ props.data.pricing.currency }}
        </div>
      </template>

      <div v-if="props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.INCLUSIVE" class="opacity-60">
        Includes Tax:
      </div>
      <div v-if="props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.EXCLUSIVE" class="opacity-60">
        Total Tax:
      </div>
      <div v-if="props.data.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
        <span class="font-bold">
          {{
            props.data.pricing.totalTax
              ? (props.data.pricing.totalTax / 100).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }}
        </span>
        {{ props.data.pricing.currency }}
      </div>

      <div class="opacity-60">Total:</div>
      <div :class="{ 'border-t': props.data.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE }">
        <span class="font-bold">
          {{
            props.data.pricing.total
              ? (props.data.pricing.total / 100).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }}
        </span>
        {{ props.data.pricing.currency }}
      </div>
    </div>
  </div>
</template>
