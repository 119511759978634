<script setup lang="ts">
import { Ref, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { State } from '@ankor-io/common/events/Editor'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

type Props = {
  /**
   * The place name
   */
  name: string
  /**
   * The place description
   */
  description: string
  /**
   * The location image
   */
  image: string
  /**
   * Represents the segment label
   */
  label: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: { field: string; value: string }): void
}>()

const stateRef: Ref<State> = ref(State.READ)
const isClamped: Ref<boolean> = ref(true)

const toggleClamp = () => {
  isClamped.value = !isClamped.value
}
</script>
<template>
  <div class="flex flex-row w-full bg-gray-50 rounded-lg @sm:rounded-2xl">
    <div
      class="bg-white p-4 border border-gray-200 dark:border-gray-600 shadow-lg rounded-lg @sm:rounded-2xl min-w-[18.75rem] max-w-[18.75rem] max-h-61"
    >
      <AssetViewerPlaceholder class="w-full h-full object-cover" :url="`/media/${props.image}`" />
    </div>
    <div class="px-8 py-4 flex flex-col gap-y-4">
      <div class="flex flex-wrap gap-x-1 gap-y-2 mt-4">
        <h1 class="!text-3xl font-semibold !text-gray-300 leading-6 cursor-default">{{ props.label }}.</h1>
        <h1 class="!text-3xl font-normal location-card-heading self-center break-all">
          <SingleLineTextEditor
            placeholder="Enter a place name"
            :value="props.name"
            @update:value="emit('update:value', { field: 'name', value: $event })"
          />
        </h1>
      </div>
      <hr class="w-20 border-2 border-theme-primary" />
      <p :class="isClamped && stateRef === State.READ ? 'line-clamp-4' : 'line-clamp-none'">
        <MultiLineTextEditor
          class="location-card-body"
          placeholder="Enter a place description"
          :value="props.description"
          @click.stop="stateRef = State.EDIT"
          @on:blur="stateRef = State.READ"
          @update:value="emit('update:value', { field: 'description', value: $event })"
        />
      </p>
      <div v-if="stateRef === State.READ && props.description" class="text-xs">
        <span v-if="isClamped" class="cursor-pointer text-theme-primary" @click="toggleClamp"> Show more </span>
        <span v-else class="cursor-pointer text-theme-primary" @click="toggleClamp"> Show less </span>
      </div>
    </div>
  </div>
</template>
<style>
.location-card-heading .ProseMirror p {
  line-height: 1.5rem; /* TailwindCSS would not override the text editors line height */
}

.location-card-body .ProseMirror p {
  line-height: 1.375; /* TailwindCSS would not override the text editors line height */
}
</style>
