<script setup lang="ts">
import { Ref, defineComponent, inject, ref, watch } from 'vue'
import draggable from 'vuedraggable'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { OutlineDelete } from '@ankor-io/icons/outline'
import { SolidPlus } from '@ankor-io/icons/solid'
import { _requestSignedUrl, uploadFile } from '@ankor-io/wheelhouse/src/services/assets/upload'

import HelpTip from '@/components/HelpTip.vue'
import AssetUploader from '@/components/asset-uploader/AssetUploader.vue'
import DeleteConfirmation, { DeleteConfirmationDataProps } from '@/components/modal-content/DeleteConfirmation.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { AuthenticationContext } from '@/iam/types'
import { useModal } from '@/modal/useModal'

type Props = {
  proposalUri: string
  images: string[]
}

const props = defineProps<Props>()

defineComponent(draggable)

const emit = defineEmits<{
  (e: 'update:value', value: { field: string; value: string }): void
  (e: 'delete:value', value: { value: string; index: number }): void
}>()

const uploadState: Ref<string | null> = ref(null)
const imagesRef: Ref<string[]> = ref(props.images || [])

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const { isOpen, updateModalState } = useModal()
const modalValue: Ref<DeleteConfirmationDataProps | null> = ref(null)
const imageToDelete: Ref<{ index: number; image: string } | null> = ref(null)

// ----- Drag Items ----- //

const dragging: Ref<boolean> = ref(false)

/**
 * Add item to designated position
 */
const reorderList = (): void => {
  const images = ObjectUtil.deepCopy(imagesRef.value)
  emit('update:value', { field: images, value: images })
}

const handleUploadFile = async (file: File | File[]): Promise<boolean> => {
  uploadState.value = 'Progress'
  const token: string | undefined = await authenticationContext.getToken()
  const filesToUpload = Array.isArray(file) ? file : [file]

  const results = filesToUpload.map(async (fileToUpload: File) => {
    const { mediaUri, signedUrl } = await _requestSignedUrl(props.proposalUri, token!)
    const uploaded: boolean = await uploadFile(signedUrl, fileToUpload)

    const fileReader = new FileReader()
    fileReader.readAsDataURL(fileToUpload)
    if (uploaded) {
      uploadState.value = 'Success'
      emit('update:value', { field: 'images', value: mediaUri })

      setTimeout(() => {
        uploadState.value = null
      }, 3000)

      return Promise.resolve(true)
    } else {
      uploadState.value = 'Failed'
      return Promise.resolve(false)
    }
  })

  return results.reduce(
    async (a: Promise<boolean>, b: Promise<boolean>) => (await a) && (await b),
    Promise.resolve(true),
  )
}

const deleteImage = (event: { value: string; index: number }): void => {
  emit('delete:value', {
    value: event.value,
    index: event.index,
  })
}

const openDeleteConfirmation = (index: number, image: string) => {
  modalValue.value = {
    message: 'You are about to remove this image from this presentation. Are you sure you want to continue?',
    labelCancel: 'No, keep the image',
    labelConfirm: 'Yes, delete it',
  }

  imageToDelete.value = { index, image }
  updateModalState(true)
}

const confirmDelete = () => {
  deleteImage({
    value: imageToDelete.value?.image!,
    index: imageToDelete.value?.index!,
  })

  closeModal()
}

const closeModal = () => {
  modalValue.value = null
  imageToDelete.value = null
  updateModalState(false)
}

watch(
  () => props.images,
  (newValue: string[], oldValue: string[]) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      imagesRef.value = newValue
    }
  },
)

watch(isOpen, (value) => {
  if (!value) {
    modalValue.value = null
    imageToDelete.value = null
  }
})
</script>
<template>
  <div class="h-full flex flex-col gap-4">
    <HelpTip tip="Drag any image below into any position in the image layout" />
    <AssetUploader class="h-48" :uploadState="uploadState" @file:loaded="handleUploadFile" />
    <draggable
      tag="ul"
      item-key="path"
      draggable=".asset-draggable"
      class="h-[calc(100vh-27rem)] overflow-auto flex flex-col gap-y-5"
      :list="imagesRef"
      :disabled="false"
      @dragend="reorderList"
      @start="dragging = true"
      @end="dragging = false"
    >
      <template #item="{ element, index }">
        <li class="asset-draggable cursor-grab relative group flex items-center">
          <!-- Hover actions -->
          <template v-if="!dragging">
            <div class="absolute top-0 w-full h-full transition-all duration-300 invisible group-hover:visible">
              <div
                class="z-10 bg-black w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
              ></div>
            </div>

            <!-- On hover add -->
            <button
              class="z-20 absolute flex justify-around items-center top-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:top-1/4 group-hover:-translate-y-1/4 border border-white bg-white w-20 px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
              type="button"
              @click="emit('update:value', { field: 'hero', value: element })"
            >
              <SolidPlus class="size-5 fill-white" />
              <span class="mr-1 text-white font-medium text-xs">Add</span>
            </button>

            <!-- On hover delete -->
            <button
              class="z-20 absolute flex gap-x-1 justify-around items-center bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/4 group-hover:translate-y-1/4 border border-red-600 bg-red-300 w-20 px-3 py-2 rounded-md transition-all duration-300 opacity-0 group-hover:opacity-100"
              type="button"
              @click="openDeleteConfirmation(index, element)"
            >
              <OutlineDelete class="w-4 h-4 shrink-0 stroke-red-600" />
              <span class="text-red-600 font-medium text-xs">Delete</span>
            </button>
          </template>

          <!-- Image -->
          <div class="w-full h-40">
            <AssetViewerPlaceholder
              class="object-cover"
              :url="`/media/${element}`"
              :id="`galleryIndex-${index}`"
              :widthDescriptors="['320w']"
            />
          </div>
        </li>
      </template>
    </draggable>

    <ModalContentWrapper v-if="modalValue">
      <DeleteConfirmation
        :message="modalValue.message"
        :label-cancel="modalValue.labelCancel"
        :label-confirm="modalValue.labelConfirm"
        @close:modal="closeModal()"
        @confirm:modal="confirmDelete()"
      />
    </ModalContentWrapper>
  </div>
</template>
