<script lang="ts" setup>
import { Ref, inject, ref, watch } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { HiOutlineGlobeAlt } from '@ankor-io/icons/hi_outline'
import { OutlineMail, OutlinePhone } from '@ankor-io/icons/outline'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { AuthenticationContext } from '@/iam/types'
import { LayoutTemplate } from '@/sections/types'
import { linkMedia } from '@/services/MediaService'

import { ProfileCompanySectionEditorData } from './ProfileCompanySectionEditor'

type Props = {
  id: string
  /**
   * The template uri
   */
  templateUri: string
  proposalUri: string
  document: JsonProposal
  data: ProfileCompanySectionEditorData
  layout: LayoutTemplate
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

props.lifecycle({
  onBeforeHydrate() {
    // make sure the data is set before hydration completes from the template identity
    const { identity } = props.document.template
    const image = (identity.image && replacePathToMediaUris(props.templateUri, identity.image)[0]) || ''
    const companyImage =
      (identity.companyImage && replacePathToMediaUris(props.templateUri, identity.companyImage)[0]) || ''
    //@ts-ignore
    this.data = { ...identity, image, companyImage }
  },
  async onHydrated() {
    if (props.data.image) {
      await linkMedia({ authenticationContext }, props.proposalUri, props.data.image)
    }

    if (props.data.companyImage) {
      await linkMedia({ authenticationContext }, props.proposalUri, props.data.companyImage)
    }

    if (!props.layout.type) {
      emit('update:layout', {
        sectionId: props.id,
        layout: { type: 'compact', options: { ...props.layout.options } },
      })
    }
  },
} as unknown as EditableLifecycleHooks)

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<ProfileCompanySectionEditorData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const data: Ref<ProfileCompanySectionEditorData> = ref(props.data)
const identityData = ref(Object.assign({}, props.document.template.identity))

const update = (field: string, value: string) => {
  const newData = ObjectUtil.deepCopy(props.data)
  newData[field] = value
  emit('update:value', { sectionId: props.id, data: newData })
}

watch(
  () => props.document.template.identity,
  (newValue, oldValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      // currently the props.document.identity is firing in this watcher many times when an inline edit occurs
      // the below must happen to check that there is a difference in the identity
      const newIdentityString = JSON.stringify(newValue)
      const currentIdentityString = JSON.stringify(identityData.value)

      identityData.value = Object.assign({}, newValue)
      if (newIdentityString !== currentIdentityString) {
        data.value = newValue // updates the entire props.data to be the newValue
      } else {
        const sectionData = Object.values(props.data).filter((value) => !!value)
        data.value = Object.keys(sectionData).length ? props.data : newValue
      }

      emit('update:value', { sectionId: props.id, data: data.value })
    }
  },
  { immediate: true, deep: true },
)
</script>
<template>
  <div
    class="profile-company w-full h-full flex items-center p-4 @sm:p-8 flex-col justify-between @sm:flex-row gap-8 border border-gray-200 dark:border-gray-600"
    :class="
      props.layout.type === 'taller'
        ? 'bg-gray-700 rounded-t-lg @sm:rounded-t-2xl border-b-0'
        : 'bg-gray-50 rounded-lg @sm:rounded-2xl'
    "
  >
    <!-- Company details -->
    <div class="company flex flex-col justify-center @sm:max-w-[33%] gap-y-1 text-center @sm:text-left">
      <h1
        v-if="props.data.companyName"
        class="!text-lg @sm:!text-3xl font-semibold"
        :class="{ '!text-white': props.layout.type === 'taller' }"
      >
        <SingleLineTextEditor :value="props.data.companyName" @update:value="update('companyName', $event)" />
      </h1>
      <div class="flex flex-col gap-y-4 self-center @sm:self-auto">
        <div
          v-if="props.data.companyAddress || props.data.companyPhone"
          :class="{ '!text-white': props.layout.type === 'taller' }"
        >
          <SingleLineTextEditor
            v-if="props.data.companyAddress"
            :value="props.data.companyAddress"
            @update:value="update('companyAddress', $event)"
          />
          <SingleLineTextEditor
            v-if="props.data.companyPhone"
            :value="props.data.companyPhone"
            @update:value="update('companyPhone', $event)"
          />
        </div>
        <AssetViewerPlaceholder
          v-if="props.data.companyImage"
          class="max-w-[11.5rem] max-h-[11.5rem] object-cover"
          :url="`/media/${props.data.companyImage}`"
        />
      </div>
    </div>

    <!-- Profile details -->
    <div
      class="profile min-w-[25rem] w-fit flex flex-col @sm:flex-row rounded-lg @sm:rounded-2xl overflow-hidden shadow-md h-full my-auto border border-gray-200 dark:border-gray-600"
      :class="{ 'bg-gray-500': props.layout.type === 'taller' }"
    >
      <!-- Profile image -->
      <div class="w-full @sm:w-56 h-full @sm:min-h-56">
        <AssetViewerPlaceholder
          v-if="props.data.image"
          class="flex object-cover aspect-square sm:aspect-auto"
          :url="`/media/${props.data.image}`"
        />
      </div>

      <div class="flex flex-col justify-between gap-y-2 py-8 px-6">
        <div v-if="props.data.givenName || props.data.surname || props.data.role">
          <div v-if="props.data.givenName || props.data.surname" class="flex flex-row gap-1 flex-wrap font-bold">
            <h1 v-if="props.data.givenName" class="!text-lg" :class="{ '!text-white': props.layout.type === 'taller' }">
              <SingleLineTextEditor :value="props.data.givenName" @update:value="update('givenName', $event)" />
            </h1>
            <h1 v-if="props.data.surname" class="!text-lg" :class="{ '!text-white': props.layout.type === 'taller' }">
              <SingleLineTextEditor :value="props.data.surname" @update:value="update('surname', $event)" />
            </h1>
          </div>
          <h6 v-if="props.data.role">
            <SingleLineTextEditor
              class="italic font-light"
              :class="props.layout.type === 'taller' ? '!text-white' : 'text-gray-500'"
              :value="props.data.role"
              @update:value="update('role', $event)"
            />
          </h6>
        </div>

        <div class="flex flex-col gap-y-2 !font-light" :class="{ 'text-white': props.layout.type === 'taller' }">
          <div v-if="props.data.phone" class="flex gap-x-2">
            <div class="self-center">
              <OutlinePhone class="w-4 stroke-theme-primary" />
            </div>
            <SingleLineTextEditor :value="props.data.phone" @update:value="update('phone', $event)" />
          </div>
          <div v-if="props.data.email" class="flex gap-x-2">
            <div class="self-center">
              <OutlineMail class="w-4 stroke-theme-primary" />
            </div>
            <SingleLineTextEditor class="break-all" :value="props.data.email" @update:value="update('email', $event)" />
          </div>
          <div v-if="props.data.website" class="flex gap-x-2">
            <div class="self-center">
              <HiOutlineGlobeAlt class="w-4 stroke-theme-primary" />
            </div>
            <SingleLineTextEditor
              class="break-all"
              :value="props.data.website"
              @update:value="update('website', $event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Primary colour block for taller layout/layout 2 at the bottom -->
  <div
    v-if="props.layout.type === 'taller'"
    class="h-16 bg-theme-primary rounded-b-lg @sm:rounded-b-2xl border border-gray-200 dark:border-gray-600 border-t-0"
  ></div>
</template>
