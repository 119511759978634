<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import ls from 'localstorage-slim'
import { Ref, computed, inject, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { RouteLocationNormalizedLoaded, Router, RouterView, useRoute, useRouter } from 'vue-router'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import { showAwaitToast, showErrorToast, showInfoToast } from '@ankor-io/blocks/components/toast'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import OneColumnWithHeader from '@ankor-io/blocks/layouts/OneColumnWithHeader.vue'
import { ProposalIndexItem } from '@ankor-io/common/index/ProposalIndexItem'
import { useAppDispatcher } from '@ankor-io/common/lang/events'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { Events } from '@ankor-io/common/proposal/Events'
import {
  EditableProposal,
  HeaderTemplate,
  Identity,
  JsonProposal,
  ProposalItem,
  Template,
  ThemeTemplate,
} from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { SlideTemplate } from '@ankor-io/common/proposal/Slide'
import { SlideType } from '@ankor-io/common/proposal/SlideType'
import { Trip } from '@ankor-io/common/trip/Trip'
import { URIBuilder, URIContext } from '@ankor-io/common/uri/Uri'
import { HiOutlineSwatch } from '@ankor-io/icons/hi_outline'
import {
  OutlineDocumentText,
  OutlineEye,
  OutlinePerson,
  OutlineRefresh,
  OutlineShare,
  OutlineTemplate,
  OutlineTick,
} from '@ankor-io/icons/outline'
import {
  SolidChevronDoubleRight,
  SolidChevronDown,
  SolidColorSwatch,
  SolidHome,
  SolidRoute,
  SolidYacht,
} from '@ankor-io/icons/solid'

import Loader from '@/components/Loader.vue'
import Spinner from '@/components/Spinner.vue'
import BrochureGeneratedModalContent from '@/components/modal-content/BrochureGeneratedModalContent.vue'
import GlobalIdentityModal from '@/components/modal-content/GlobalIdentityModal.vue'
import GlobalStyleModal from '@/components/modal-content/GlobalStyleModal.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { AuthenticationContext } from '@/iam/types'
import { useModal } from '@/modal/useModal'
import { getSectionTypes } from '@/sections/proposalSectionTypes'
import { linkMedia } from '@/services/MediaService'
import { StateService, useStateServiceProvider } from '@/services/StateService'
import { getDefaultProposalTemplate, getUserTemplate, useProposalService } from '@/services/proposal/ProposalService'
import { EditableProposalDeserializer } from '@/services/proposal/deserializer/EditableProposalDeserializer'
import { useStowageService } from '@/services/stowage/StowageService'
import {
  CLICK_PRESENTATION_EDITOR_TAB,
  CLICK_SKIP_TO_PRESENTATION_EDITOR_BUTTON,
  CONFIRM_PROFILE_COMPANY_SETTINGS,
  CONFIRM_STYLE_BRANDING_SETTINGS,
  LOGOUT,
  NAVIGATE_TO_HOME,
  OPEN_PRESENTATION_PREVIEW,
  OPEN_PROFILE_COMPANY_SETTINGS,
  OPEN_SHARE_MODAL,
  OPEN_STYLE_BRANDING_SETTINGS,
  PRESENTATION_NAME_CLICKED,
} from '@/utils/mixPanel/constants'

const router: Router = useRouter()
const route: RouteLocationNormalizedLoaded = useRoute()
const sectionTypes = getSectionTypes() // Used for saving template and reading section types from the proposal

const stowageService = useStowageService()
const dispatcher = useAppDispatcher().get()
const proposalService = useProposalService()
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const canAccess: Ref<boolean> = ref(true)
const bannerOpen: Ref<boolean> = ref(false)
const editablePresentation: Ref<EditableProposal | null> = ref(null)
const isEditSaved = ref<boolean>(false)

const isTemplateLoading: Ref<boolean> = ref(true)
const identityModalVisible: Ref<boolean> = ref(false)
const themeModalVisible: Ref<boolean> = ref(false)
const userTemplate: Ref<Template> = ref({} as Template)
const userTemplateUri: string = URIBuilder.from(URIContext.U, authenticationContext.getUser().id, 'template', 'default')
const internalNameInputRef = ref<HTMLInputElement>()

const presentationURI: Ref<string> = ref('')

const trip: Ref<Trip | null> = ref(null)
const { updateModalState } = useModal()
const generatingBrochure: Ref<boolean> = ref(false)

let stateService: StateService

/**
 * Listener callback for the {@link Events.SYNC} app event
 */
const synchronize = (uri: string) => () => {
  stateService.getClient(uri).onChanges(editablePresentation.value!.toJson())
  isEditSaved.value = stateService.getClient(uri).isSaved()
}

onBeforeMount(async () => {
  stateService = await useStateServiceProvider(inject).then((_stateServiceProvider) => _stateServiceProvider.get())

  if (route.name === 'trips-editor-presentation-new') {
    const item = route.query.item
    if (item && JSON.parse(item as string).type === SlideType.ITINERARY) {
      createNewPresentation({ tabToLandOn: 'routes' })
    } else {
      createNewPresentation()
    }
  } else if (route.name === 'trips-editor-presentation-duplicate' && route.params.uri) {
    duplicate(route.params.uri as string)
  } else if (route.name === 'trips-editor-presentation-edit' && route.params.uri) {
    if (route.query.hasItems === 'true') {
      router.replace({
        name: 'trips-editor-presentation-edit-design',
        params: { uri: route.params.uri },
      })
    } else {
      router.replace({
        name: 'trips-editor-presentation-edit-yachts',
        params: { uri: route.params.uri },
      })
    }

    dispatcher.addEventListener(Events.SYNC, synchronize(route.params.uri as string))
    registerPresentationObserver(route.params.uri as string)
  } else if (
    route.name === 'trips-editor-presentation-edit-yachts' ||
    route.name === 'trips-editor-presentation-edit-routes' ||
    route.name === 'trips-editor-presentation-edit-design'
  ) {
    dispatcher.addEventListener(Events.SYNC, synchronize(route.params.uri as string))
    registerPresentationObserver(route.params.uri as string)
  }
})

onMounted(async () => {
  await getCurrentUserTemplate()
  internalNameInputRef.value?.focus()
  presentationURI.value = route.params.uri as string
})

onBeforeUnmount(async () => {
  if (presentationURI.value) {
    await stowageService.setURIDocument(presentationURI.value as string, editablePresentation.value!.toJson())
    stateService.getClient(presentationURI.value as string).unRegisterObserver(presentationEditorObserver)
    dispatcher.removeEventListener(Events.SYNC, synchronize(presentationURI.value as string))
  }
})

watch(editablePresentation, (newValue, oldValue) => {
  if (oldValue === null && newValue !== null && route.query.item && route.params.uri) {
    const item = JSON.parse(route.query.item as string)
    const proposalItem: ProposalItem = {
      type: item.type,
      uri: item.uri,
      item,
    } as ProposalItem

    editablePresentation.value?.addItem(proposalItem)
    stateService.getClient(route.params.uri as string).onChanges(editablePresentation.value!.toJson())
  }
})

const registerPresentationObserver = (uri: string) => {
  stateService.getClient(uri).registerObserver(presentationEditorObserver)
  stateService.onForbidden(uri, () => {
    canAccess.value = false
  })
}

const presentationEditorObserver = async () => {
  // skip server edits when in pause mode
  if (editablePresentation.value?.isInitializing()) {
    return
  }
  const document = stateService.getClient(route.params.uri as string).getContent() as JsonProposal

  editablePresentation.value?.destroy()
  editablePresentation.value = EditableProposalDeserializer.deserialize(document)

  isEditSaved.value = stateService.getClient(route.params.uri as string).isSaved()
}

/**
 * Share and preview should be disabled unless user has added at least one item (A route or Yacht)
 * has been added to the selection and has reached the review step
 */
const shareAndPreviewDisabled = computed(() => {
  if (
    !editablePresentation.value ||
    editablePresentation.value.isInitializing() ||
    !editablePresentation.value.hasItems ||
    !isEditSaved.value
  ) {
    return true
  }

  return false
})

const getCurrentUserTemplate = async () => {
  // fetch the default template
  const template: Template | null = await stowageService.getDocument<Template>('template', 'default')
  // use the default template if the user template is not found
  userTemplate.value = template ? getUserTemplate(template) : getDefaultProposalTemplate()
  isTemplateLoading.value = false
}

const updateIdentity = async (identity: Identity) => {
  identityModalVisible.value = false
  const updatedTemplate: Template = ObjectUtil.deepCopy({ ...userTemplate.value, identity })

  if (route.params.uri) {
    // await to copy over as proposalDocument gets updated instantly with broken images appearing in section
    if (userTemplate.value.identity.companyImage !== identity.companyImage && identity.companyImage !== '') {
      // link company image to proposal
      await linkMedia({ authenticationContext }, route.params.uri as string, identity.companyImage)
    }

    if (userTemplate.value.identity.image !== identity.image && identity.image !== '') {
      // link profile image to proposal
      await linkMedia({ authenticationContext }, route.params.uri as string, identity.image)
    }
  }

  return showAwaitToast(
    stowageService.setDocument('template', 'default', updatedTemplate),
    `Updating profile & company...`,
    `Updated profile & company successfully.`,
    `Failed to update profile & company.`,
  ).then(() => {
    userTemplate.value = updatedTemplate
    // After the setDocument is successful, only then update the editablePresentation
    if (editablePresentation.value) {
      updateProfileCompanyWithTemplate(editablePresentation.value, updatedTemplate)
    }
  })
}

const updateTheme = async (theme: ThemeTemplate) => {
  themeModalVisible.value = false
  const updatedTemplate: Template = ObjectUtil.deepCopy({ ...userTemplate.value, theme })

  return showAwaitToast(
    stowageService.setDocument('template', 'default', updatedTemplate),
    `Updating profile & company...`,
    `Updated profile & company successfully.`,
    `Failed to update profile & company.`,
  ).then(() => {
    userTemplate.value = updatedTemplate
    // After the setDocument is successful, only then update the editablePresentation
    if (route.params.uri && editablePresentation.value) {
      updateProfileCompanyWithTemplate(editablePresentation.value, updatedTemplate)
    }
  })
}

const updateProfileCompanyWithTemplate = (editablePresentation: EditableProposal, template: Template) => {
  const jsonProposal = editablePresentation.toJson()
  jsonProposal.template = template

  for (const section of jsonProposal!.document.slides.map((slide) =>
    slide.sections.find((section) => section.type === SectionType.PROFILE_COMPANY),
  )) {
    if (section) {
      section.data.companyImage = template.identity.companyImage
      section.data.image = template.identity.image
    }
  }

  updatePresentation(jsonProposal)
}

// Creates a new presentation and navigates to the edit page
const createNewPresentation = async (
  { tabToLandOn }: { tabToLandOn: 'routes' | 'yachts' } = { tabToLandOn: 'yachts' },
) => {
  showInfoToast('Creating new presentation...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch('/api/presentation/new', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()

    // need to ensure the user lands on the "Route" tab if they create a presentation via a route
    router.replace({
      name: `trips-editor-presentation-edit-${tabToLandOn}`,
      params: { uri },
      query: { item: route.query.item },
    })

    dispatcher.addEventListener(Events.SYNC, synchronize(uri))
    registerPresentationObserver(uri)
  } else {
    showErrorToast('Failed to create new presentation')
    console.debug('Failed to create new presentation', res)
  }
}

// Duplicates a presentation and navigates to the edit page
const duplicate = async (fromUri: string) => {
  showInfoToast('Duplicating presentation...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch(`/api/presentation/duplicate/${fromUri}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    if (route.query.hasItems === 'true') {
      router.replace({
        name: 'trips-editor-presentation-edit-design',
        params: { uri },
      })
    } else {
      router.replace({
        name: 'trips-editor-presentation-edit-yachts',
        params: { uri },
      })
    }

    dispatcher.addEventListener(Events.SYNC, synchronize(uri))
    registerPresentationObserver(uri)
  } else {
    showErrorToast('Failed to duplicate presentation')
    console.debug('Failed to duplicate presentation', res)
  }
}

const share = async (uri: string) => {
  generatingBrochure.value = true

  trip.value = await proposalService.share(uri)

  generatingBrochure.value = false
  updateModalState(true)
}

/**
 * updates internal name of the presentation
 * @param ev HTMLInput Event
 */
const updateInternalName = (ev: Event): void => {
  const target = ev.target as HTMLInputElement
  const { value } = target
  if (value === editablePresentation.value!.internalName) {
    return
  }
  ;(editablePresentation.value! as any).internalName = value
  // skip sending server changes when in pause mode
  if (editablePresentation.value?.isInitializing()) {
    return
  }
  const item: Partial<ProposalIndexItem> = ls.get<ProposalIndexItem>(route.params.uri as string) || {}
  ls.set(route.params.uri as string, { ...item, line_1: value }, { ttl: 90 })

  // TODO: update internal name in local storage so it can be used in dashboard?
  if (route.params.uri) {
    stateService.getClient(route.params.uri as string).onChanges(editablePresentation.value!.toJson())
    isEditSaved.value = stateService.getClient(route.params.uri as string).isSaved()
  }
}

/**
 * Update and persit the presentation via state service
 * @param updatedPresentation Updated presentation
 */
const updatePresentation = (updatedPresentation: JsonProposal) => {
  if (!route.params.uri) {
    return
  }

  stateService.getClient(route.params.uri as string).onChanges(updatedPresentation)
  isEditSaved.value = stateService.getClient(route.params.uri as string).isSaved()
}

const saveTemplate = async () => {
  // Get the itinerary slide
  const firstItinSlideIndex = editablePresentation.value?.document.slides.findIndex(
    (slide) => slide.getType() === SlideType.ITINERARY,
  )
  const firstItinSlide =
    firstItinSlideIndex !== -1 ? editablePresentation.value?.document.slides[firstItinSlideIndex!] : undefined

  // Get the vessel slide
  const firstVesselSlideIndex = editablePresentation.value?.document.slides.findIndex(
    (slide) => slide.getType() === SlideType.VESSEL,
  )
  const firstVesselSlide =
    firstVesselSlideIndex !== -1 ? editablePresentation.value?.document.slides[firstVesselSlideIndex!] : undefined

  const sectionTypesToRemove: SectionType[] = Object.values(sectionTypes)
    .filter((def) => def.autoRemove || def.disableSaveLayout)
    .map((section) => section.type)

  if (firstItinSlide) {
    const itineraryToSave: SlideTemplate = firstItinSlide?.asTemplate()
    itineraryToSave.sections = itineraryToSave.sections.filter(
      (section) => !sectionTypesToRemove.includes(section.type as SectionType),
    )
    editablePresentation.value!.template.itinerary = itineraryToSave
  }

  if (firstVesselSlide) {
    const vesselToSave: SlideTemplate = firstVesselSlide?.asTemplate()
    vesselToSave.sections = vesselToSave.sections.filter(
      (section) => !sectionTypesToRemove.includes(section.type as SectionType),
    )
    editablePresentation.value!.template.vessel = vesselToSave
  }

  const header = editablePresentation.value?.document.getHeader()
  editablePresentation.value!.template.header = header ? { section: header.asTemplate() } : ({} as HeaderTemplate)

  return showAwaitToast(
    stowageService.setDocument<Template>('template', 'default', editablePresentation.value!.template),
    `Saving new default template...`,
    `Template has been saved.`,
    `Template could not be saved.`,
  )
}
</script>

<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false">
      <!-- p>Meet us at <strong>Cairns</strong>!</p -->
    </Banner>
    <OneColumnWithHeader
      :class-names="
        bannerOpen
          ? {
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #header>
        <div
          class="sticky top-0 z-40 flex justify-between h-16 shrink-0 items-center gap-x-2 sm:gap-x-6 lg:gap-x-4 border-b shadow-sm px-2 sm:px-6 lg:px-8 border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800"
        >
          <!-- nunununununununun -->
          <!--   Breadcrumbs      -->
          <!-- nunununununununun -->
          <div class="min-w-fit">
            <nav class="flex flex-row flex-nowrap">
              <ol class="group flex flex-row flex-nowrap shrink-0 items-center">
                <li class="mr-2">
                  <RouterLink
                    aria-current="page"
                    class="text-sm font-medium group-hover:fill-primary-600 group-dark:hover:fill-primary-500"
                    :to="{ name: 'trips-library-presentations' }"
                    @click="$mixPanel.track(NAVIGATE_TO_HOME, { from: 'Presentation Editor' })"
                  >
                    <SolidHome
                      class="size-4 transition-all group-hover:fill-primary-600 group-hover:stroke-primary-600 dark:group-hover:fill-primary-500 dark:group-hover:stroke-primary-500 fill-gray-500 stroke-gray-500 dark:fill-gray-400 dark:stroke-gray-400"
                    />
                  </RouterLink>
                </li>
                <li class="hidden lg:block mr-2">
                  <RouterLink
                    aria-current="page"
                    class="text-sm transition-all cursor-pointer text-gray-500 dark:text-gray-400 group-hover:text-primary-600 dark:group-hover:text-primary-500"
                    :to="{ name: 'trips-library-presentations' }"
                    @click="$mixPanel.track(NAVIGATE_TO_HOME, { from: 'Presentation Editor' })"
                  >
                    Presentations
                  </RouterLink>
                </li>
                <li class="hidden lg:block mr-2 text-sm text-gray-500 dark:text-gray-400">/</li>
              </ol>
              <input
                :ref="(el) => (internalNameInputRef = el as HTMLInputElement)"
                type="text"
                class="hover:none px-2 w-16 md:w-36 h-8 text-nowrap p-0 overflow-hidden lg:w-52 border rounded-md text-sm bg-transparent text-ellipsis focus:border text-gray-700 dark:text-gray-300 focus:border-primary-600 dark:focus:border-primary-500"
                :class="{ 'cursor-not-allowed': !isEditSaved }"
                :disabled="!isEditSaved"
                :value="editablePresentation?.internalName || ls.get<ProposalIndexItem>(route.params.uri as string)?.line_1"
                @blur="updateInternalName($event)"
                @click=";[($event.target as HTMLInputElement).select(), $mixPanel.track(PRESENTATION_NAME_CLICKED)]"
              />
            </nav>
          </div>

          <!-- nunununununununun -->
          <!--       Views       -->
          <!-- nunununununununun -->
          <ul
            v-if="$route.params.uri && editablePresentation"
            class="hidden md:flex flex-nowrap -mb-px text-center font-medium text-sm text-gray-500 dark:text-gray-400 gap-x-0 lg:gap-x-2.5"
          >
            <li class="flex items-center">
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group transition-colors"
                :to="{ name: 'trips-editor-presentation-edit-yachts', params: { uri: $route.params.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-yachts' ||
                  $route.name === 'trips-editor-presentation-edit'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : isEditSaved
                    ? 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500'
                    : 'pointer-events-none text-gray-300 dark:text-gray-600',
                ]"
                @click="$mixPanel.track(CLICK_PRESENTATION_EDITOR_TAB, { tab: 'Yachts' })"
              >
                <div class="hidden xl:block"><SolidYacht class="size-4 me-2 shrink-0" /></div>
                Select Yachts
              </RouterLink>
            </li>
            <li class="hidden lg:flex items-center justify-center">
              <SolidChevronDoubleRight
                class="size-3 lg:size-4 shrink-0"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-yachts' ||
                  $route.name === 'trips-editor-presentation-edit'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : isEditSaved
                    ? 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500'
                    : 'pointer-events-none text-gray-300 dark:text-gray-600',
                ]"
              />
            </li>
            <li class="flex items-center">
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group transition-colors"
                :to="{ name: 'trips-editor-presentation-edit-routes', params: { uri: $route.params.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-routes'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : isEditSaved
                    ? 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500'
                    : 'pointer-events-none text-gray-300 dark:text-gray-600',
                ]"
                @click="$mixPanel.track(CLICK_PRESENTATION_EDITOR_TAB, { tab: 'Routes' })"
              >
                <div class="hidden xl:block"><SolidRoute class="size-4 me-2 shrink-0" /></div>
                Select Routes
              </RouterLink>
            </li>
            <li class="hidden lg:flex items-center justify-center">
              <SolidChevronDoubleRight
                class="size-3 lg:size-4 shrink-0"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-routes'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : isEditSaved
                    ? 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500'
                    : 'pointer-events-none text-gray-300 dark:text-gray-600',
                ]"
              />
            </li>
            <li class="flex items-center" :class="{ 'cursor-not-allowed': !editablePresentation.hasItems }">
              <RouterLink
                class="inline-flex items-center justify-center p-2 rounded-t-lg group transition-colors"
                :to="{ name: 'trips-editor-presentation-edit-design', params: { uri: $route.params.uri } }"
                :class="[
                  $route.name === 'trips-editor-presentation-edit-design'
                    ? 'active text-primary-600 dark:text-primary-500'
                    : editablePresentation.hasItems && isEditSaved
                    ? 'hover:text-gray-600 dark:hover:text-gray-400 dark:text-gray-500'
                    : 'pointer-events-none text-gray-300 dark:text-gray-600',
                ]"
                @click="$mixPanel.track(CLICK_PRESENTATION_EDITOR_TAB, { tab: 'Design' })"
              >
                <div class="hidden xl:block"><SolidColorSwatch class="size-4 me-2 shrink-0" /></div>
                Design & Share
              </RouterLink>
            </li>
          </ul>
          <select
            v-if="$route.params.uri"
            class="md:hidden h-11 z-20 border-1 rounded-lg text-xs focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
            @change="
              $router.push({
                name: ($event.currentTarget! as HTMLInputElement).value,
                params: { uri: $route.params.uri },
              })
            "
          >
            <option value="trips-editor-presentation-edit-yachts">Select Yachts</option>
            <option value="trips-editor-presentation-edit-routes">Select Routes</option>
            <option value="trips-editor-presentation-edit-design" :disabled="!editablePresentation?.hasItems">
              Design & Share
            </option>
          </select>

          <div class="flex items-center gap-x-2">
            <!-- Diff sync saved state -->
            <div class="flex items-center gap-x-1">
              <OutlineTick
                v-if="isEditSaved"
                class="hidden md:block size-3 shrink-0 stroke-green-500 dark:stroke-green-400"
              />
              <div v-if="!isEditSaved" class="animate-spin">
                <OutlineRefresh
                  class="hidden md:block scale-x-[-1] size-4 shrink-0 stroke-cognac-500 dark:stroke-cognac-400"
                />
              </div>
              <span
                class="w-12 font-medium text-xs"
                :class="isEditSaved ? 'text-green-500 dark:text-green-400' : 'text-cognac-500 dark:text-cognac-400'"
              >
                {{ isEditSaved ? 'Saved' : 'Saving...' }}
              </span>
            </div>
            <!-- nunununununununun -->
            <!--    Button Group   -->
            <!-- nunununununununun -->
            <div v-if="$route.params.uri" class="flex items-center gap-x-2">
              <!-- Preview -->
              <span :class="{ 'cursor-not-allowed': shareAndPreviewDisabled }">
                <RouterLink
                  target="_blank"
                  id="presentation-preview"
                  class="transition-colors hidden lg:flex items-center gap-x-2 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer focus:ring-4 focus:outline-none border border-gray-200 dark:border-gray-600"
                  :class="
                    shareAndPreviewDisabled
                      ? 'pointer-events-none text-white bg-gray-200 dark:bg-gray-400'
                      : 'cursor-pointer focus:ring-4 focus:outline-none text-gray-900 dark:text-white bg-white dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 focus:bg-gray-300 dark:focus:bg-gray-700'
                  "
                  :to="{ name: 'trips-preview', params: { uri: $route.params.uri } }"
                  @click="$mixPanel.track(OPEN_PRESENTATION_PREVIEW, { from: 'Presentation Editor' })"
                >
                  <OutlineEye class="size-4 shrink-0" />
                  Preview
                </RouterLink>
              </span>

              <!-- Share -->
              <button
                id="presentation-share"
                class="transition-all hidden lg:flex items-center gap-x-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-primary-300 text-white hover:text-white"
                :class="
                  shareAndPreviewDisabled
                    ? 'cursor-not-allowed bg-gray-200 dark:bg-gray-400'
                    : 'cursor-pointer bg-primary-600 dark:bg-primary-500 hover:bg-primary-500 dark:hover:bg-primary-800'
                "
                :disabled="shareAndPreviewDisabled"
                @click="
                  ;[
                    share($route.params.uri as string),
                    $mixPanel.track(OPEN_SHARE_MODAL, { from: 'Presentation Editor' }),
                  ]
                "
              >
                <Spinner v-if="generatingBrochure" class="w-4 h-4"></Spinner>
                <OutlineShare v-else class="size-4 shrink-0" />
                Share
              </button>

              <div class="cursor-not-allowed lg:hidden flex gap-x-px">
                <button
                  type="button"
                  class="w-full sm:w-auto justify-center transition-colors font-medium rounded-l-lg text-xs px-2 md:px-5 py-2.5 text-center inline-flex items-center gap-x-2"
                  :class="
                    shareAndPreviewDisabled
                      ? 'pointer-events-none text-white bg-gray-200 dark:bg-gray-400'
                      : 'cursor-pointer focus:ring-4 focus:outline-none text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300  dark:text-white '
                  "
                  @click="share($route.params.uri as string)"
                >
                  <Spinner v-if="generatingBrochure" class="w-4 h-4"></Spinner>
                  Share
                </button>
                <Menu as="div" class="relative inline-block text-left">
                  <MenuButton
                    class="transition-colors flex rounded-r-lg px-2 md:px-5 py-2.5 items-center cursor-pointer focus:ring-4 focus:outline-none"
                    :class="
                      shareAndPreviewDisabled
                        ? 'pointer-events-none text-white bg-gray-200 dark:bg-gray-400'
                        : 'cursor-pointer focus:ring-4 focus:outline-none text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300 dark:text-white'
                    "
                  >
                    <span class="sr-only">Open options</span>
                    <SolidChevronDown class="size-5" aria-hidden="true" />
                  </MenuButton>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute border right-0 z-50 mt-2 py-1 w-24 md:w-36 origin-top-right rounded-md shadow-lg ring-1 ring-opacity-5 focus:outline-none bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600 ring-black"
                    >
                      <MenuItem>
                        <RouterLink
                          target="_blank"
                          class="flex transition-all gap-x-2 items-center cursor-pointer px-2 md:px-3 py-2 bg-white dark:bg-gray-800 hover:bg-gray-100 hover:dark:bg-gray-700 text-gray-500 dark:text-gray-400"
                          :to="{ name: 'trips-preview', params: { uri: $route.params.uri } }"
                        >
                          <span class="text-sm">Preview</span>
                        </RouterLink>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>

              <!-- Profile -->
              <AccountMenu
                :name="authenticationContext.getUser()?.given_name"
                @logout=";[$authenticationContext.logout(), $mixPanel.track(LOGOUT, { from: 'Presentation Editor' })]"
              >
                <template #contextualLinks>
                  <MenuItem>
                    <li
                      class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                      @click="saveTemplate"
                    >
                      <OutlineTemplate
                        class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                      />
                      Save as default layout
                    </li>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <li
                      class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                      :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                      @click="
                        ;[
                          (identityModalVisible = true),
                          $mixPanel.track(OPEN_PROFILE_COMPANY_SETTINGS, { from: 'Presentation Editor' }),
                        ]
                      "
                    >
                      <OutlinePerson
                        class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                      />
                      Profile &amp; Company
                    </li>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <li
                      class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                      :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                      @click="
                        ;[
                          (themeModalVisible = true),
                          $mixPanel.track(OPEN_STYLE_BRANDING_SETTINGS, { from: 'Presentation Editor' }),
                        ]
                      "
                    >
                      <HiOutlineSwatch
                        class="size-4 shrink-0 stroke-gray-500 dark:stroke-gray-400 group-hover:stroke-gray-900 dark:group-hover:stroke-gray-50"
                      />
                      Style & Branding
                    </li>
                  </MenuItem>
                  <MenuItem v-slot="{ active }" class="border-b border-b-gray-200 dark:border-b-gray-600">
                    <li
                      class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                      :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                    >
                      <OutlineDocumentText
                        class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                      />
                      <a href="https://ankor.io/terms-conditions" target="_blank"> Terms &amp; Conditions </a>
                    </li>
                  </MenuItem>
                </template>
              </AccountMenu>
            </div>
          </div>
        </div>
      </template>
      <template #default>
        <div v-if="$route.name === 'trips-editor-presentation-new'"><Loader /></div>
        <div v-if="$route.name === 'trips-editor-presentation-duplicate'"><Loader /></div>
        <div v-if="!editablePresentation"><Loader /></div>
        <RouterView
          v-else
          name="content"
          :presentation="editablePresentation"
          @update:presentation="updatePresentation"
          :isEditSaved="isEditSaved"
        />
      </template>
      <template
        #footer
        v-if="
          $route.name === 'trips-editor-presentation-edit-yachts' ||
          $route.name === 'trips-editor-presentation-edit-routes'
        "
      >
        <div class="flex items-center justify-end border-t border-gray-200 dark:border-gray-600 h-16 px-24">
          <div v-if="$route.name === 'trips-editor-presentation-edit-yachts'" class="flex gap-x-4">
            <RouterLink
              class="hidden md:inline-flex w-fit sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border text-sm px-5 py-2.5 text-center items-center"
              :to="{ name: 'trips-editor-presentation-edit-design', params: { uri: $route.params.uri } }"
              :class="
                !(editablePresentation?.hasItems && isEditSaved)
                  ? 'pointer-events-none text-gray-200 dark:text-gray-600 border-gray-200 dark:border-gray-600 border-spacing-0'
                  : 'border-primary-600 dark:border-primary-500 hover:border-primary-800 dark:hover:border-primary-800 text-primary-600 dark:text-primary-500 hover:text-white dark:hover:text-white focus:text-white dark:focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300'
              "
              @click="$mixPanel.track(CLICK_SKIP_TO_PRESENTATION_EDITOR_BUTTON, { tab: 'Design' })"
            >
              Skip to Design & Share
            </RouterLink>
            <RouterLink
              class="w-fit transition-all flex items-center gap-x-2 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer focus:ring-4 focus:outline-none text-white"
              :class="[
                isEditSaved
                  ? 'cursor-pointer  bg-primary-600 dark:bg-primary-500 hover:bg-primary-500 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300'
                  : 'pointer-events-none bg-gray-200 dark:bg-gray-400',
              ]"
              :to="{ name: 'trips-editor-presentation-edit-routes' }"
            >
              Next: Select Routes
            </RouterLink>
          </div>
          <div v-else-if="$route.name === 'trips-editor-presentation-edit-routes'" class="flex gap-x-4">
            <RouterLink
              class="hidden md:inline-flex w-fit sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border text-sm px-5 py-2.5 text-center items-center"
              :to="{ name: 'trips-editor-presentation-edit-yachts', params: { uri: $route.params.uri } }"
              :class="
                !isEditSaved
                  ? 'pointer-events-none text-gray-200 dark:text-gray-600 border-gray-200 dark:border-gray-600 border-spacing-0'
                  : 'border-primary-600 dark:border-primary-500 hover:border-primary-800 dark:hover:border-primary-800 text-primary-600 dark:text-primary-500 hover:text-white dark:hover:text-white focus:text-white dark:focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300'
              "
            >
              Back to Select Yachts
            </RouterLink>
            <RouterLink
              class="w-fit transition-all flex items-center gap-x-2 font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer focus:ring-4 focus:outline-none text-white"
              :class="
                !isEditSaved || !editablePresentation?.hasItems
                  ? 'pointer-events-none bg-gray-200 dark:bg-gray-400'
                  : 'cursor-pointer  bg-primary-600 dark:bg-primary-500 hover:bg-primary-500 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300'
              "
              :to="{ name: 'trips-editor-presentation-edit-design' }"
            >
              Next: Design & Share
            </RouterLink>
          </div>
        </div>
      </template>
    </OneColumnWithHeader>

    <!-- Identity / Profile & Company modal -->
    <Teleport v-if="identityModalVisible" to="body">
      <GlobalIdentityModal
        :identity="userTemplate.identity"
        :userTemplateUri="userTemplateUri"
        @update:identity="
          ;[updateIdentity($event), $mixPanel.track(CONFIRM_PROFILE_COMPANY_SETTINGS, { from: 'Presentation Editor' })]
        "
        @close:modal="identityModalVisible = false"
      />
    </Teleport>

    <!-- Style modal -->
    <Teleport v-if="themeModalVisible" to="body">
      <GlobalStyleModal
        :theme="userTemplate.theme"
        @update:theme="
          ;[updateTheme($event), $mixPanel.track(CONFIRM_STYLE_BRANDING_SETTINGS, { from: 'Presentation Editor' })]
        "
        @close:modal="themeModalVisible = false"
      />
    </Teleport>
    <ModalContentWrapper v-if="trip">
      <BrochureGeneratedModalContent :trip="trip"></BrochureGeneratedModalContent>
    </ModalContentWrapper>
  </div>
</template>
