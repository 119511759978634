<script setup lang="ts">
import { MenuItem } from '@headlessui/vue'
import { Mixpanel } from 'mixpanel-browser'
import { Ref, inject, onMounted, ref } from 'vue'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import { showAwaitToast } from '@ankor-io/blocks/components/toast'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import TwoColumnWithHeader from '@ankor-io/blocks/layouts/TwoColumnWithHeader.vue'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { Identity, Template, ThemeTemplate } from '@ankor-io/common/proposal/Proposal'
import { URIBuilder, URIContext } from '@ankor-io/common/uri/Uri'
import { HiOutlineSwatch } from '@ankor-io/icons/hi_outline'
import { OutlineDocumentText, OutlinePerson } from '@ankor-io/icons/outline'
import {
  SolidCommodore,
  SolidContracts,
  SolidDocumentAdd,
  SolidDocumentText,
  SolidRadar,
  SolidRoute,
  SolidShip,
} from '@ankor-io/icons/solid'

import GlobalIdentityModal from '@/components/modal-content/GlobalIdentityModal.vue'
import GlobalStyleModal from '@/components/modal-content/GlobalStyleModal.vue'
import { getDefaultProposalTemplate, getUserTemplate } from '@/services/proposal/ProposalService'
import { useStowageService } from '@/services/stowage/StowageService'
import {
  CONFIRM_PROFILE_COMPANY_SETTINGS,
  CONFIRM_STYLE_BRANDING_SETTINGS,
  LOGOUT,
  NEW_PRESENTATION,
  OPEN_PROFILE_COMPANY_SETTINGS,
  OPEN_STYLE_BRANDING_SETTINGS,
  SELECT_ANKOR_APP,
} from '@/utils/mixPanel/constants'

const stowageService = useStowageService()
const mixPanel: Mixpanel | undefined = inject('mixPanel')!
const authenticationContext: any = inject('authenticationContext')!

const [env, tld, _appName, branchName] = location.hostname.split('.').reverse()
const apps = [
  {
    name: 'Calendars - Charter Broker',
    icon: SolidRadar,
    appName: 'radar',
    onClick: () => mixPanel.track(SELECT_ANKOR_APP, { app: 'Radar' }),
  },
  {
    name: 'Calendars - Central Agent',
    icon: SolidCommodore,
    appName: 'calendars',
    onClick: () => mixPanel.track(SELECT_ANKOR_APP, { app: 'Calendars' }),
  },
  {
    name: 'Contracts',
    icon: SolidContracts,
    appName: 'contracts',
    onClick: () => mixPanel.track(SELECT_ANKOR_APP, { app: 'Contracts' }),
  },
].map((app) => ({
  ...app,
  href: `https://${[branchName, app.appName, tld, env].filter((part) => !!part).join('.')}`,
}))

const bannerOpen: Ref<boolean> = ref(false)

const isTemplateLoading: Ref<boolean> = ref(true)
const identityModalVisible: Ref<boolean> = ref(false)
const themeModalVisible: Ref<boolean> = ref(false)
const userTemplate: Ref<Template> = ref({} as Template)
const userTemplateUri: string = URIBuilder.from(URIContext.U, authenticationContext.getUser().id, 'template', 'default')

onMounted(async () => await getCurrentUserTemplate())

const getCurrentUserTemplate = async () => {
  // fetch the default template
  const template: Template | null = await stowageService.getDocument<Template>('template', 'default')
  // use the default template if the user template is not found
  userTemplate.value = template ? getUserTemplate(template) : getDefaultProposalTemplate()
  isTemplateLoading.value = false
}

const updateIdentity = async (identity: Identity) => {
  identityModalVisible.value = false
  const updatedTemplate = ObjectUtil.deepCopy({ ...userTemplate.value, identity })

  return showAwaitToast(
    stowageService.setDocument('template', 'default', updatedTemplate),
    `Updating profile & company...`,
    `Updated profile & company successfully.`,
    `Failed to update profile & company.`,
  ).then(() => {
    userTemplate.value = updatedTemplate
  })
}

const updateTheme = async (theme: ThemeTemplate) => {
  themeModalVisible.value = false
  const updatedTemplate = ObjectUtil.deepCopy({ ...userTemplate.value, theme })

  return showAwaitToast(
    stowageService.setDocument('template', 'default', updatedTemplate),
    `Updating theme...`,
    `Updated theme successfully.`,
    `Failed to update theme.`,
  ).then(() => {
    userTemplate.value = updatedTemplate
  })
}
</script>
<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false"></Banner>
    <TwoColumnWithHeader
      :navigation="[
        {
          name: 'My Presentations',
          href: '/trips/library/presentations',
          icon: SolidDocumentText,
          active: $route.path.startsWith('/trips/library/presentations'),
        },
        {
          name: 'Yacht Library',
          href: '/trips/library/yachts',
          icon: SolidShip,
          active: $route.path.startsWith('/trips/library/yachts'),
        },
        {
          name: 'Route Library',
          href: '/trips/library/routes',
          icon: SolidRoute,
          active: $route.path.startsWith('/trips/library/routes'),
        },
      ]"
      :apps="apps"
      :navBottom="[]"
      :class-names="
        bannerOpen
          ? {
              sidebar: 'lg:top-[2.75rem]',
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #appGroupTitle>
        <div class="text-xs/6 ml-2 font-semibold text-gray-900 dark:text-gray-50">Apps</div>
      </template>
      <template #header>
        <div class="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <RouterLink
              v-if="
                $route.name === 'trips-library-presentations' ||
                $route.name === 'trips-library-routes' ||
                $route.name === 'trips-library-yachts'
              "
              target="_blank"
              class="w-full sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300"
              :to="{ name: 'trips-editor-presentation-new' }"
              @click="$mixPanel.track(NEW_PRESENTATION, { from: 'Dashboard Header' })"
            >
              <SolidDocumentAdd class="size-5 mr-2 self-center" />
              New Presentation
            </RouterLink>
            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10 dark:bg-gray-600" aria-hidden="true"></div>

            <!-- Profile dropdown -->
            <AccountMenu
              :name="authenticationContext.getUser()?.given_name"
              @logout=";[$authenticationContext.logout(), $mixPanel.track(LOGOUT, { from: 'Home' })]"
            >
              <template #contextualLinks>
                <MenuItem v-slot="{ active }">
                  <li
                    class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                    :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                    @click="
                      ;[
                        (identityModalVisible = true),
                        $mixPanel.track(OPEN_PROFILE_COMPANY_SETTINGS, { from: 'Dashboard' }),
                      ]
                    "
                  >
                    <OutlinePerson
                      class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                    />
                    Profile &amp; Company
                  </li>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <li
                    class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                    :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                    @click="
                      ;[
                        (themeModalVisible = true),
                        $mixPanel.track(OPEN_STYLE_BRANDING_SETTINGS, { from: 'Dashboard' }),
                      ]
                    "
                  >
                    <HiOutlineSwatch
                      class="size-4 shrink-0 stroke-gray-500 dark:stroke-gray-400 group-hover:stroke-gray-900 dark:group-hover:stroke-gray-50"
                    />
                    Style & Branding
                  </li>
                </MenuItem>
                <MenuItem v-slot="{ active }" class="border-b border-b-gray-200 dark:border-b-gray-600">
                  <li
                    class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                    :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                  >
                    <OutlineDocumentText
                      class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                    />
                    <a href="https://ankor.io/terms-conditions" target="_blank"> Terms &amp; Conditions </a>
                  </li>
                </MenuItem>
              </template>
            </AccountMenu>
          </div>
        </div>
      </template>
      <template #default>
        <RouterView name="content" />
      </template>
    </TwoColumnWithHeader>

    <!-- Identity / Profile & Company modal -->
    <Teleport v-if="identityModalVisible" to="body">
      <GlobalIdentityModal
        :identity="userTemplate.identity"
        :userTemplateUri="userTemplateUri"
        @update:identity="
          ;[updateIdentity($event), $mixPanel.track(CONFIRM_PROFILE_COMPANY_SETTINGS, { from: 'Dashboard' })]
        "
        @close:modal="identityModalVisible = false"
      />
    </Teleport>

    <!-- Style modal -->
    <Teleport v-if="themeModalVisible" to="body">
      <GlobalStyleModal
        :theme="userTemplate.theme"
        @update:theme=";[updateTheme($event), $mixPanel.track(CONFIRM_STYLE_BRANDING_SETTINGS, { from: 'Dashboard' })]"
        @close:modal="themeModalVisible = false"
      />
    </Teleport>
  </div>
</template>
