import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "3",
      d: "M13.5 10.1211V22.4961M22.5 13.4961V25.8711M23.2547 31.1188L30.5672 27.4625C31.1389 27.1767 31.5 26.5924 31.5 25.9532V7.22656C31.5 5.97211 30.1798 5.15621 29.0578 5.71722L23.2547 8.61879C22.7796 8.85633 22.2204 8.85633 21.7453 8.61879L14.2547 4.87347C13.7796 4.63593 13.2204 4.63593 12.7453 4.87347L5.43283 8.52972C4.86113 8.81557 4.5 9.39989 4.5 10.0391V28.7657C4.5 30.0202 5.82015 30.8361 6.94217 30.275L12.7453 27.3735C13.2204 27.1359 13.7796 27.1359 14.2547 27.3735L21.7453 31.1188C22.2204 31.3563 22.7796 31.3563 23.2547 31.1188Z"
    })
  ]))
}