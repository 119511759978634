<script setup lang="ts">
import { Ref, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'

import AssetDropOnly from '@/components/asset-uploader/AssetDropOnly.vue'

/**
 * Currently supporting four images
 * with minimum of two images required,
 * can be extended to multiple at a later point
 */
export interface ResponsiveImageProps {
  /**
   * The array of images to be laid out with equal heights
   */
  url: string
  id?: string
  index: number
  uploadState: string | null
}

// define the props object
const props = defineProps<ResponsiveImageProps>()

const emit = defineEmits<{
  (e: 'image:drop', value?: string): void
  (e: 'image:dragover', event: DragEvent): void
  (e: 'image:drag', event: DragEvent): void
}>()

const flexRatio: Ref<number> = ref(1)
const updateFlexRatioBasedOnImage = (event: Event) => {
  const target = event.target as HTMLImageElement
  // image's aspect ratio is alsways going to be naturalWidth/naturalHeight
  if (!target) return
  flexRatio.value = target.naturalWidth / target.naturalHeight
}
</script>
<template>
  <AssetDropOnly
    :key="props.index"
    :image-index="props.index + 1"
    :upload-state="props.uploadState"
    :style="{ flex: flexRatio }"
    @file:dropped="emit('image:drop', $event)"
    @image:dragover="emit('image:dragover', $event)"
  >
    <AssetViewerPlaceholder
      class="w-full h-full"
      :id="props.id"
      :url="props.url"
      :width-descriptors="['1280w']"
      @load="updateFlexRatioBasedOnImage"
      @drag="emit('image:drag', $event)"
    />
  </AssetDropOnly>
</template>
