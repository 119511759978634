import { Ref, readonly, ref } from 'vue'

import { Company } from '@ankor-io/iam/types'

const company: Ref<Company | null> = ref(null)

export const useCompany = () => {
  /**
   * fetch company details from the server
   * @returns company
   */
  const fetchCompany = async (token: string) => {
    const company: Company | null = await fetch('/api/account/me/company', {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json() as Promise<Company>)
      .catch((err) => {
        console.error(err)
        return null as unknown as Promise<null>
      })

    return company
  }

  /**
   * get company name from the server
   * @returns company
   */
  const getCompany = async (token: string) => {
    if (company.value !== null) {
      return company.value
    }
    company.value = await fetchCompany(token)
    return company.value
  }

  return {
    company: readonly(company), // avoid the state to be mutated from a component
    getCompany,
  }
}
