import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M20.0484 6.35159C20.2734 6.57662 20.3997 6.88179 20.3997 7.19999C20.3997 7.51818 20.2734 7.82335 20.0484 8.04839L10.4484 17.6484C10.2234 17.8734 9.9182 17.9997 9.6 17.9997C9.2818 17.9997 8.97663 17.8734 8.7516 17.6484L3.9516 12.8484C3.73301 12.6221 3.61206 12.3189 3.61479 12.0043C3.61753 11.6897 3.74373 11.3887 3.96622 11.1662C4.18871 10.9437 4.48968 10.8175 4.80432 10.8148C5.11895 10.812 5.42208 10.933 5.6484 11.1516L9.6 15.1032L18.3516 6.35159C18.5766 6.12662 18.8818 6.00024 19.2 6.00024C19.5182 6.00024 19.8234 6.12662 20.0484 6.35159Z",
      "clip-rule": "evenodd"
    })
  ]))
}