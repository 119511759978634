<script lang="ts" setup>
import { Mixpanel } from 'mixpanel-browser'
import { Ref, computed, inject, onBeforeMount, ref, watch } from 'vue'

import SimpleAlertModal from '@ankor-io/blocks/components/modals/SimpleAlertModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import { ViewMode } from '@ankor-io/common/events/Editor'
import { EditableProposal, JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { EditableSection, SectionLayout } from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { ProposalEditableSlide, Slide } from '@ankor-io/common/proposal/Slide'
import { SlideType } from '@ankor-io/common/proposal/SlideType'
import {
  OutlineAdd,
  OutlineDelete,
  OutlineDocumentDuplicate,
  OutlineEdit,
  OutlineMoveDown,
  OutlineMoveUp,
  OutlineXMark,
} from '@ankor-io/icons/outline'

import HelpTip from '@/components/HelpTip.vue'
import SideEditorPanel from '@/components/SideEditorPanel.vue'
import EditorVue from '@/components/editor/Editor.vue'
import { AuthenticationContext } from '@/iam/types'
import { NavBarLayout } from '@/sections/navbar/types'
import { getSectionTypes, sectionsFor } from '@/sections/proposalSectionTypes'
import { createBlankSection } from '@/services/proposal/SectionService'
import { produceInitialisedEditableSection } from '@/services/proposal/factory/SectionFactory'
import { ThemeService, useThemeService } from '@/services/theme/ThemeService'
import { SECTION_ADDED } from '@/utils/mixPanel/constants'

const props = defineProps<{
  uri: string
  presentation: EditableProposal
}>()

const emit = defineEmits<{
  (e: 'update:presentation', value: JsonProposal): void
}>()

const presentationRef = computed(() => props.presentation)

const sections = computed(() => sectionsFor(currentSlideType.value!) || [])
const headerSection = computed(() => props.presentation.document.getHeader())

const headerRef: Ref<any> = ref(null)
const mode: Ref<ViewMode> = ref(ViewMode.SLIDE)

// hovered and selected section index
const hoveredSectionIndex: Ref<number | null> = ref(null)
// add section panel visibility
const isSectionPanelVisible: Ref<boolean> = ref(false)
// hovered and selected header
const isHoveringOnHeader: Ref<boolean> = ref(false)
// hovered over the add section button for the header
const addSectionButtonBelowHeader: Ref<boolean> = ref(false)
// section options panel visibility
const showSideEditorPanel: Ref<boolean> = ref(false)
// selected slide uri, is null for header
const selectedSlideUri: Ref<string | null> = ref(null)
// slide type of the currently active slide
const currentSlideType: Ref<SlideType | null> = ref(null)
// slide that is currently active
const activeSlideUri = ref<string | null>(null)
// all available section types
const sectionTypes = getSectionTypes()
// theme service to load presentation theme
const themeService: ThemeService = useThemeService()
// Modal helpers
const deleteModalHelper = modalHelper()

const mixPanel: Mixpanel | undefined = inject('mixPanel')

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const templateUri = `u::${authenticationContext.getUser().id}::template::default`

onBeforeMount(() => {
  const jsonPresentation = props.presentation.toJson()
  if (jsonPresentation.template.theme) {
    themeService.loadTheme(jsonPresentation.template.theme)
  }

  activeSlideUri.value = props.presentation.document.slides[0].getUri()
})

watch(
  () => JSON.stringify(props.presentation.template?.theme),
  () => {
    const jsonPresentation = props.presentation.toJson()
    themeService.loadTheme(jsonPresentation.template.theme)
  },
)

/**
 * show add section panel
 * @param slide the selected Slide
 * @param sectionIndex the index of section
 */
const showAddSectionPanel = (slide: Slide, sectionIndex: number) => {
  currentSlideType.value = slide.getType()
  addSectionButtonBelowHeader.value = false
  isSectionPanelVisible.value = true
  showSideEditorPanel.value = false
  hoveredSectionIndex.value = sectionIndex
  selectedSlideUri.value = slide.getUri()
}

/**
 * show section options panel
 * @param slide the selected Slide
 * @param sectionIndex the index of section
 */
const showOptionsPanel = (slideUri: string, sectionIndex: number) => {
  isSectionPanelVisible.value = false
  showSideEditorPanel.value = true
  hoveredSectionIndex.value = sectionIndex
  selectedSlideUri.value = slideUri
}

/**
 * show header options panel
 */
const showHeaderOptionsPanel = (): void => {
  isSectionPanelVisible.value = false
  showSideEditorPanel.value = true
  hoveredSectionIndex.value = null
  selectedSlideUri.value = null
}

/**
 *  change active slide
 * @param _event the event with slideUri
 */
const changeActiveSlide = (_event: { slideUri: string; viewMode: ViewMode }) => {
  const { slideUri, viewMode } = _event
  activeSlideUri.value = slideUri
  mode.value = viewMode
}

/**
 * Update header layout to position logo
 * @param layout NavBarLayout
 */
const updateHeaderLayout = (layout: NavBarLayout): void => {
  presentationRef.value.document.getHeader()?.setLayout(layout)
  emit('update:presentation', presentationRef.value.toJson())
}

/**
 * Update layout of the section
 * @param _event event with sectionId and layout
 */
const updateLayout = (_event: { sectionId: string; layout: SectionLayout }): void => {
  presentationRef.value.document.slides.forEach((slide: ProposalEditableSlide) => {
    slide.getSections().forEach((section: EditableSection<any>) => {
      if (section.id === _event.sectionId) {
        section.setLayout(_event.layout)
        emit('update:presentation', presentationRef.value.toJson())
        return
      }
    })
  })
}

/**
 * Update data of the section
 * @param event event with sectionId and data
 * @param section the section to update
 */
const updateValue = (event: any, section: any): void => {
  section.onChanges(event)

  presentationRef.value.document.slides.forEach((slide: ProposalEditableSlide) => {
    slide.getSections().forEach((section: EditableSection<any>) => {
      if (section.id === event.sectionId) {
        section.setData(section.getProxy())
        emit('update:presentation', presentationRef.value.toJson())
        return
      }
    })
  })
}

/**
 * add section to slide and update presentation
 *
 * @param sectionType type of section
 * @param sectionIndex index after which new section needs to be added
 * @param _slide the slide in which section needs to be added
 */
const addSection = (
  sectionType: SectionType,
  sectionIndex: number,
  hasOptionsPanel: boolean,
  openOptionsPanelOnAdd: boolean = true,
): void => {
  const slideUri = selectedSlideUri.value!
  const editableSection: EditableSection<SectionType> = createBlankSection(sectionType, slideUri)

  for (const slide of presentationRef.value.document.slides) {
    if (slide.getType() === SlideType.ITINERARY || slide.getType() === SlideType.VESSEL) {
      if (slide.getUri() === slideUri) {
        slide.addSection(editableSection, sectionIndex + 1)
        mixPanel?.track(SECTION_ADDED, { type: sectionType.valueOf(), uri: presentationRef.value.uri })
        emit('update:presentation', presentationRef.value.toJson())
      }
    }
  }

  // hide add section panel
  isSectionPanelVisible.value = false

  // show configuration options panel after adding section
  if (openOptionsPanelOnAdd && hasOptionsPanel) {
    showOptionsPanel(slideUri, sectionIndex + 1)
    showSideEditorPanel.value = true
    return
  }

  selectedSlideUri.value = null
}

/**
 * duplicate and add section to slide and update presentation
 *
 * @param sectionType type of section
 * @param sectionIndex index after which new section needs to be added
 * @param _slide the slide in which section needs to be added
 */
const duplicateSection = (sectionIndex: number, _slide: ProposalEditableSlide): void => {
  const sectionToCopy = _slide.getSections()[sectionIndex]

  const slideUri = _slide.getUri()
  const editableSection: EditableSection<SectionType> = produceInitialisedEditableSection(
    slideUri,
    sectionToCopy.getType(),
    sectionToCopy.layout,
    sectionToCopy.data,
  )

  for (const slide of presentationRef.value.document.slides) {
    if (slide.getUri() === slideUri) {
      slide.addSection(editableSection, sectionIndex + 1)
      emit('update:presentation', presentationRef.value.toJson())
      return
    }
  }
}

/**
 * Move section down
 * @param sectionIndex index of the section to be moved
 * @param _slide the slide in which section needs to be moved
 */
const moveDownSection = (sectionIndex: number, _slide: ProposalEditableSlide): void => {
  const slideUri = _slide.getUri()
  for (const slide of presentationRef.value.document.slides) {
    if (slide.getUri() === slideUri) {
      const sections = slide.getSections()

      // exit when its the last section of the slide
      if (sectionIndex === sections.length - 1) {
        return
      }

      sections.splice(sectionIndex + 1, 0, sections.splice(sectionIndex, 1)[0])
      emit('update:presentation', presentationRef.value.toJson())
      return
    }
  }
}

/**
 * Move section up
 * @param sectionIndex index of the section to be moved
 * @param _slide the slide in which section needs to be moved
 */
const moveUpSection = (sectionIndex: number, _slide: ProposalEditableSlide): void => {
  const slideUri = _slide.getUri()
  // exit early when its the first section of the slide
  if (sectionIndex === 0) {
    return
  }

  for (const slide of presentationRef.value.document.slides) {
    if (slide.getUri() === slideUri) {
      const sections = slide.getSections()
      sections.splice(sectionIndex, 0, sections.splice(sectionIndex - 1, 1)[0])
      emit('update:presentation', presentationRef.value.toJson())
      return
    }
  }
}

const deleteSection = (sectionIndex: number, slideUri: string) => {
  for (const slide of presentationRef.value.document.slides) {
    if (slide.getUri() === slideUri) {
      const editableSection = slide.getSections()[sectionIndex]
      slide.markDelete(editableSection)
      emit('update:presentation', presentationRef.value.toJson())
      return
    }
  }
}
</script>
<template>
  <div v-if="presentationRef" id="presentation" class="print:hidden h-[calc(100vh-8rem)] overflow-auto">
    <SideEditorPanel
      v-if="hoveredSectionIndex === null && selectedSlideUri === null && !isSectionPanelVisible && showSideEditorPanel"
      title="Header with Logo"
      class="side-editor-panel transition-[left] ease-in-out fixed z-40 top-20 bottom-2.5 w-96 rounded-lg shadow-lg overflow-y-auto overflow-x-hidden"
      :class="showSideEditorPanel ? 'left-2' : '-left-96'"
      @hide:panel="showSideEditorPanel = false"
    >
      <component
        :is="headerSection?.getConfigurationComponent()"
        :id="headerSection?.id"
        :layout="headerSection?.layout"
        @update:layout="updateHeaderLayout"
      />
    </SideEditorPanel>

    <!-- presentation header -->
    <div
      @click="() => (isSectionPanelVisible = false)"
      class="@container presentation presentation-header overflow-x-auto sticky top-0 z-30"
      :class="[{ 'w-96': $route.query.mode === 'mobile' }, { 'w-[64rem]': $route.query.mode === 'tablet' }]"
    >
      <!-- header -->
      <div
        @mouseenter="isHoveringOnHeader = true"
        @mouseleave="!addSectionButtonBelowHeader && (isHoveringOnHeader = false)"
      >
        <div
          v-if="isHoveringOnHeader && !isSectionPanelVisible && !showSideEditorPanel"
          class="edit-header-action-bar absolute z-10 top-2.5 right-2.5 flex gap-x-4 px-4 py-2.5 items-center rounded-lg text-sm border shadow-sm overflow-clip bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600"
        >
          <button
            type="button"
            class="p-1 group rounded-md focus:ring-2 focus:ring-primary-300"
            @click.stop="showHeaderOptionsPanel"
          >
            <OutlineEdit
              class="w-3.5 self-center stroke-gray-900 dark:stroke-white group-hover:stroke-primary-600 dark:group-hover:stroke-primary-600"
            />
          </button>
        </div>

        <!-- header component -->
        <div v-if="presentationRef.document.getHeader()" :ref="(el) => (headerRef = el)">
          <EditorVue v-slot="slotProps" :section="presentationRef.document.getHeader()!">
            <component
              :isHydrating="slotProps.section.isHydrating()"
              :lifecycle="slotProps.section.setLifecycleHooks.bind(slotProps.section)"
              :notify="slotProps.section.notifyAll.bind(slotProps.section)"
              :subscribe="slotProps.section.subscribe.bind(slotProps.section)"
              :unsubscribe="slotProps.section.unsubscribe.bind(slotProps.section)"
              :is="slotProps.section.getComponent()"
              :id="slotProps.section.id"
              :data="slotProps.section.data"
              :refs="slotProps.section.refs"
              :layout="slotProps.section.layout"
              :properties="slotProps.section.getProperties()"
              :state="slotProps.state"
              :uri="slotProps.section.getSlideUri()"
              :proposalUri="presentationRef.uri"
              :templateUri="`u::${$authenticationContext.getUser().id}::template::default`"
              :document="presentationRef.toJson()"
              @update:value="updateValue($event, slotProps.section)"
              @update:state="slotProps.updateState"
              @change:slide="changeActiveSlide($event)"
              @vue:beforeMount="slotProps.section.beforeMount()"
              @vue:beforeUnmount="slotProps.section.beforeUnmount()"
            />
          </EditorVue>
        </div>
      </div>
    </div>

    <!-- add section button -->
    <div
      v-show="(isHoveringOnHeader || addSectionButtonBelowHeader) && !isSectionPanelVisible && !showSideEditorPanel"
      class="sticky w-full z-40 mx-auto"
      :class="[{ 'w-96': $route.query.mode === 'mobile' }, { 'w-[64rem]': $route.query.mode === 'tablet' }]"
      :style="`top: ${headerRef?.scrollHeight || 0}px`"
      @mouseleave="!isSectionPanelVisible && !showSideEditorPanel && (addSectionButtonBelowHeader = false)"
      @mouseenter="!isSectionPanelVisible && !showSideEditorPanel && (addSectionButtonBelowHeader = true)"
    >
      <!-- Width is going over scrollbar for some reason -->
      <div class="group absolute w-full h-11 flex items-center -top-6">
        <div class="h-1 flex-grow transition-all bg-primary-600 group-hover:bg-primary-800"></div>
        <button
          type="button"
          class="flex items-center font-medium rounded-lg text-sm px-4 py-3 text-center text-white border border-transparent bg-primary-600 transition-all hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
          @click.stop="
            showAddSectionPanel(
              mode === ViewMode.STACKED
                ? presentationRef.document.slides[0]
                : presentationRef.document.slides.find((slide) => slide.getUri() === activeSlideUri)!,
              -1,
            )
          "
        >
          <OutlineAdd class="w-3 h-3 mr-2 self-center stroke-white" />
          <span class="text-sm font-medium">Add Section</span>
        </button>
        <div class="h-1 flex-grow transition-all bg-primary-600 group-hover:bg-primary-800"></div>
      </div>
    </div>

    <!-- add section panel -->
    <div
      v-show="isSectionPanelVisible"
      class="add-section-panel fixed z-30 top-20 left-2.5 bottom-2.5 w-96 rounded-lg py-4 px-5 overflow-y-scroll overflow-x-hidden border shadow-xl border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800"
    >
      <h6 class="text-gray-900 dark:text-white">Add new section</h6>
      <button
        class="cursor-pointer absolute right-0 top-0 pt-5 pr-4 text-gray-900 dark:text-white hover:text-primary-600 dark:hover:text-primary-600"
        @click="isSectionPanelVisible = false"
      >
        <OutlineXMark class="w-3 h-3" />
      </button>
      <HelpTip class="my-5" tip="Choose a section type to add." />

      <div class="inline-grid grid-cols-2 gap-4">
        <div v-for="sectionType in sections" :key="sectionType.label" class="cursor-pointer">
          <p class="text-sm font-semibold truncate text-gray-900 dark:text-white">{{ sectionType.label }}</p>
          <div class="relative group flex w-[9.5rem] h-[9.5rem] bg-blue-50 px-3 mt-2 rounded-md">
            <component
              :is="sectionType.icon"
              :class="sectionType.iconVariant === 'solid' ? 'fill-primary-500' : 'stroke-primary-500'"
            />
            <button
              class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
              type="button"
              @click="
                addSection(
                  sectionType.type,
                  hoveredSectionIndex!,
                  sectionType.optionsPanel,
                  sectionType.openOptionsPanelOnAdd,
                )
              "
            >
              Add
            </button>
            <div
              class="z-10 absolute top-0 right-0 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- slide -->
    <template v-for="slide in presentationRef.document.slides" :key="slide.getId()">
      <!-- filter out anything else besides Vessel and Itinerary -->
      <div
        class="A-slide pb-6 max-w-360 mx-auto"
        @click="() => (isSectionPanelVisible = false)"
        v-if="mode === ViewMode.STACKED || (mode === ViewMode.SLIDE && activeSlideUri === slide.getUri())"
      >
        <template v-for="(section, sectionIndex) in slide.getSections()" :key="section.id">
          <!-- section -->
          <div
            v-if="!sectionTypes[section.getType()].autoRemove"
            class="relative A-section mx-auto overflow-visible"
            :id="section.id"
            :class="[
              section.getType(),
              { 'w-96': $route.query.mode === 'mobile' },
              { 'w-[64rem]': $route.query.mode === 'tablet' },
            ]"
            @mouseenter="!isSectionPanelVisible && !showSideEditorPanel && (hoveredSectionIndex = sectionIndex)"
            @mouseleave="!isSectionPanelVisible && !showSideEditorPanel && (hoveredSectionIndex = null)"
          >
            <!-- Options panel on the left hand side -->
            <SideEditorPanel
              v-if="
                hoveredSectionIndex === sectionIndex &&
                sectionTypes[section.getType()].optionsPanel &&
                selectedSlideUri === slide.getUri()
              "
              class="transition-[left] ease-in-out z-30 fixed top-[4.5rem] bottom-2 text-gray-900 dark:text-white"
              :class="showSideEditorPanel ? 'left-2' : '-left-96'"
              :title="sectionTypes[section.getType()].optionsPanelTitle || 'Options'"
              :key="`${section.id}-side-panel`"
              @hide:panel="showSideEditorPanel = false"
            >
              <component
                :key="`${section.id}-component`"
                :is="section.getConfigurationComponent()"
                :uri="section.getSlideUri()"
                :proposalUri="presentationRef.uri"
                :templateUri="`u::${$authenticationContext.getUser().id}::template::default`"
                :id="section.id"
                :data="section.data"
                :refs="section.refs"
                :layout="section.layout"
                :lifecycle="section.setLifecycleHooks.bind(section)"
                :notify="section.notifyAll.bind(section)"
                :subscribe="section.subscribe.bind(section)"
                :unsubscribe="section.unsubscribe.bind(section)"
                :document="presentationRef.toJson()"
                @update:layout="updateLayout"
                @update:value="updateValue($event, section)"
              />
            </SideEditorPanel>

            <!-- edit move delete section buttons -->
            <div
              v-if="hoveredSectionIndex === sectionIndex && !isSectionPanelVisible && !showSideEditorPanel"
              class="absolute z-30 top-2.5 right-2.5 h-full flex bg-transparent pointer-events-none"
            >
              <div
                class="edit-action-bar sticky h-14 flex gap-x-4 px-4 py-2.5 items-center rounded-lg shadow-sm overflow-clip border text-sm bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600 pointer-events-auto"
                :style="`top: ${headerRef ? headerRef.scrollHeight + 10 : 10}px`"
              >
                <button
                  v-if="sectionTypes[section.getType()].optionsPanel"
                  type="button"
                  class="p-1 group rounded-md focus:ring-2 focus:ring-primary-300"
                  @click.stop="showOptionsPanel(slide.getUri(), sectionIndex)"
                >
                  <OutlineEdit
                    class="w-3.5 self-center stroke-gray-900 dark:stroke-white group-hover:stroke-primary-600 dark:group-hover:stroke-primary-600"
                  />
                </button>
                <button
                  v-if="sectionIndex !== slide.getSections().length - 1"
                  type="button"
                  class="p-1 group rounded-md focus:ring-2 focus:ring-primary-300"
                  @click.stop="moveDownSection(sectionIndex, slide)"
                >
                  <OutlineMoveDown
                    class="w-4 self-center stroke-gray-900 dark:stroke-white group-hover:stroke-primary-600 dark:group-hover:stroke-primary-600"
                  />
                </button>
                <button
                  v-if="sectionIndex !== 0"
                  type="button"
                  class="p-1 group rounded-md focus:ring-2 focus:ring-primary-300"
                  @click.stop="moveUpSection(sectionIndex, slide)"
                >
                  <OutlineMoveUp
                    class="w-4 self-center stroke-gray-900 dark:stroke-white group-hover:stroke-primary-600 dark:group-hover:stroke-primary-600"
                  />
                </button>
                <button
                  type="button"
                  class="p-1 group rounded-md focus:ring-2 focus:ring-primary-300"
                  @click.stop="duplicateSection(sectionIndex, slide)"
                >
                  <OutlineDocumentDuplicate
                    class="w-3.5 self-center stroke-gray-900 dark:stroke-white group-hover:stroke-primary-600 dark:group-hover:stroke-primary-600"
                  />
                </button>
                <button
                  type="button"
                  class="p-1 group rounded-md focus:ring-2 focus:ring-primary-300"
                  @click="
                    () =>
                      deleteModalHelper.show({
                        title: 'Delete this section?',
                        uri: slide.getUri(),
                        sectionIndex,
                        sectionName: sectionTypes[section.getType()].label,
                      })
                  "
                >
                  <OutlineDelete
                    class="w-3.5 self-center stroke-gray-900 dark:stroke-white group-hover:stroke-primary-600 dark:group-hover:stroke-primary-600"
                  />
                </button>
              </div>
            </div>

            <!-- section component -->
            <div class="presentation presentation-section @container p-4 sm:p-6">
              <EditorVue v-slot="slotProps" :section="section" @hydrated="updateValue($event, section)">
                <component
                  :isHydrating="section.isHydrating()"
                  :lifecycle="section.setLifecycleHooks.bind(section)"
                  :notify="slotProps.section.notifyAll.bind(slotProps.section)"
                  :subscribe="slotProps.section.subscribe.bind(slotProps.section)"
                  :unsubscribe="slotProps.section.unsubscribe.bind(slotProps.section)"
                  :is="slotProps.section.getComponent()"
                  :id="slotProps.section.id"
                  :data="slotProps.section.data"
                  :refs="slotProps.section.refs"
                  :layout="slotProps.section.layout"
                  :properties="slotProps.section.getProperties()"
                  :state="slotProps.state"
                  :uri="slotProps.section.getSlideUri()"
                  :proposalUri="presentationRef.uri"
                  :templateUri="templateUri"
                  :document="presentationRef.toJson()"
                  @section:delete="deleteSection(sectionIndex, slide.getUri())"
                  @update:value="updateValue($event, section)"
                  @update:layout="updateLayout"
                  @update:state="slotProps.updateState"
                  @change:slide="changeActiveSlide($event)"
                  @vue:beforeMount="section.beforeMount()"
                  @vue:beforeUnmount="section.beforeUnmount()"
                />
              </EditorVue>
            </div>
          </div>

          <!-- add section button -->
          <div
            v-show="hoveredSectionIndex === sectionIndex && !isSectionPanelVisible && !showSideEditorPanel"
            class="relative z-40 mx-auto"
            :class="[
              { 'h-7': sectionIndex === slide.getSections().length - 1 },
              { 'w-96': $route.query.mode === 'mobile' },
              { 'w-[64rem]': $route.query.mode === 'tablet' },
            ]"
            @mouseenter="!isSectionPanelVisible && !showSideEditorPanel && (hoveredSectionIndex = sectionIndex)"
            @mouseleave="!isSectionPanelVisible && !showSideEditorPanel && (hoveredSectionIndex = null)"
          >
            <div
              class="group absolute z-10 w-full h-11 flex items-center"
              :class="sectionIndex === slide.getSections().length - 1 ? 'bottom-1' : '-bottom-6'"
            >
              <div class="h-1 flex-grow transition-all bg-primary-600 group-hover:bg-primary-800"></div>
              <button
                type="button"
                class="flex items-center font-medium rounded-lg text-sm px-4 py-3 text-center text-white border border-transparent bg-primary-600 transition-all hover:bg-primary-800 focus:ring-4 focus:ring-primary-300"
                @click.stop="showAddSectionPanel(slide, sectionIndex)"
              >
                <OutlineAdd class="w-3 h-3 mr-2 self-center stroke-white" />
                <span class="text-sm font-medium">Add Section</span>
              </button>
              <div class="h-1 flex-grow transition-all bg-primary-600 group-hover:bg-primary-800"></div>
            </div>
          </div>
        </template>
      </div>
    </template>

    <!-- Delete section modal -->
    <Teleport defer to="body">
      <!-- delete modal -->
      <SimpleAlertModal
        v-if="deleteModalHelper.isVisible()"
        buttonText="Yes, Delete"
        :model="deleteModalHelper.model"
        @confirm="(model: any) => [deleteModalHelper.hide(), deleteSection(model.sectionIndex, model.uri)]"
        @dismiss="deleteModalHelper.hide()"
      >
        <template #title="{ modelValue }">
          <h3>{{ modelValue.title }}</h3>
        </template>
        <template #default="{ modelValue }">
          <div class="space-y-2.5 text-center text-sm text-gray-500 dark:text-gray-400">
            <p>You are about to permanently delete</p>
            <p class="font-bold">{{ modelValue.sectionName }}</p>
            <p>Are you sure you want to proceed?</p>
          </div>
        </template>
      </SimpleAlertModal>
    </Teleport>
  </div>
</template>

<style lang="scss">
#presentation {
  background: v-bind('presentationRef.template.theme.color.background');
}
.presentation {
  background: v-bind('presentationRef.template.theme.color.background');
  font-family: v-bind('presentationRef.template.theme.font.primary.type.family');
  color: v-bind('presentationRef.template.theme.font.primary.color');
  h1 {
    font-family: v-bind('presentationRef.template.theme.font.heading.type.family');
    color: v-bind('presentationRef.template.theme.font.heading.color');
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: v-bind('presentationRef.template.theme.font.subheading.type.family');
    color: v-bind('presentationRef.template.theme.font.subheading.color');
  }

  .buttonSection {
    a {
      font-family: v-bind('presentationRef.template.theme.font.primary.type.family');
      color: v-bind('presentationRef.template.theme.font.primary.color');
      background: v-bind('presentationRef.template.theme.color.primary');
      border: 1px solid v-bind('presentationRef.template.theme.color.primary');
    }
  }

  .icon-theme-subheading {
    color: v-bind('presentationRef.template.theme.font.subheading.color');
  }

  .icon-theme-heading {
    color: v-bind('presentationRef.template.theme.font.heading.color');
  }

  .icon-theme-primary {
    color: v-bind('presentationRef.template.theme.color.primary');
  }

  .icon-fill-theme-subheading {
    fill: v-bind('presentationRef.template.theme.font.subheading.color');
  }
}
</style>
