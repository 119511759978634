<script setup lang="ts">
// Introduced as part of issue-936 - most of script and template updated to use swiper
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Ref, onMounted, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { OutlineChevronLeft, OutlineChevronRight, OutlineXMark } from '@ankor-io/icons/outline/index'

type ImageCarouselProps = {
  /**
   * Carousel slides
   */
  slides: string[]
  slideTo: number
}

const props = withDefaults(defineProps<ImageCarouselProps>(), {
  slideTo: 0,
})

onMounted(() => {
  if (props.slideTo) {
    updateSlideOnClick(props.slideTo)
  }
})

const modalRef: Ref<HTMLButtonElement | null> = ref(null)
const currentSlide: Ref<number> = ref(0)
const swiper: Ref<typeof Swiper | null> = ref(null)
// Defining the modules to use for swiper
const modules = [Pagination]

const emit = defineEmits<{
  (e: 'close'): void
}>()

const onSwiper = (event: any) => {
  swiper.value = event
}

const updateSlide = (to: number): void => {
  const element = document.getElementById(to.toString())
  element?.scrollIntoView({ inline: 'center', behavior: 'smooth' })
  currentSlide.value = to
}

const onSlideChange = (event: any) => {
  updateSlide(event.activeIndex)
}

const updateSlideOnClick = (index: number) => {
  swiper.value!.slideTo(index)
  updateSlide(index)
}
</script>
<template>
  <!-- Image carousel modal -->
  <div
    tabindex="0"
    ref="modalRef"
    class="fixed top-0 left-0 right-0 z-modal w-full h-full max-h-screen p-4 inset-0 bg-black"
    @keyup.esc="emit('close')"
    @keyup.left="updateSlideOnClick(currentSlide - 1)"
    @keyup.right="updateSlideOnClick(currentSlide + 1)"
  >
    <!-- Close button -->
    <button class="absolute top-0 right-0 p-4 z-50 group" @click="emit('close')">
      <OutlineXMark class="h-3 w-3 stroke-white group-hover:stroke-gray-300" />
    </button>

    <!-- Carousel wrapper -->
    <div class="flex flex-col gap-4">
      <!-- Slides -->
      <div class="h-[calc(100vh-13rem)] relative flex content-around w-full gap-4 flex-wrap sm:flex-row pt-12">
        <Swiper
          class="h-full"
          :modules="modules"
          :pagination="true"
          :slides-per-view="1"
          :space-between="12"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
        >
          <SwiperSlide
            v-for="(slide, index) in props.slides.filter((i) => !!i)"
            class="relative flex grow shrink-0 w-full sm:px-28"
            :key="index"
          >
            <img v-if="slide.startsWith('http')" class="object-contain w-full h-full rounded" :src="slide" />
            <AssetViewer v-else class="object-contain w-full h-full rounded" :url="`/media/${slide}`" />
          </SwiperSlide>
        </Swiper>

        <!-- Previous & Next buttons -->
        <div class="hidden sm:flex w-full justify-between">
          <!-- Previous -->
          <button
            type="button"
            class="z-30 flex items-center justify-center h-full cursor-pointer group focus:outline-none top-0 left-0 sm:absolute"
            :class="currentSlide > 0 ? 'visible' : 'invisible'"
            @click="updateSlideOnClick(currentSlide - 1)"
          >
            <span
              class="inline-flex items-center justify-center rounded-full w-10 h-10 bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none"
            >
              <OutlineChevronLeft class="text-white w-6 h-6" />
              <span class="sr-only">Previous</span>
            </span>
          </button>

          <!-- Next -->
          <button
            type="button"
            class="z-30 flex items-center justify-center h-full cursor-pointer group focus:outline-none top-0 right-0 sm:absolute"
            :class="currentSlide < props.slides.length - 1 ? 'visible' : 'invisible'"
            @click="updateSlideOnClick(currentSlide + 1)"
          >
            <span
              class="inline-flex items-center justify-center rounded-full w-10 h-10 bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none"
            >
              <OutlineChevronRight class="text-white w-6 h-6" />
              <span class="sr-only">Next</span>
            </span>
          </button>
        </div>
      </div>

      <!-- Gallery -->
      <div class="flex w-full overflow-x-auto h-[10rem]">
        <button
          v-for="(slide, index) in props.slides.filter((i) => !!i)"
          :key="index"
          :id="index.toString()"
          class="flex shrink-0 rounded overflow-hidden transition-[transform] ease-in-out duration-500 w-64"
          :class="{ 'scale-90': index !== currentSlide }"
          @click="updateSlideOnClick(index)"
        >
          <img v-if="slide.startsWith('http')" class="object-cover block w-full h-full" :src="slide" />
          <AssetViewer v-else class="object-cover block w-full h-full" :url="`/media/${slide}`" />
        </button>
      </div>
    </div>
  </div>
</template>
