<script setup lang="ts">
import { Ref, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'

/**
 * Currently supporting four images
 * with minimum of two images required,
 * can be extended to multiple at a later point
 */
export interface ResponsiveImageProps {
  /**
   * The array of images to be laid out with equal heights
   */
  url: string
  id?: string
}

// define the props object
const props = defineProps<ResponsiveImageProps>()

const flexRatio: Ref<number> = ref(1)
const updateFlexRatioBasedOnImage = (event: Event) => {
  const target = event.target as HTMLImageElement
  // image's aspect ratio is alsways going to be naturalWidth/naturalHeight
  if (!target) return
  flexRatio.value = target.naturalWidth / target.naturalHeight
}
</script>
<template>
  <div :style="{ flex: flexRatio }">
    <AssetViewer
      class="w-full h-full"
      loading-strategy="eager"
      :id="props.id"
      :url="props.url"
      :width-descriptors="['1280w']"
      @load="updateFlexRatioBasedOnImage"
    />
  </div>
</template>
