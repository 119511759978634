<script setup lang="ts">
import { GrowthBook } from '@growthbook/growthbook'
import { Mixpanel } from 'mixpanel-browser'
import { Ref, inject, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

import { ViewMode } from '@ankor-io/common/events/Editor'
import { JsonProposal, Proposal } from '@ankor-io/common/proposal/Proposal'
import { SlideType } from '@ankor-io/common/proposal/SlideType'

import { getSectionTypes } from '@/sections/proposalSectionTypes'
import { ThemeService, useThemeService } from '@/services/theme/ThemeService'
import { WHEELHOUSE_20250103_TRIPS_V2_APP } from '@/utils/growthbook/constants'
import { SHARED_LINK_VIEW } from '@/utils/mixPanel/constants'

export interface ProposalViewerProps {
  proposal: Proposal
}

const props = defineProps<ProposalViewerProps>()

const route = useRoute()
const themeService: ThemeService = useThemeService()
const growthbook: GrowthBook = inject('growthbook')!
const mixPanel: Mixpanel | undefined = inject('mixPanel')!

const jsonProposal: Ref<JsonProposal> = ref(props.proposal.toJson())
const activeSlideUri: Ref<string | null> = ref(null)
const mode: Ref<ViewMode> = ref(ViewMode.STACKED)
const sectionTypes = getSectionTypes()

/**
 * change active slide and update mode
 * @param _event event to change slide
 */
const changeSlide = (_event: { slideUri: string; viewMode: ViewMode }) => {
  const { slideUri, viewMode } = _event
  mode.value = viewMode
  activeSlideUri.value = slideUri
}

onBeforeMount(() => {
  if (growthbook.isOn(WHEELHOUSE_20250103_TRIPS_V2_APP) && route.name !== 'trips-preview') {
    mixPanel.track(SHARED_LINK_VIEW, { uri: props.proposal.uri })
  }

  jsonProposal.value = props.proposal.toJson()
  if (jsonProposal.value.template.theme) {
    themeService.loadTheme(jsonProposal.value.template.theme)
  }
})
</script>
<template>
  <div id="proposal">
    <!-- header -->
    <div v-if="props.proposal.document.header !== null" class="proposal-viewer sticky top-0 z-30">
      <component
        :lifecycle="props.proposal.document.header.setLifecycleHooks.bind(props.proposal.document.header)"
        :is="props.proposal.document.header.getComponent()"
        :data="props.proposal.document.header.data"
        :refs="props.proposal.document.header.refs"
        :layout="props.proposal.document.header.layout"
        :properties="props.proposal.document.header.getProperties()"
        :uri="props.proposal.document.header.getSlideUri()"
        :proposalUri="jsonProposal.uri"
        :document="jsonProposal"
        @change:slide="changeSlide($event)"
      />
    </div>

    <!-- slide -->
    <div
      v-for="slide in props.proposal.document.slides"
      :key="slide.getId()"
      class="A-slide proposal-viewer max-w-360 mx-auto"
    >
      <!-- filter out Yacht slide types for now, we don't have sections for those -->
      <template
        v-if="
          slide.getType() !== SlideType.YACHT &&
          (mode === ViewMode.STACKED || (mode === ViewMode.SLIDE && activeSlideUri === slide.getId()))
        "
      >
        <!-- section -->
        <template v-for="(section, sectionIndex) in slide.getSections()" :key="`slide-${slide.getId()}-section-${sectionIndex}`">
          <div
            v-if="!sectionTypes[section.getType()].autoRemove"
            class="A-section @container mx-auto overflow-x-auto p-4 sm:p-6"
            :class="section.getType()"
          >
            <component
              :lifecycle="section.setLifecycleHooks.bind(section)"
              :is="section.getComponent()"
              :data="section.data"
              :refs="section.refs"
              :layout="section.layout"
              :properties="section.getProperties()"
              :uri="section.getSlideUri()"
              :proposalUri="jsonProposal.uri"
              :document="jsonProposal"
              @change:slide="changeSlide($event)"
            />
          </div>
        </template>
      </template>
    </div>

    <!-- footer -->
    <div class="proposal-viewer" v-if="props.proposal.document.footer !== null">
      <component
        :lifecycle="props.proposal.document.footer.setLifecycleHooks.bind(props.proposal.document.footer)"
        :is="props.proposal.document.footer.getComponent()"
        :data="props.proposal.document.footer.data"
        :refs="props.proposal.document.footer.refs"
        :layout="props.proposal.document.footer.layout"
        :properties="props.proposal.document.footer.getProperties()"
        :uri="props.proposal.document.footer.getSlideUri()"
        :proposalUri="jsonProposal.uri"
        :document="jsonProposal"
      />
    </div>
  </div>
</template>
<style lang="scss">
#proposal {
  background: v-bind('jsonProposal.template.theme.color.background');
}
.proposal-viewer {
  background: v-bind('jsonProposal.template.theme.color.background');
  font-family: v-bind('jsonProposal.template.theme.font.primary.type.family');
  color: v-bind('jsonProposal.template.theme.font.primary.color');
  h1 {
    font-family: v-bind('jsonProposal.template.theme.font.heading.type.family');
    color: v-bind('jsonProposal.template.theme.font.heading.color');
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: v-bind('jsonProposal.template.theme.font.subheading.type.family');
    color: v-bind('jsonProposal.template.theme.font.subheading.color');
  }
  .buttonSection {
    a {
      font-family: v-bind('jsonProposal.template.theme.font.primary.type.family');
      color: v-bind('jsonProposal.template.theme.font.primary.color');
      background: v-bind('jsonProposal.template.theme.color.primary');
      border: 1px solid v-bind('jsonProposal.template.theme.color.primary');
    }
  }
  .icon-theme-subheading {
    color: v-bind('jsonProposal.template.theme.font.subheading.color');
  }
  .icon-theme-heading {
    color: v-bind('jsonProposal.template.theme.font.heading.color');
  }
  .icon-theme-primary {
    color: v-bind('jsonProposal.template.theme.color.primary');
  }
  .icon-fill-theme-subheading {
    fill: v-bind('jsonProposal.template.theme.font.subheading.color');
  }
}
</style>
