<script setup lang="ts">
import { Ref, ref } from 'vue'

import { SolidPlus } from '@ankor-io/icons/solid'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

import { KeyValueSpecification } from './types/types'

type Props = {
  specification: KeyValueSpecification
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'add:specification', value: { key: string; value: string }): void
}>()

const specificationRef: Ref<KeyValueSpecification> = ref(props.specification)

const addNewSpecItem = () => {
  if (!specificationRef.value.key || !specificationRef.value.value) {
    return
  }

  emit('add:specification', { key: specificationRef.value.key, value: specificationRef.value.value as string })
  specificationRef.value = { key: '', value: '' }
}
</script>
<template>
  <div class="spec-item relative rounded-lg @sm:rounded-2xl p-6 bg-white">
    <div
      class="h-full flex flex-col hover:border-dashed border-theme-primary gap-x-2 gap-y-1 shadow-theme-shading text-center"
    >
      <SingleLineTextEditor
        class="mb-1 cursor-text flex justify-center"
        placeholder="Add label"
        :value="specificationRef.key"
        @update:value="specificationRef.key = $event"
      />
      <div class="cursor-text flex flex-col items-center justify-center">
        <SingleLineTextEditor
          class="font-bold"
          placeholder="Add value"
          :value="specificationRef.value as string || ''"
          @update:value="specificationRef.value = $event"
        />
      </div>
      <button
        id="add-new-specification"
        class="px-3 py-2 mx-auto flex flex-nowrap gap-x-2 h-8 w-20 border text-primary-700 border-primary-600 hover:bg-primary-300 rounded-lg"
        @click="addNewSpecItem"
      >
        <SolidPlus class="size-4 shrink-0 self-center fill-primary-600" />
        <span class="font-medium text-xs text-nowrap">Add</span>
      </button>
    </div>
  </div>
</template>
