<script lang="ts" setup>
import { Ref, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import { ToysAmenitiesSectionData } from '@/sections/vessel/toys-amenities/types/types'

type Props = {
  id: string
  proposalUri: string
  data: ToysAmenitiesSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<ToysAmenitiesSectionData>): void
}>()

const isClamped: Ref<boolean> = ref(true)

const updateToy = (index: number, value: string): void => {
  const data = ObjectUtil.deepCopy(props.data) as ToysAmenitiesSectionData
  data.toys[index] = value
  emit('update:value', { sectionId: props.id, data })
}

const updateAmenity = (index: number, value: string): void => {
  const data = ObjectUtil.deepCopy(props.data) as ToysAmenitiesSectionData
  data.amenities[index] = value
  emit('update:value', { sectionId: props.id, data })
}

const updateEntertainment = (index: number, value: string): void => {
  const data = ObjectUtil.deepCopy(props.data) as ToysAmenitiesSectionData
  data.entertainment[index] = value
  emit('update:value', { sectionId: props.id, data })
}

const updateTender = (index: number, value: string): void => {
  const data = ObjectUtil.deepCopy(props.data) as ToysAmenitiesSectionData
  data.tenders[index] = value
  emit('update:value', { sectionId: props.id, data })
}

const updateHeading = (value: string) => {
  const data = ObjectUtil.deepCopy(props.data)
  emit('update:value', { sectionId: props.id, data: { ...data, heading: value } })
}
</script>
<template>
  <div id="toys-amenities-section">
    <!-- Heading -->
    <h1 class="font-light mx-auto max-w-screen-sm text-center sm:mb-8 text-2xl @sm:text-3xl tracking-tight">
      <SingleLineTextEditor
        class="mb-4 text-center font-bold"
        :value="props.data.heading"
        @update:value="updateHeading($event)"
      />
    </h1>

    <div
      id="toys-amenities-container"
      class="flex flex-col p-8 @sm:p-16 bg-white drop-shadow-md border border-gray-200 dark:border-gray-600 rounded-lg @sm:rounded-2xl"
    >
      <!-- Toys -->
      <div v-if="props.data.toys?.length" class="py-8 first:pt-0 last:pb-0">
        <h1 class="mb-4 !text-xl @sm:!text-2xl font-bold">Toys</h1>
        <div class="grid gap-x-8 gap-y-4 @sm:grid-cols-2 @md:grid-cols-4">
          <SingleLineTextEditor
            v-for="(toy, toyIndex) in props.data.toys.slice(0, isClamped ? 8 : undefined)"
            placeholder="Add toy"
            :key="toyIndex"
            :value="toy"
            @update:value="updateToy(toyIndex, $event)"
          ></SingleLineTextEditor>
        </div>
      </div>

      <hr v-if="!isClamped" />

      <template v-if="!isClamped">
        <!-- Amenities -->
        <div v-if="props.data.amenities?.length" class="py-8 first:pt-0 last:pb-0">
          <h1 class="mb-4 !text-xl @sm:!text-2xl font-bold">Amenities</h1>
          <div class="grid gap-x-8 gap-y-4 @sm:grid-cols-2 @md:grid-cols-4">
            <SingleLineTextEditor
              v-for="(amenity, amenityIndex) in props.data.amenities"
              placeholder="Add amenity"
              :key="amenityIndex"
              :value="amenity"
              @update:value="updateAmenity(amenityIndex, $event)"
            ></SingleLineTextEditor>
          </div>
        </div>

        <hr />

        <!-- Entertainment -->
        <div v-if="props.data.entertainment?.length" class="py-8 first:pt-0 last:pb-0">
          <h1 class="mb-4 !text-xl @sm:!text-2xl font-bold">Entertainment</h1>
          <div class="grid gap-x-8 gap-y-4 @sm:grid-cols-2 @md:grid-cols-4">
            <SingleLineTextEditor
              v-for="(entertainment, entertainmentIndex) in props.data.entertainment"
              placeholder="Add entertainment"
              :key="entertainmentIndex"
              :value="entertainment"
              @update:value="updateEntertainment(entertainmentIndex, $event)"
            ></SingleLineTextEditor>
          </div>
        </div>

        <hr />

        <!-- Tenders -->
        <div v-if="props.data.tenders?.length" class="py-8 first:pt-0 last:pb-0">
          <h1 class="mb-4 !text-xl @sm:!text-2xl font-bold">Tenders</h1>
          <div class="grid gap-x-8 gap-y-4 @sm:grid-cols-2 @md:grid-cols-4">
            <SingleLineTextEditor
              v-for="(tender, tenderIndex) in props.data.tenders"
              placeholder="Add tender"
              :key="tenderIndex"
              :value="tender"
              @update:value="updateTender(tenderIndex, $event)"
            ></SingleLineTextEditor>
          </div>
        </div>
      </template>

      <span
        v-if="
          props.data.amenities?.length ||
          props.data.entertainment?.length ||
          props.data.tenders?.length ||
          props.data.toys?.length > 8
        "
        class="cursor-pointer text-theme-primary"
        @click="isClamped = !isClamped"
      >
        {{ isClamped ? 'Show more' : 'Show less' }}
      </span>
    </div>
  </div>
</template>
