<script setup lang="ts">
import { Ref, ref } from 'vue'

import { SolidCircleX } from '@ankor-io/icons/solid'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

import { KeyValueSpecification } from './types/types'

type Props = {
  specification: KeyValueSpecification
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'delete:specification'): void
  (e: 'update:value', value: { key: keyof KeyValueSpecification; value: string; arrIndex?: number }): void
}>()

const isHovered: Ref<boolean> = ref(false)
</script>
<template>
  <div
    class="spec-item relative rounded-lg @sm:rounded-2xl p-6 bg-white"
    @mouseleave="isHovered = false"
    @mouseenter="isHovered = true"
  >
    <div
      class="h-full flex flex-col hover:border-dashed border-theme-primary gap-x-2 gap-y-1 shadow-theme-shading text-center"
    >
      <SingleLineTextEditor
        class="mb-1 cursor-text flex justify-center"
        placeholder="Add label"
        :value="props.specification.key"
        @update:value="emit('update:value', { key: 'key', value: $event })"
      />
      <div class="cursor-text flex flex-col items-center justify-center">
        <template v-if="typeof props.specification.value === 'object'">
          <SingleLineTextEditor
            v-for="(item, i) in props.specification.value"
            :key="`specification-value-${i}`"
            class="font-bold"
            placeholder="Add value"
            :value="`${item?.toString()}`"
            @update:value="emit('update:value', { key: 'value', value: $event, arrIndex: i })"
          />

          <SingleLineTextEditor
            :key="`specification-value-${props.specification.value ? props.specification.value.length : 0}`"
            class="font-bold"
            placeholder="Add value"
            @update:value="
              emit('update:value', {
                key: 'value',
                value: $event,
                arrIndex: props.specification.value.length,
              })
            "
          />
        </template>
        <SingleLineTextEditor
          v-else
          class="font-bold"
          placeholder="Add value"
          :value="props.specification.value"
          @update:value="emit('update:value', { key: 'value', value: $event })"
        />
      </div>
    </div>

    <div v-if="isHovered" class="absolute z-10 -top-1 -right-1 cursor-pointer" @click="emit('delete:specification')">
      <SolidCircleX class="w-6 h-6 fill-gray-500" />
    </div>
  </div>
</template>
