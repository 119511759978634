<script setup lang="ts">
import { GrowthBook } from '@growthbook/growthbook'
import { SearchClient, SearchIndex } from 'algoliasearch/lite'
import { initPopovers } from 'flowbite'
import { DateTime } from 'luxon'
import { Ref, inject, onMounted, ref, watch } from 'vue'

import Spinner from '@ankor-io/blocks/components/Spinner.vue'
import SimpleAlertModal from '@ankor-io/blocks/components/modals/SimpleAlertModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import { showAwaitToast } from '@ankor-io/blocks/components/toast'
import { MapperTypes } from '@ankor-io/calendars-endpoint/src/mapper'
import { useModal } from '@ankor-io/commodore/src/hooks/modal/useModal'
import { useCompany } from '@ankor-io/commodore/src/hooks/useCompany'
import { BookingManagerVesselPairing } from '@ankor-io/common/booking-manager/types'
import { VesselIndexItem } from '@ankor-io/common/index/VesselIndexItem'
import { formatLength } from '@ankor-io/common/vessel/Formatter'
import { Company } from '@ankor-io/iam/types'
import {
  OutlineCheck,
  OutlineClipboardCopy,
  OutlineExclamationCircle,
  OutlineInformationCircle,
  OutlineKey,
  OutlineTrash,
  OutlineXMark,
} from '@ankor-io/icons/outline'
import { SolidCheck, SolidX } from '@ankor-io/icons/solid'

import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { AuthenticationContext } from '@/iam/types'
import { COMMODORE_20250218_MMK_INTEGRATION } from '@/utils/growthbook/constants'

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const searchClient: SearchClient = inject('searchClient')!
const commodoreIndex: SearchIndex = searchClient.initIndex('commodore')
const growthbook: GrowthBook = inject('growthbook')!

const { isOpen, updateModalState } = useModal()

const { company, getCompany } = useCompany()

// Modal helpers
const deleteModalHelper = modalHelper()

onMounted(async () => {
  initPopovers()
  const token: string = (await authenticationContext.getToken())!

  await Promise.all([
    getCompany(token).then(async (company: Company | null) => {
      const mmkEnabled = growthbook.isOn(COMMODORE_20250218_MMK_INTEGRATION)

      if (company && mmkEnabled) {
        await loadBookingManagerIntegration()
      }
    }),
    loadKeys(),
  ])
})

type Tab = 'KEY_GENERATION' | 'INTEGRATION'

const activeTab: Ref<Tab> = ref('KEY_GENERATION')

const navigation: { name: string; tabId: Tab }[] = [
  { name: 'Key generation', tabId: 'KEY_GENERATION' },
  { name: 'Integrations', tabId: 'INTEGRATION' },
]

// Company Details

const copiedCompanyURI: Ref<boolean> = ref(false)

const copyCompanyURI = (key: string) => {
  navigator.clipboard.writeText(key)
  copiedCompanyURI.value = true
}

// Key Generation

type Key = {
  kid: string
  pemSPKI: string
  pemPKCS8: string
}

const keys: Ref<{ kid: string; createdAt: string }[]> = ref([])
const isLoadingKeys: Ref<boolean> = ref(true)
const isAddingKey: Ref<boolean> = ref(false)
const copiedPublicKey: Ref<boolean> = ref(false)
const copiedPrivateKey: Ref<boolean> = ref(false)
const newKey: Ref<Key | null> = ref(null)
const isKeyPairModalVisible: Ref<boolean> = ref(false)

const loadKeys = async () => {
  isLoadingKeys.value = true
  try {
    const resp = await fetch('/api/key', {
      method: 'GET',
      headers: {
        authorization: `Bearer ${await authenticationContext.getToken()}`,
      },
    })

    if (resp.status === 200) {
      const response = (await resp.json()) as { kid: string; createdAt: string }[]
      keys.value = []
      response.forEach((key) => {
        keys.value.unshift(key)
      })
    }
  } catch (e) {
    console.error(e)
  }
  isLoadingKeys.value = false
}

const addKey = async () => {
  isAddingKey.value = true
  try {
    const resp = await fetch('/api/key', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${await authenticationContext.getToken()}`,
      },
    })
    if (resp.status === 200) {
      const response = (await resp.json()) as { createdAt: string; kid: string; pemSPKI: string; pemPKCS8: string }
      keys.value.unshift({ kid: response.kid, createdAt: response.createdAt })
      newKey.value = response
      openKeyPairModal()
    }
  } catch (e) {
    console.error(e)
  }
  isAddingKey.value = false
}

const deleteKey = async (kid: string) => {
  return showAwaitToast(
    fetch(`/api/key/${kid}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${await authenticationContext.getToken()}`,
      },
    }),
    `Deleting key...`,
    `Deleted key successfully.`,
    `Failed to delete key.`,
  ).then(() => {
    keys.value = keys.value.filter((key) => key.kid !== kid)
  })
}

const openKeyPairModal = () => {
  isKeyPairModalVisible.value = true
}

const resetCopiedStates = () => {
  copiedCompanyURI.value = false
  copiedPublicKey.value = false
  copiedPrivateKey.value = false
}

const closeKeyPairModal = () => {
  resetCopiedStates()
  isKeyPairModalVisible.value = false
}

const copyPublicKey = (key: string) => {
  navigator.clipboard.writeText(key)
  resetCopiedStates()
  copiedPublicKey.value = true
}

const copyPrivateKey = (key: string) => {
  navigator.clipboard.writeText(key)
  resetCopiedStates()
  copiedPrivateKey.value = true
}

// Integrations

const websiteSharingMode: Ref<'NONE' | 'ALL' | undefined> = ref('NONE')
const isUpdatingVisibility: Ref<boolean> = ref(false)
const isModalOpen: Ref<boolean> = ref(false)
const isBookingManagerModalOpen: Ref<boolean> = ref(false)
const showApiDisabledToast: Ref<boolean> = ref(false)
const hasSavedBookingManagerAPIKey: Ref<boolean> = ref(false)
const bookingManagerAPIKey: Ref<string | undefined> = ref()
const bookingManagerAPIToggle: Ref<boolean> = ref(false)
const bookingManagerAPIIntegrationLoading: Ref<boolean> = ref(false)
const vessels: Ref<{ name: string; uri: string; paired: boolean }[] | null> = ref(null)
const yachtPairings: Ref<BookingManagerVesselPairing[] | null> = ref(null)
const loadingVessels: Ref<boolean> = ref(false)

const updateWebsiteSharing = async () => {
  if (websiteSharingMode.value === 'ALL' && !isModalOpen.value) {
    isModalOpen.value = true
    updateModalState(true)
    return
  }

  isUpdatingVisibility.value = true
  const response = await fetch('/api/website/sharing/toggle', {
    method: 'PUT',
    headers: {
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (!response.ok) {
    console.error('Failed to update website sharing mode')
  } else {
    const { websiteSharingMode: sharingMode }: { websiteSharingMode: string } = await response.json()
    websiteSharingMode.value = sharingMode as 'NONE' | 'ALL' | undefined

    if (sharingMode === 'NONE') {
      showApiDisabledToast.value = true
      setTimeout(() => {
        showApiDisabledToast.value = false
      }, 15 * 1000) // 15 seconds
    }
  }
  isUpdatingVisibility.value = false
}

const disableToggle = async (): Promise<void> => {
  await updateWebsiteSharing()
  isModalOpen.value = false
  updateModalState(false)
}

watch(company, (value) => {
  if (websiteSharingMode.value !== value?.websiteSharingMode) {
    websiteSharingMode.value = value?.websiteSharingMode
  }
})

const loadBookingManagerIntegration = async () => {
  bookingManagerAPIIntegrationLoading.value = true
  try {
    const resp: Response = await fetch(
      `/settings-endpoint/c::${company.value?.id}::settings::integrations::${MapperTypes.BOOKING_MANAGER}`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${await authenticationContext.getToken()}`,
          accept: 'application/json',
          contentType: 'application/json',
        },
      },
    )

    if (resp.status === 200) {
      const response = (await resp.json()) as { document: Record<string, string>; uri: string }[]
      if (response.length > 0) {
        bookingManagerAPIKey.value = response[0].document['api-key']
        hasSavedBookingManagerAPIKey.value = bookingManagerAPIKey.value !== undefined
        bookingManagerAPIToggle.value = response[0].document['enabled'] === 'true'
      }
    }
  } catch (e) {
    console.error(e)
  }
  bookingManagerAPIIntegrationLoading.value = false
}

const updateBookingManagerIntegration = async (property: 'api-key' | 'enabled', value: string) => {
  bookingManagerAPIIntegrationLoading.value = true

  try {
    return showAwaitToast(
      fetch(
        `/settings-endpoint/c::${company.value?.id}::settings::integrations::${MapperTypes.BOOKING_MANAGER}::${property}`,
        {
          method: 'POST',
          headers: {
            authorization: `Bearer ${await authenticationContext.getToken()}`,
            accept: 'text/plain',
            contentType: 'text/plain',
          },
          body: value,
        },
      ).then(async () => {
        hasSavedBookingManagerAPIKey.value = true
        if (property === 'enabled' && value === 'true' && bookingManagerAPIKey.value) {
          // Call to initialize the pull of vessels
          await fetch(`/api/booking-manager/init?apiKey=${bookingManagerAPIKey.value}`, {
            method: 'PUT',
            headers: {
              authorization: `Bearer ${await authenticationContext.getToken()}`,
            },
          })
        } else if (property === 'enabled' && value === 'false') {
          // Call to remove from scheduled tasks
          await fetch('/api/booking-manager/remove', {
            method: 'PUT',
            headers: {
              authorization: `Bearer ${await authenticationContext.getToken()}`,
            },
          })
        }
        bookingManagerAPIIntegrationLoading.value = false
      }),
      `Updating settings...`,
      `Updated settings successfully.`,
      `Failed to update settings.`,
    )
  } catch (e) {
    console.error(e)
  }
}

// modals
watch(isOpen, (value) => {
  if (!value) {
    isModalOpen.value = false
    isBookingManagerModalOpen.value = false
  }
})

watch(isBookingManagerModalOpen, async (opened) => {
  // load vessels when the modal is opened and only once
  if (opened && vessels.value === null) {
    loadingVessels.value = true
    vessels.value = await getListOfVessels()
    loadingVessels.value = false
  }
})

const getListOfVesselsFromBookingManagerPull = async (): Promise<BookingManagerVesselPairing[]> => {
  const resp = await fetch('/api/booking-manager/vessel/list', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await authenticationContext.getToken()}`,
    },
  })

  if (resp.ok) {
    const pairings: BookingManagerVesselPairing[] = await resp.json()
    return pairings
  }
  return []
}

/**
 * fetch list of vessels from the index
 * @returns list of vessels
 */
const getListOfVessels = async () => {
  const pairings = await getListOfVesselsFromBookingManagerPull()

  const vessels = await commodoreIndex
    .search('', { hitsPerPage: 300 })
    .then(({ hits }) =>
      (hits as VesselIndexItem[]).map((hit) => {
        const pairedVessel = pairings.find((pairing) => pairing.vesselUri === hit.uri)
        return {
          name: `${hit.line_1} (${formatLength(hit.line_2!)})`,
          uri: hit.uri,
          paired: pairedVessel?.paired || false,
          bookingManagerYachtName: pairedVessel?.name,
        }
      }),
    )
    .catch((err) => {
      console.error(err)
      return []
    })
  return vessels?.length > 0 ? vessels : []
}
</script>

<template>
  <div class="commodore">
    <header class="border-b border-black/5 dark:border-white/5">
      <!-- Secondary navigation -->
      <nav class="flex overflow-x-auto py-4">
        <ul
          role="list"
          class="flex min-w-full flex-none gap-x-6 px-4 text-sm/6 font-semibold text-gray-400 sm:px-6 lg:px-8"
        >
          <li v-for="item in navigation" :key="item.tabId">
            <button @click.stop="activeTab = item.tabId" :class="item.tabId === activeTab ? 'text-blue-600' : ''">
              {{ item.name }}
            </button>
          </li>
        </ul>
      </nav>
    </header>

    <div v-show="activeTab === 'KEY_GENERATION'" class="divide-y divide-black/5 dark:divide-white/5">
      <!-- Company Name Row -->
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-5 px-4 py-8 sm:px-6 lg:px-8">
        <h2 class="text-gray-900 dark:text-gray-50">Company Details</h2>
        <div class="md:col-span-2 text-sm/6 text-gray-500 dark:text-gray-400">
          <div class="relative overflow-x-auto">
            <div class="rounded-lg overflow-hidden min-w-fit border border-gray-200 dark:border-gray-700">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead
                  class="text-xs uppercase border-b bg-gray-50 dark:bg-gray-900 dark:text-gray-400 border-gray-200 dark:border-gray-700"
                >
                  <tr>
                    <th scope="col" class="px-6 py-3">Company Name</th>
                    <th scope="col" class="px-6 py-3">Company URI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-gray-500 dark:text-gray-400 bg-white [&:not(:last-child)]:border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap">
                      <p>{{ company?.name }}</p>
                    </th>
                    <td class="px-6 py-4">
                      <p class="flex items-center gap-x-1">
                        c::{{ company?.id }}
                        <button name="copy-company-uri" type="button" @click.stop="copyCompanyURI(`c::${company!.id}`)">
                          <OutlineCheck
                            v-if="copiedCompanyURI"
                            class="w-4 h-4 stroke-green-600 dark:stroke-green-400"
                          />
                          <OutlineClipboardCopy
                            v-else
                            class="w-4 h-4 hover:stroke-primary-600 active:stroke-primary-600 focus:stroke-primary-600"
                          />
                        </button>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Key Generation Row -->
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-10 px-4 py-8 sm:px-6 lg:px-8">
        <h2 class="text-gray-900 dark:text-gray-50">Key Generation</h2>
        <div class="md:col-span-2">
          <div class="flex flex-col p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md gap-4">
            <div class="flex flex-wrap justify-between gap-2">
              <div class="flex items-center gap-2">
                <p class="flex items-center gap-x-1 text-gray-500 dark:text-gray-400">
                  Generate public and Private keys here
                  <button
                    class="text-primary-600 dark:text-primary-400"
                    type="button"
                    data-popover-placement="bottom"
                    data-popover-target="popover-generate-keys"
                  >
                    <OutlineInformationCircle class="h-4 w-4" />
                  </button>
                </p>
                <div
                  data-popover
                  role="tooltip"
                  class="absolute z-50 invisible inline-block text-xs normal-case font-normal whitespace-normal text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0"
                  id="popover-generate-keys"
                >
                  <div class="max-w-[25rem] p-2">You can generate a maximum of 3 keys.</div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <button
                name="button"
                type="button"
                class="flex items-center gap-x-1 outline-none rounded-lg md:w-32 h-10 w-28 px-3 md:px-5 whitespace-nowrap text-white bg-primary-600 hover:bg-primary-700"
                :class="{
                  'cursor-not-allowed disabled:bg-gray-300 dark:disabled:bg-gray-600':
                    isLoadingKeys || isAddingKey || keys.length >= 3,
                }"
                @click="addKey"
                :disabled="isLoadingKeys || isAddingKey || keys.length >= 3"
              >
                <Spinner v-if="isAddingKey" class="w-4 h-4" />
                <OutlineKey v-else class="w-5 h-5" />
                <span class="text-xs md:text-sm font-medium w-full">Generate</span>
              </button>
            </div>

            <div class="relative overflow-x-auto">
              <div class="rounded-lg overflow-hidden min-w-fit border border-gray-200 dark:border-gray-700">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead
                    class="text-xs uppercase border-b bg-gray-50 dark:bg-gray-900 dark:text-gray-400 border-gray-200 dark:border-gray-700"
                  >
                    <tr>
                      <th scope="col" class="px-6 py-3">Generated Api Key</th>
                      <th scope="col" class="px-6 py-3">Date</th>
                      <th scope="col" class="px-6 py-3 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="isLoadingKeys"
                      class="text-gray-500 dark:text-gray-400 bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap">
                        <div>
                          <Spinner class="w-7 h-7" />
                        </div>
                      </th>
                    </tr>

                    <tr
                      v-else-if="keys.length === 0"
                      class="text-gray-500 dark:text-gray-400 bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap">
                        <div>
                          <p>No Keys Generated Yet</p>
                        </div>
                      </th>
                    </tr>

                    <template v-else>
                      <tr
                        v-for="key in keys"
                        :key="key.kid"
                        class="text-gray-500 dark:text-gray-400 bg-white [&:not(:last-child)]:border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap">
                          <div>
                            <p class="text-base text-gray-900 dark:text-white">Key ID</p>
                            <p>{{ key.kid }}</p>
                          </div>
                        </th>
                        <td class="px-6 py-4">
                          {{ DateTime.fromISO(key.createdAt).toFormat('yyyy-MM-dd hh:mm:ss a') }}
                        </td>
                        <td class="px-6 py-4 text-center">
                          <button
                            type="button"
                            class="text-red-500 bg-transparent hover:bg-gray-200 hover:text-red-700 p-1 rounded-lg text-sm ms-auto inline-flex justify-center items-center hover:dark:text-red-400 dark:hover:bg-gray-600 dark:hover:text-white"
                            @click="
                              deleteModalHelper.show({
                                title: 'Delete this Key?',
                                kid: key.kid,
                              })
                            "
                          >
                            <OutlineTrash class="w-4 h-4" />
                            <span class="sr-only">Delete Key</span>
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="activeTab === 'INTEGRATION'" class="divide-y divide-black/5 dark:divide-white/5">
      <!-- API Integration -->
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-5 px-4 py-8 sm:px-6 lg:px-8">
        <div>
          <h2 class="text-gray-900 dark:text-gray-50">API Integration</h2>
          <p class="text-sm text-gray-700 dark:text-gray-200">
            Toggle to approve all yacht data in your company for integration with the RA API, ensuring current and
            future Radar users have access.
            <a
              class="block text-primary-600 dark:text-primary-500"
              href="https://www.ankor.io/terms-conditions"
              target="_blank"
            >
              Terms and Conditions
            </a>
          </p>
        </div>

        <div class="md:col-span-2">
          <div class="flex items-center gap-2">
            <label
              class="inline-flex items-center cursor-pointer"
              data-test="api-website-toggle"
              @click.prevent="updateWebsiteSharing"
            >
              <input type="checkbox" class="sr-only peer" :checked="websiteSharingMode === 'ALL'" />
              <div
                class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
              ></div>
            </label>
            <Spinner v-if="isUpdatingVisibility && !isModalOpen" class="size-7" />
          </div>
        </div>
      </div>

      <!-- Booking Manager Integration -->
      <div
        v-if="$growthbook.isOn(COMMODORE_20250218_MMK_INTEGRATION)"
        class="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-10 px-4 py-8 sm:px-6 lg:px-8"
      >
        <h2 class="text-gray-900 dark:text-gray-50">Booking Manager Integration</h2>
        <div class="md:col-span-2 flex flex-col gap-y-4">
          <div class="flex gap-x-4">
            <div>
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="booking-manager-api-key">
                API Key
              </label>
              <div class="flex gap-x-2">
                <input
                  class="w-64 border text-sm rounded-lg block py-2 px-4 text-gray-900 dark:text-white focus:text-gray-900 dark:focus:text-white focus:ring-blue-500 dark:focus:ring-blue-500 focus:border-blue-500 dark:focus:border-blue-500 valid:border-gray-300 valid:dark:border-gray-600 dark:placeholder-gray-400 bg-gray-50 dark:bg-gray-700"
                  id="booking-manager-api-key"
                  type="text"
                  placeholder="Paste your MMK API key here...."
                  data-test="api-booking-manager-api-field"
                  :class="{
                    'cursor-not-allowed': hasSavedBookingManagerAPIKey,
                  }"
                  :value="bookingManagerAPIKey"
                  :disabled="hasSavedBookingManagerAPIKey"
                  @input="bookingManagerAPIKey = ($event.target as HTMLInputElement).value"
                />
                <button
                  v-show="hasSavedBookingManagerAPIKey"
                  type="button"
                  data-popover-placement="bottom"
                  data-popover-target="popover-booking-manager-info"
                >
                  <OutlineInformationCircle class="size-4" />
                </button>
                <div
                  data-popover
                  role="tooltip"
                  class="absolute z-50 invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600 text-gray-500 dark:text-gray-400"
                  id="popover-booking-manager-info"
                >
                  <div class="max-w-[25rem] p-2">
                    This API Key has been locked in. To remove or update your MMK API key, please contact
                    support@ankor.io
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
            </div>
            <button
              v-if="!hasSavedBookingManagerAPIKey"
              class="self-end transition-all flex items-center gap-x-2 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-primary-300 text-white hover:text-white"
              :class="
                bookingManagerAPIIntegrationLoading
                  ? 'cursor-not-allowed bg-gray-200 dark:bg-gray-400'
                  : 'cursor-pointer bg-primary-600 dark:bg-primary-500 hover:bg-primary-500 dark:hover:bg-primary-800'
              "
              :disabled="bookingManagerAPIIntegrationLoading"
              :key="bookingManagerAPIKey"
              @click="bookingManagerAPIKey && updateBookingManagerIntegration('api-key', bookingManagerAPIKey)"
              data-test="save-booking-manager-button"
            >
              Save
              <Spinner v-if="bookingManagerAPIIntegrationLoading" class="w-4 h-4" />
            </button>
          </div>
          <div>
            <div class="flex gap-x-1">
              <p>Enable integration</p>
              <button
                type="button"
                data-popover-placement="bottom"
                data-popover-target="popover-booking-manager-api-keys"
              >
                <OutlineInformationCircle class="h-4 w-4" />
              </button>
            </div>
            <div
              data-popover
              role="tooltip"
              class="absolute z-50 invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600 text-gray-500 dark:text-gray-400"
              id="popover-booking-manager-api-keys"
            >
              <div class="max-w-[25rem] p-2">You can enable integration once you have a valid api key</div>
              <div data-popper-arrow></div>
            </div>
            <div class="mt-2 flex items-center gap-2">
              <label
                class="inline-flex items-center"
                :class="
                  bookingManagerAPIKey && !bookingManagerAPIIntegrationLoading ? 'cursor-pointer' : 'cursor-not-allowed'
                "
                data-test="api-booking-manager-toggle"
                @click.prevent="
                  () => {
                    bookingManagerAPIKey && !bookingManagerAPIIntegrationLoading
                      ? (bookingManagerAPIToggle = !bookingManagerAPIToggle)
                      : null
                    bookingManagerAPIKey && !bookingManagerAPIIntegrationLoading
                      ? updateBookingManagerIntegration('enabled', bookingManagerAPIToggle.toString())
                      : null
                  }
                "
              >
                <input type="checkbox" class="sr-only peer" :checked="bookingManagerAPIToggle" />
                <div
                  class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
              </label>
              <Spinner v-if="bookingManagerAPIIntegrationLoading" class="w-7 h-7" />
            </div>
          </div>
          <button
            v-if="bookingManagerAPIToggle"
            @click=";[updateModalState(true), (isBookingManagerModalOpen = true)]"
            class="self-start underline"
          >
            View your current Calendars to Booking pairings
          </button>
        </div>
      </div>
    </div>

    <!-- key pair modal -->
    <ModalContentWrapper v-if="isKeyPairModalVisible && newKey">
      <div
        class="w-full md:max-w-6xl max-h-screen relative bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded-lg shadow-md text-sm text-gray-900 dark:text-white m-2 sm:m-5 md:m-10 overflow-y-auto"
      >
        <div class="grid grid-row-3 divide-y dark:divide-gray-600">
          <div class="p-6 flex gap-x-2 justify-between overflow-y-auto">
            <p class="text-lg text-gray-500 dark:text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis">
              <span class="font-semibold text-lg text-gray-900 dark:text-white">Key ID</span> {{ newKey.kid }}
            </p>
            <div class="p-2">
              <OutlineXMark
                class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400"
                @click.stop="closeKeyPairModal"
              />
            </div>
          </div>
          <div class="p-4 flex gap-y-4 flex-col overflow-y-auto lg:grid lg:grid-cols-2">
            <div class="flex flex-col w-full">
              <p class="font-bold text-sm text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-700 px-6 py-3">
                Public key
              </p>
              <p
                class="text-xs break-words md:whitespace-pre font-light text-gray-500 dark:text-gray-400 px-6 my-3 lg:h-64 max-h-64 overflow-auto"
              >
                {{ newKey.pemSPKI }}
              </p>
              <button
                name="button"
                type="button"
                class="self-end flex items-center gap-x-2 outline-none rounded-lg px-2 h-8 focus:ring-2 whitespace-nowrap border transition-all text-white bg-primary-600 hover:bg-primary-800 active:bg-primary-600 focus:bg-primary-600 focus:ring-primary-300 dark:focus:ring-blue-800 border-primary-600 hover:border-primary-800"
                @click.stop="copyPublicKey(newKey.pemSPKI)"
              >
                <OutlineCheck v-if="copiedPublicKey" class="w-5 h-5 stroke-primary-700 dark:stroke-primary-300" />
                <OutlineClipboardCopy v-else class="w-5 h-5 stroke-white" />
                <span class="text-xs font-medium w-full">Copy</span>
              </button>
            </div>
            <div class="flex flex-col w-full">
              <p class="font-bold text-sm text-gray-500 dark:text-gray-400 bg-gray-50 dark:bg-gray-700 px-6 py-3">
                Private key
              </p>
              <p
                class="text-xs break-words md:whitespace-pre font-light text-gray-500 dark:text-gray-400 px-6 my-3 lg:h-64 max-h-64 overflow-auto"
              >
                {{ newKey.pemPKCS8 }}
              </p>
              <button
                name="button"
                type="button"
                class="self-end flex items-center gap-x-2 outline-none rounded-lg px-2 h-8 focus:ring-2 whitespace-nowrap border transition-all text-white bg-primary-600 hover:bg-primary-800 active:bg-primary-600 focus:bg-primary-600 focus:ring-primary-300 dark:focus:ring-blue-800 border-primary-600 hover:border-primary-800"
                @click.stop="copyPrivateKey(newKey.pemPKCS8)"
              >
                <OutlineCheck v-if="copiedPrivateKey" class="w-5 h-5 stroke-primary-700 dark:stroke-primary-300" />
                <OutlineClipboardCopy v-else class="w-5 h-5 stroke-white" />
                <span class="text-xs font-medium w-full">Copy</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalContentWrapper>

    <!-- Delete modal -->
    <Teleport defer to="body">
      <!-- delete modal -->
      <SimpleAlertModal
        v-if="deleteModalHelper.isVisible()"
        :model="deleteModalHelper.model"
        @confirm="(model: any) => [deleteModalHelper.hide(), deleteKey(model.kid)]"
        @dismiss="deleteModalHelper.hide()"
      >
        <template #title="{ modelValue }">
          <h3 class="text-center">{{ modelValue.title }}</h3>
        </template>
        <template #default="{ modelValue }">
          <div class="space-y-2.5 text-center text-sm text-gray-500 dark:text-gray-400">
            <p>You are about to delete this key</p>
            <p class="font-bold">{{ modelValue.kid }}</p>
            <p>Are you sure you want to proceed?</p>
          </div>
        </template>
      </SimpleAlertModal>
    </Teleport>

    <!-- api integration disable confirmation modal -->
    <ModalContentWrapper v-if="isModalOpen">
      <div
        class="w-full flex flex-col items-center justify-center"
        :class="{ 'pointer-events-none': isUpdatingVisibility }"
      >
        <OutlineExclamationCircle class="w-8 h-8 stroke-gray-400" />
        <p class="my-5 mb-10 max-w-sm text-center text-gray-500">
          Are you sure you want to disable API Integration? Anyone referencing your yacht on their website will be
          effected.
        </p>
        <div class="w-full flex justify-center gap-x-4">
          <button
            type="button"
            name="cancel"
            class="text-gray-800 border border-gray-200 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-50 font-medium rounded-lg text-sm leading-9 px-3 dark:hover:bg-gray-300 dark:bg-gray-50 dark:border-gray-300"
            @click="isModalOpen = false"
          >
            No, cancel
          </button>
          <button
            type="button"
            name="confirm"
            class="text-white border border-red-500 focus:outline-none bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm leading-9 px-3"
            @click="disableToggle()"
          >
            <Spinner v-if="isUpdatingVisibility" class="w-4 h-4" />
            <p v-else>Yes, disable it</p>
          </button>
        </div>
      </div>
    </ModalContentWrapper>

    <!-- toast for api disabled -->
    <div
      v-if="showApiDisabledToast"
      class="z-50 absolute top-20 right-5 flex w-full max-w-xs md:max-w-md p-4 rounded-lg shadow-sm text-gray-500 bg-white divide-gray-200 dark:text-gray-400 dark:divide-gray-700 dark:bg-gray-800"
    >
      <div class="flex justify-between w-full">
        <div class="flex items-center gap-x-4">
          <SolidCheck class="w-8 h-8 shrink-0 rounded-lg text-green-500 bg-green-100 p-1.5" />

          <div class="text-sm">
            <p>Successfully disabled API Integration</p>
          </div>
        </div>
        <SolidX
          class="w-4 text-gray-500 dark:text-gray-400 cursor-pointer shrink-0"
          @click.stop="showApiDisabledToast = false"
        />
      </div>
    </div>

    <!-- Booking Manager integration modal -->
    <ModalContentWrapper v-if="isBookingManagerModalOpen">
      <div
        class="w-full flex flex-col items-center justify-center divide-y divide-black/5 dark:divide-white/5 commodore"
        :class="{ 'pointer-events-none': isUpdatingVisibility }"
      >
        <!-- -mt-2 is to align the heading with the close "X" -->
        <h5 class="pb-3 -mt-2 text-left text-gray-900 dark:text-gray-50 md:whitespace-nowrap">
          Calendars to Booking Manager yacht pairings
        </h5>
        <div class="hidden md:flex flex-col gap-y-2 w-full pt-3">
          <div v-if="loadingVessels" class="h-80 w-full flex justify-center items-center">
            <Spinner class="w-8 h-8" />
          </div>
          <template v-else>
            <div class="grid grid-cols-2">
              <p class="text-gray-900 dark:text-gray-50">Existing <b>Calendars</b> Yachts</p>
              <p class="text-gray-900 dark:text-gray-50"><b>Booking Manager</b> Yachts</p>
            </div>
            <div class="flex flex-col h-72 overflow-y-auto gap-y-2">
              <div
                v-for="(vessel, index) in vessels"
                class="grid grid-cols-2 p-3 border border-gray-200 dark:border-gray-700"
              >
                <p class="text-gray-900 dark:text-gray-50">{{ vessel.name }}</p>
                <div v-if="!vessel.paired" class="flex justify-between">
                  <p class="text-gray-900 dark:text-gray-50">-</p>
                  <a :href="`/view-vessel/${vessel.uri}?pairing=true`" class="underline">Pair this</a>
                </div>
                <div v-else class="flex justify-between">
                  <p class="text-gray-900 dark:text-gray-50">{{ vessel.bookingManagerYachtName }}</p>
                  <a :href="`/view-vessel/${vessel.uri}?pairing=false`" class="underline">Unpair</a>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="flex md:hidden flex-col w-full gap-y-2 pt-3">
          <div v-if="loadingVessels" class="h-80 flex justify-center items-center">
            <Spinner class="w-8 h-8" />
          </div>
          <template v-else>
            <div class="flex flex-col h-72 overflow-y-auto gap-y-2">
              <div
                v-for="(vessel, index) in vessels"
                class="flex justify-between p-3 border border-gray-200 dark:border-gray-700 gap-x-4"
              >
                <div class="flex flex-col">
                  <p class="text-xs text-gray-900 dark:text-gray-50">Existing <b>Calendars</b> Yachts</p>
                  <p class="text-gray-900 dark:text-gray-50">{{ vessel.name }}</p>
                  <hr class="my-4" />
                  <p class="text-xs text-gray-900 dark:text-gray-50"><b>Booking Manager</b> Yachts</p>
                  <p class="text-gray-900 dark:text-gray-50">{{ vessel.bookingManagerYachtName }}</p>
                </div>
                <a href="#" class="underline text-right self-center whitespace-nowrap">Pair</a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </ModalContentWrapper>
  </div>
</template>
