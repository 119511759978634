<script lang="ts" setup>
import { ComputedRef, Ref, computed, ref } from 'vue'
import draggable from 'vuedraggable'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { SolidDrag } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'

import SpecificationItemAdd from './SpecificationItemAdd.vue'
import SpecificationItemEditor from './SpecificationItemEditor.vue'
import { KeyValueSpecification, VesselSpecificationsSectionData } from './types/types'

type Props = {
  id: string
  proposalUri: string
  data: VesselSpecificationsSectionData
  layout: LayoutTemplate
  isHydrating: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<VesselSpecificationsSectionData>): void
}>()

const isDragging: Ref<boolean> = ref(false)
const hoveredIndex: Ref<number> = ref(-1)
const addNewSpecItem = (specification: KeyValueSpecification) => {
  if (!specification.key || !specification.value) {
    return
  }

  const validSpecifications = props.data.specifications.filter(
    (specification) => specification.key && specification.value && specification.value.length > 0,
  )
  const data: VesselSpecificationsSectionData = ObjectUtil.deepCopy({
    ...props.data,
    specifications: validSpecifications,
  })

  data.specifications.push(specification)
  emit('update:value', { sectionId: props.id, data })
}

const updateSpecItem = (
  index: number,
  event: { key: keyof KeyValueSpecification; value: string; arrIndex?: number },
): void => {
  const validSpecifications = props.data.specifications.filter(
    (specification) => specification.key && specification.value && specification.value.length > 0,
  )
  const data: VesselSpecificationsSectionData = ObjectUtil.deepCopy({
    ...props.data,
    specifications: validSpecifications,
  })

  const specification = data.specifications[index]
  // For values that are string[]
  if (event.key === 'value' && typeof specification.value === 'object' && event.arrIndex !== undefined) {
    // Text input does not exist as part of the current data
    if (event.arrIndex === specification.value.length && !event.value) {
      return
    }

    // Remove the value if the value is empty
    if (!event.value) {
      specification.value.splice(event.arrIndex, 1)
    } else {
      if (specification.value[event.arrIndex] === event.value) {
        return
      }

      specification.value[event.arrIndex] = event.value
    }
  } else {
    if (specification[event.key] === event.value) {
      return
    }

    specification[event.key] = event.value
  }

  emit('update:value', { sectionId: props.id, data })
}

const deleteSpecItem = (index: number): void => {
  const data: VesselSpecificationsSectionData = ObjectUtil.deepCopy({
    ...props.data,
    specifications: props.data.specifications.filter(
      (specification) => specification.key && specification.value && specification.value.length > 0,
    ),
  })
  data.specifications.splice(index, 1)
  emit('update:value', { sectionId: props.id, data })
}

const updateOrder = (e: any): void => {
  if (e.moved) {
    emit('update:value', { sectionId: props.id, data: { specifications: validSpecifications.value! } })
  }
}

const validSpecifications: ComputedRef<KeyValueSpecification[] | undefined> = computed(() =>
  props.data.specifications?.filter(
    (specification) => specification.key && specification.value && specification.value.length > 0,
  ),
)
</script>
<template>
  <div v-if="props.isHydrating" class="h-44 flex items-center justify-center">
    <Spinner />
  </div>
  <div v-else id="vessel-specifications-section">
    <h1 class="font-bold text-3xl text-center mb-4">
      <SingleLineTextEditor value="Specifications" :is-editable="false" />
    </h1>
    <draggable
      item-key="id"
      class="grid gap-4 pt-2 mt-4 grid-cols-2 @md:grid-cols-5 text-left transition-all duration-500"
      :list="validSpecifications"
      @start="isDragging = true"
      @end="isDragging = false"
      @change="updateOrder"
    >
      <template #item="{ element: specification, index: specIndex }: { element: any, index: number }">
        <div
          class="relative rounded-lg"
          :key="`${specIndex}${specification.key}`"
          @mouseenter="hoveredIndex = specIndex"
          @mouseleave="hoveredIndex = -1"
        >
          <SolidDrag
            v-if="hoveredIndex === specIndex && !isDragging"
            class="absolute h-8 -left-6 top-[calc(50%-1rem)] fill-gray-500 cursor-grab"
          />

          <SpecificationItemEditor
            :specification="specification"
            @delete:specification="deleteSpecItem(specIndex)"
            @update:value="updateSpecItem(specIndex, $event)"
          />
        </div>
      </template>
    </draggable>
    <SpecificationItemAdd
      class="mt-4 w-1/2 @md:w-1/5"
      :specification="{ key: '', value: '' }"
      @add:specification="addNewSpecItem"
    />
  </div>
</template>
