<script setup lang="ts">
import { MenuItem } from '@headlessui/vue'
import { FeatureCollection } from '@turf/turf'
import { watchDebounced } from '@vueuse/core'
import { SearchClient, SearchIndex } from 'algoliasearch/lite'
import ls from 'localstorage-slim'
import { LngLat, LngLatBounds, Projection } from 'mapbox-gl'
import { ComputedRef, Ref, computed, inject, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { RouteLocationNormalizedLoaded, Router, useRoute, useRouter } from 'vue-router'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import ImageCarouselModalEditor from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModalEditor.vue'
import { showErrorToast, showInfoToast } from '@ankor-io/blocks/components/toast'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import OneColumnWithHeader from '@ankor-io/blocks/layouts/OneColumnWithHeader.vue'
import { ItineraryIndexItem } from '@ankor-io/common/index/ItineraryIndexItem'
import { Coordinates, Itinerary, Path, Place, Segment, Stop } from '@ankor-io/common/itinerary/Itinerary'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { UUID } from '@ankor-io/common/lang/uuid'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { Identity, Template, ThemeTemplate } from '@ankor-io/common/proposal/Proposal'
import { URI, URIBuilder, URIContext } from '@ankor-io/common/uri/Uri'
import { HiOutlineSwatch } from '@ankor-io/icons/hi_outline'
import { OutlineChevronDown, OutlineDocumentText, OutlinePerson } from '@ankor-io/icons/outline'
import { SolidDocumentAdd, SolidHome, SolidPencil } from '@ankor-io/icons/solid'

import Loader from '@/components/Loader.vue'
import Loading from '@/components/Loader.vue'
import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import ItineraryAssets from '@/components/itinerary/builder/ItineraryAssets.vue'
import ItineraryMapContainer from '@/components/itinerary/builder/ItineraryMapContainer.vue'
import ItinerarySegments from '@/components/itinerary/builder/ItinerarySegments.vue'
import GlobalIdentityModal from '@/components/modal-content/GlobalIdentityModal.vue'
import GlobalStyleModal from '@/components/modal-content/GlobalStyleModal.vue'
import { AuthenticationContext } from '@/iam/types'
import { linkMedia } from '@/services/MediaService'
import { StateService, useStateServiceProvider } from '@/services/StateService'
import { getDefaultProposalTemplate, getUserTemplate } from '@/services/proposal/ProposalService'
import { useStowageService } from '@/services/stowage/StowageService'
import { distanceBetween, nauticalMilesToMeters } from '@/utils/distance'
import {
  CHANGE_ROUTE_NAME,
  CONFIRM_PROFILE_COMPANY_SETTINGS,
  CONFIRM_STYLE_BRANDING_SETTINGS,
  EXPAND_CONTENT_PANEL,
  NAVIGATE_TO_HOME,
  NEW_PRESENTATION,
  OPEN_PROFILE_COMPANY_SETTINGS,
  OPEN_STYLE_BRANDING_SETTINGS,
} from '@/utils/mixPanel/constants'
import { removeHtmlTagsFromString } from '@/utils/stringManipulations'

type Feature = {
  type: string
  properties: {
    uri: string
    isCustom: boolean
    line_1?: string
    line_2?: string
    line_3?: string
    line_4?: string
    image?: string
  }
  geometry: {
    type: string
    coordinates: [string, string]
  }
}

const stateService: StateService = await useStateServiceProvider(inject).then((_stateServiceProvider) => {
  return _stateServiceProvider.get()
})
const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const userTemplateUri: string = URIBuilder.from(URIContext.U, authenticationContext.getUser().id, 'template', 'default')
const accessToken: string = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
const mapProjectType: Projection = { name: 'globe' } as Projection
const route: RouteLocationNormalizedLoaded = useRoute()
const router: Router = useRouter()
const stowageService = useStowageService()

// Algolia search client and Places index
const searchClient: SearchClient = inject('searchClient') as SearchClient
const index: SearchIndex = searchClient.initIndex('place')

const bannerOpen: Ref<boolean> = ref(false)
const itinerary: Ref<Itinerary | null> = ref(null)
const isEditSaved: Ref<boolean> = ref(false)
const activePanel: Ref<'left' | 'right'> = ref('left')
const identityModalVisible: Ref<boolean> = ref(false)
const themeModalVisible: Ref<boolean> = ref(false)
const userTemplate: Ref<Template> = ref({} as Template)
const isTemplateSaving: Ref<boolean> = ref(false)
const isTemplateLoading: Ref<boolean> = ref(true)
const canAccess: Ref<boolean> = ref(true)
// ref to determine which segment is selected
const selectedSegmentIndex: Ref<number | null> = ref(null)
// ref to determine which segment is selected
const selectedStopIndex: Ref<number | null> = ref(null)
// ref to determine which place is selected
const selectedPlaceUri: Ref<string | null> = ref(null)
// ref to determine which segment label is edited
const isEditingPlaceName: Ref<boolean> = ref(false)
// refs for segment label inputs
const placeNameInputRef: Ref<any> = ref(null)
// ref to determine if overview heading is being edited
const isEditingOverviewHeading: Ref<boolean> = ref(false)
// ref for overview heading inputs
const overviewHeadingInputRef: Ref<any> = ref(null)
// ref for saving to library
const advancedSettingsOpenRef: Ref<boolean> = ref(false)
// ref for image carousel modal
const showCarouselModal: Ref<boolean> = ref(false)
// Map loaded state
const hasMapLoaded: Ref<boolean> = ref(false)
// Map center
const center: Ref<LngLat | undefined> = ref()
// LngLatBounds
const bbox: Ref<LngLatBounds | undefined> = ref()
// Map places data
const sources: Ref<FeatureCollection | undefined> = ref(undefined)

// A computed value of all the itinerary assets
const allItineraryAssets = computed(() => {
  const assets: string[] = []

  // Get all the overview images, if exists
  itinerary.value?.images?.map((image: string) => assets.push(image))

  // Get all the place images, if exists
  itinerary.value?.segments.forEach((segment: Segment) => {
    segment.stops.forEach((stop: Stop) => {
      stop.place?.images?.map((image: string) => assets.push(image))
    })
  })
  return assets
})

const selectedStopRef: ComputedRef<Stop | null> = computed(() => {
  if (selectedPlaceUri.value === null) {
    return null
  }

  const stop = itinerary.value!.segments.reduce((accumulator, currentValue) => {
    const stop = currentValue.stops.find((stop) => stop.place.uri === selectedPlaceUri.value)
    if (stop) {
      return stop
    }
    return accumulator
  }, {})

  return stop as Stop
})
const internalNameInputRef = ref<HTMLInputElement>()

onBeforeMount(() => {
  if (route.name === 'trips-editor-routes-new') {
    createNewRoute()
  } else if (route.name === 'trips-editor-routes-duplicate' && route.params.uri) {
    duplicate(route.params.uri as string)
  }
})

onMounted(async () => {
  await getCurrentUserTemplate()
  if (route.path.includes('/trips/editor/routes/edit') && route.params.uri) {
    registerRoutesObserver(route.params.uri as string)
  }
  internalNameInputRef.value?.focus()
})

onBeforeUnmount(() => {
  stateService.getClient(route.params.uri as string).unRegisterObserver(itineraryEditorObserver)
})

const getCurrentUserTemplate = async () => {
  // fetch the default template
  const template: Template | null = await stowageService.getDocument<Template>('template', 'default')
  // use the default template if the user template is not found
  userTemplate.value = template ? getUserTemplate(template) : getDefaultProposalTemplate()
  isTemplateLoading.value = false
}

const itineraryEditorObserver = async () => {
  itinerary.value = stateService.getClient(route.params.uri as string).getContent() as Itinerary
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

watchDebounced([bbox, center], () => getPlacesFromIndex(bbox.value!, center.value!), { debounce: 500, maxWait: 1000 })

const registerRoutesObserver = (uri: string) => {
  stateService.getClient(uri).registerObserver(itineraryEditorObserver)
  stateService.onForbidden(uri, () => {
    canAccess.value = false
  })
}

// Creates a new route and navigates to the edit page
const createNewRoute = async () => {
  showInfoToast('Creating new route...')
  const token = await authenticationContext.getToken()
  const res: Response = await fetch('/api/route/new', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    router.replace({ name: 'trips-editor-routes-edit', params: { uri } })

    registerRoutesObserver(uri)
  } else {
    showErrorToast('Failed to create new route')
    console.debug('Failed to create new route', res)
  }
}

// Duplicates a route and navigates to the edit page
const duplicate = async (fromUri: string) => {
  showInfoToast('Duplicating route...')

  const token = await authenticationContext.getToken()
  const res: Response = await fetch(`/api/route/duplicate/${fromUri}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    router.replace({ name: 'trips-editor-routes-edit', params: { uri } })

    registerRoutesObserver(uri)
  } else {
    showErrorToast('Failed to duplicate route')
    console.debug('Failed to duplicate route', res)
  }
}

const saveAndIndexPlace = async (place: Place) => {
  const placeToIndex = place
  fetch(`/api/place/${encodeURIComponent(placeToIndex.uri)}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${await authenticationContext.getToken()}`,
      accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify(placeToIndex),
  }).then(async () => {
    fetch(`/api/index/place/${placeToIndex.uri}`, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${await authenticationContext.getToken()}`,
        accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify(placeToIndex),
    })
  })
}

/**
 * On change to a segment, stop or place compute the from and to place
 * @param segments - The updated segment object
 */
const updateFromAndToPlace = (segments: Segment[]): Partial<Itinerary> => {
  const places: Place[] = segments.map((segment: Segment) => segment.stops.map((stop: Stop) => stop.place)).flat()
  const first: Place = places[0]
  const last: Place = places[places.length - 1]
  const fromCountryLine = first?.location.country ? `, ${first.location.country}` : ''
  const toCountryLine = last?.location.country ? `, ${last?.location.country}` : ''
  const item: Partial<ItineraryIndexItem> = ls.get<ItineraryIndexItem>(route.params.uri as string) || {}
  ls.set(
    route.params.uri as string,
    { ...item, line_3: `${first?.name}${fromCountryLine}`, line_4: `${last?.name}${toCountryLine}` },
    { ttl: 90 },
  )
  return { from: first, to: last }
}

/**
 * Parse hits and return a geoJSON object from index
 * @param hits - result from hits
 */
const parseHits = (hits: any) => {
  const features = []
  for (const hit of hits) {
    features.push({
      type: 'Feature',
      properties: {
        uri: hit.uri,
        isCustom: (hit.tags && hit.tags.includes('custom')) || false,
        line_1: hit.line_1,
        line_2: hit.line_2,
        line_3: hit.line_3,
        line_4: hit.line_4,
        image: hit.image,
      },
      geometry: { type: 'Point', coordinates: [hit._geoloc.lng, hit._geoloc.lat] },
    })
  }

  return {
    type: 'FeatureCollection',
    features,
  }
}

/**
 * Get places from algolia index for the current bbox
 */
const getPlacesFromIndex = async (bounds: LngLatBounds, center: LngLat): Promise<any> => {
  const ne = bounds.getNorthEast()
  const radius = Math.round(distanceBetween(center.lat, center.lng, ne.lat, ne.lng).value * nauticalMilesToMeters())

  const places: any = await index
    // results ranked around center of visible map bounding box
    .search('', {
      hitsPerPage: 50,
      aroundLatLng: `${center.lat},${center.lng}`,
      aroundRadius: radius,
    })
    .then(({ hits }) => {
      return parseHits(hits)
    })
    .catch((err) => {
      console.error(err)
      return null
    })

  const { features }: { features: Feature[] } = places
  const recentlyAddedFeatures = ls.get(`features-add-${route.params.uri as string}`) || {}
  const featuresMerged = ObjectUtil.deepCopy(features)

  for (const [featureUri, feature] of Object.entries(recentlyAddedFeatures)) {
    if (features.findIndex((feature) => feature.properties.uri === featureUri) === -1) {
      featuresMerged.push(feature)
    }
  }

  const placeUrisToRemove: { [key: string]: string } = ls.get(`place-delete-${route.params.uri as string}`) || {}
  const f = featuresMerged.filter((feature: Feature) => !placeUrisToRemove[feature.properties.uri])

  sources.value = { ...places, features: f }
}

/**
 * Add a stop to the itinerary
 * 1. Add stop from Ankor as a source - Place has all info
 * 2. Add stop from Custom Place as a source - Place has all info
 * 3. Add stop from custom pin drop / Create button - (Save and index) -Add to itinerary
 *
 * @param $event
 */
const addStop = async ($event: any) => {
  const { segmentIndex, place, distance } = $event
  const segments: Segment[] = ObjectUtil.deepCopy(itinerary.value?.segments || [])
  const stopIndex: number = segments[segmentIndex].stops?.length || 0
  const sourcePlace: Place = place

  sourcePlace.images = replacePathToMediaUris(place.uri, ...(place.images || []))

  segments[segmentIndex].stops.push({
    place: sourcePlace,
    pathFromPrevious: {
      coordinates: [] as Coordinates[],
      distance: { value: distance, unit: 'nm' },
    } as Path,
  } as Stop)

  // Update the itinerary before setup so that it is immediately visible
  itinerary.value = { ...itinerary.value, segments: segments } as Itinerary

  if (sourcePlace.tags && !sourcePlace.tags.includes('custom')) {
    const currentUri: URI = URIBuilder.fromString(sourcePlace.uri)
    sourcePlace.uri = URIBuilder.from(
      currentUri.context,
      currentUri.contextId!,
      currentUri.entity,
      UUID.timeBased(),
    ).toString()
  }

  const placeUri = URIBuilder.fromString(sourcePlace.uri)
  if (placeUri.context === URIContext.U) {
    // Need  to validate if it has already been created. If not go ahead and create it before opening up the editor
    const placeResponse = await fetch(`/api/place/${encodeURIComponent(placeUri.toString())}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${await authenticationContext.getToken()}`,
        accept: 'application/json',
        'content-type': 'application/json',
      },
    })

    if (placeResponse.status !== 200) {
      // Not in users library create it else update
      saveAndIndexPlace(place)
      // link usages to place
      linkMedia({ authenticationContext }, sourcePlace.uri, sourcePlace.images)
      selectedPlaceUri.value = place.uri

      selectedSegmentIndex.value = segmentIndex
      selectedStopIndex.value = segments[segmentIndex].stops.length

      const featureItem: Feature = {
        type: 'Feature',
        properties: {
          uri: place.uri,
          isCustom: true,
          line_1: place.location.place,
          line_3: place.location.country,
          line_4: place.location.region,
        },
        geometry: {
          type: 'Point',
          coordinates: [place.location.coordinates.longitude, place.location.coordinates.latitude],
        },
      }

      // If the place was recently deleted (still inside the place-delete localstorage), then remove said item from place-delete localstorage
      const recentlyDeletedPlaces: { [key: string]: string } =
        ls.get(`place-delete-${route.params.uri as string}`) || {}
      delete recentlyDeletedPlaces[featureItem.properties.uri]
      ls.set(`place-delete-${route.params.uri as string}`, recentlyDeletedPlaces, { ttl: 90 })

      const recentlyAddedFeatures: { [key: string]: Feature } =
        ls.get(`features-add-${route.params.uri as string}`) || {}
      ls.set(
        `features-add-${route.params.uri as string}`,
        { ...recentlyAddedFeatures, [featureItem.properties.uri]: featureItem },
        { ttl: 90 },
      )

      getPlacesFromIndex(bbox.value!, center.value!)
    }
    // if already there NOOP
  }

  // link usages in itinerary
  linkMedia({ authenticationContext }, route.params.uri as string, sourcePlace.images)

  segments[segmentIndex].stops[stopIndex] = {
    place: sourcePlace,
    pathFromPrevious: {
      coordinates: [] as Coordinates[],
      distance: { value: distance, unit: 'nm' },
    } as Path,
  } as Stop
  itinerary.value = {
    ...itinerary.value,
    segments: segments,
    ...updateFromAndToPlace(segments),
    duration: `P${segments.length}D` || '',
  } as Itinerary

  stateService.getClient(route.params.uri as string).onChanges(itinerary.value)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

/**
 * Delete stop from an itinerary
 * @param $event
 */
const deleteStop = ($event: any) => {
  const { segmentIndex, stopIndex } = $event
  if (segmentIndex !== null && stopIndex !== null) {
    const segments: Segment[] = ObjectUtil.deepCopy(itinerary.value?.segments || [])
    segments[segmentIndex].stops.splice(stopIndex, 1)
    itinerary.value = { ...itinerary.value, segments, ...updateFromAndToPlace(segments) } as Itinerary
    stateService.getClient(route.params.uri as string).onChanges(itinerary.value)
    isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
  }
}

const handlePlaceDelete = (placeUri: string) => {
  // if this particular place was recently added (still in features-add localstorage), then delete said item from the features-add localstorage
  const recentlyAddedFeatures: { [key: string]: Feature } = ls.get(`features-add-${route.params.uri as string}`) || {}
  delete recentlyAddedFeatures[placeUri]
  ls.set(`features-add-${route.params.uri as string}`, recentlyAddedFeatures, { ttl: 90 })

  // add place uri to the place-delete localstorage
  const recentlyDeletedFeatures = ls.get(`place-delete-${route.params.uri as string}`) || {}
  ls.set(`place-delete-${route.params.uri as string}`, { ...recentlyDeletedFeatures, [placeUri]: true }, { ttl: 90 })
}

const updateSegments = (event: { segments: Segment[] }) => {
  const { segments } = event

  // recalculate distances between stops in every update
  // it's fast enough to do it every time
  updateSegmentDistances(segments)

  //
  itinerary.value = {
    ...itinerary.value,
    segments,
    ...updateFromAndToPlace(segments),
    duration: `P${segments.length}D` || '',
  } as Itinerary

  stateService.getClient(route.params.uri as string).onChanges(itinerary.value)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}
/**
 * Iterates through all segments and stops to update each stop's `distanceFromLast`.
 *
 * WARN: this function mutates the segments array to prevent cloning overhead in what needs to be a fast operation.
 *
 * Rules implemented:
 * - The first stop in the first segment gets a distance of 0.
 * - Each subsequent stop gets a distance computed from the previous stop.
 * - The first stop of a segment (other than the very first stop overall)
 *   is compared to the last stop of the previous segment.
 *
 *
 * @param segments Array of segments to process.
 */
function updateSegmentDistances(segments: Segment[]): void {
  // hold the coordinates of the most recent stop.
  let lastCoordinates: Coordinates | null = null

  // Loop over each segment in order.
  segments.forEach((segment, segmentIndex) => {
    // Default to an empty array if segment.stops is undefined.
    const stops: Stop[] = segment.stops || []

    // Loop over each stop within the current segment.
    stops.forEach((stop, stopIndex) => {
      const currentCoordinates = stop.place.location.coordinates

      // Check if this is the very first stop overall.
      if (segmentIndex === 0 && stopIndex === 0) {
        stop.pathFromPrevious!.distance = { value: 0, unit: 'nm' }
      } else if (lastCoordinates) {
        // Calculate the distance from the previous stop to the current stop.
        const distanceResult = distanceBetween(
          lastCoordinates.latitude,
          lastCoordinates.longitude,
          currentCoordinates.latitude,
          currentCoordinates.longitude,
        )
        stop.pathFromPrevious!.distance.value = Math.abs(Math.round(distanceResult.value))
      }

      // Update lastCoordinates to the current stop's coordinates for the next iteration.
      lastCoordinates = currentCoordinates
    })
  })
}

const updateSelection = (event: { placeUri: string | null; segmentIndex: number | null; stopIndex: number | null }) => {
  selectedPlaceUri.value = event.placeUri
  selectedSegmentIndex.value = event.segmentIndex
  selectedStopIndex.value = event.stopIndex
}

/**
 * show editable overview heading input and focus
 */
const showEditableOverviewHeading = () => {
  isEditingOverviewHeading.value = true
  nextTick(() => overviewHeadingInputRef.value.focus())
}

/**
 * update overview heading when changed
 * @param event change event
 */
const updateOverviewHeading = (event: Event): void => {
  isEditingOverviewHeading.value = false
  const target = event.target as HTMLInputElement
  const { value } = target
  if (value === itinerary.value!.overviewHeading) {
    return
  }

  itinerary.value!.overviewHeading = target.value

  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

const updateItineraryAssets = (event: { assets: string[] }) => {
  itinerary.value!.images = event.assets
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
  const item: Partial<ItineraryIndexItem> = ls.get<ItineraryIndexItem>(route.params.uri as string) || {}
  ls.set(
    route.params.uri as string,
    { ...item, hero: event.assets && event.assets.length ? event.assets[0] : undefined },
    { ttl: 90 },
  )
}

const updateItineraryDescription = (description: string) => {
  if (removeHtmlTagsFromString(description) === itinerary.value!.description) {
    return
  }

  itinerary.value = { ...itinerary.value, description } as Itinerary
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

const updateSegmentDescription = (description: string, segmentIndex: number) => {
  if (removeHtmlTagsFromString(description) === itinerary.value!.segments[segmentIndex].description) {
    return
  }

  itinerary.value!.segments[segmentIndex].description = description
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

/**
 * Method to check if Ankor place
 * @param place
 */
const isAnkorPlace = (place: Place) => {
  const tags = place.tags || []
  return !(tags && tags.includes('custom'))
}

/**
 * Whenever an Ankor place is changed ensue to
 * @param segmentIndex
 * @param stopIndex
 * @param place
 */
const addNewPlaceWhenCustomized = (segmentIndex: number, stopIndex: number, place: Place) => {
  // If Ankor place is being customized
  if (isAnkorPlace(place)) {
    //Clone
    const placeUri: URI = URIBuilder.fromString(place.uri)
    const uri: string = URIBuilder.from(
      URIContext.U,
      authenticationContext.getUser().id,
      placeUri.entity,
      UUID.timeBased(),
    )
    const tags: string[] = place.tags ?? []
    tags.push('custom')
    itinerary.value!.segments[segmentIndex].stops[stopIndex].place = { ...place, uri, tags }
    // link usages to new custom place
    if (place.images) {
      linkMedia({ authenticationContext }, uri, place.images)
    }
    selectedPlaceUri.value = uri
  }
}

/**
 * update place name when changed
 * @param event change event
 * @param segmentIndex
 * @param stopIndex
 */
const updatePlaceName = (event: Event, segmentIndex: number, stopIndex: number): void => {
  isEditingPlaceName.value = false
  const target = event.target as HTMLInputElement
  const { value } = target
  if (value === itinerary.value!.segments[segmentIndex].stops[stopIndex].place.name) {
    return
  }

  addNewPlaceWhenCustomized(segmentIndex, stopIndex, itinerary.value!.segments[segmentIndex].stops[stopIndex].place)
  saveAndIndexPlace(itinerary.value!.segments[segmentIndex].stops[stopIndex].place)
  itinerary.value!.segments[segmentIndex].stops[stopIndex].place.name = value
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

const updateDistanceFrom = (event: Event, segmentIndex: number, stopIndex: number): void => {
  const target = event.target as HTMLInputElement
  const value = Number(target.value).valueOf()
  if (value === selectedStopRef.value!.pathFromPrevious!.distance.value) {
    return
  }

  selectedStopRef.value!.pathFromPrevious!.distance = { unit: 'nm', value: value }
  itinerary.value!.segments[segmentIndex].stops[stopIndex] = selectedStopRef.value!
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

/**
 * show editable place name input and focus
 */
const showEditablePlaceName = () => {
  isEditingPlaceName.value = true
  nextTick(() => placeNameInputRef.value.focus())
}

const updateAdvancePlaceSettings = (segmentIndex: number, stopIndex: number, place: Place): void => {
  if (JSON.stringify(itinerary.value!.segments[segmentIndex].stops[stopIndex].place) === JSON.stringify(place)) {
    return
  }

  saveAndIndexPlace(itinerary.value!.segments[segmentIndex].stops[stopIndex].place)
  itinerary.value!.segments[segmentIndex].stops[stopIndex].place = place
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

const updatePlaceAssets = (event: { assets: string[] }, segmentIndex: number, stopIndex: number): void => {
  addNewPlaceWhenCustomized(segmentIndex, stopIndex, itinerary.value!.segments[segmentIndex].stops[stopIndex].place)
  saveAndIndexPlace(itinerary.value!.segments[segmentIndex].stops[stopIndex].place)
  itinerary.value!.segments[segmentIndex].stops[stopIndex].place.images = event.assets
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

const updatePlaceDescription = (description: string, segmentIndex: number, stopIndex: number): void => {
  if (
    removeHtmlTagsFromString(description) === itinerary.value!.segments[segmentIndex].stops[stopIndex].place.description
  ) {
    return
  }

  addNewPlaceWhenCustomized(segmentIndex, stopIndex, itinerary.value!.segments[segmentIndex].stops[stopIndex].place)
  saveAndIndexPlace(itinerary.value!.segments[segmentIndex].stops[stopIndex].place)
  itinerary.value!.segments[segmentIndex].stops[stopIndex].place.description = description
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

const updateInternalName = (event: Event) => {
  const target = event.target as HTMLInputElement
  const { value } = target
  if (value === itinerary.value?.internalName) {
    return
  }

  const item: Partial<ItineraryIndexItem> = ls.get<ItineraryIndexItem>(route.params.uri as string) || {}
  ls.set(route.params.uri as string, { ...item, line_1: value }, { ttl: 90 })
  itinerary.value!.internalName = value
  stateService.getClient(route.params.uri as string).onChanges(itinerary.value!)
  isEditSaved.value = stateService?.getClient(route.params.uri as string).isSaved()
}

const validItinerary: ComputedRef<boolean> = computed(() => {
  if (!itinerary.value || !isEditSaved.value) {
    return false
  }

  const segmentWithStops = itinerary.value.segments.filter((segment) => {
    return segment.stops.length > 0
  })

  return segmentWithStops.length > 0
})

/**
 * Collects all places occurring within an itinerary
 * @param itinerary
 */
const collectPlaces = (itinerary: Itinerary): string[] => {
  const places: string[] = []
  if (itinerary && itinerary.segments) {
    itinerary.segments.forEach((segment) => {
      segment.stops.forEach((stop) => {
        places.push(stop.place.name)
      })
    })
  }
  return places
}

const updateIdentity = async (identity: Identity) => {
  isTemplateSaving.value = true
  const updatedTemplate: Template = ObjectUtil.deepCopy({ ...userTemplate.value, identity })

  if (route.params.uri) {
    // await to copy over as proposalDocument gets updated instantly with broken images appearing in section
    if (userTemplate.value.identity.companyImage !== identity.companyImage && identity.companyImage !== '') {
      // link company image to proposal
      await linkMedia({ authenticationContext }, route.params.uri as string, identity.companyImage)
    }

    if (userTemplate.value.identity.image !== identity.image && identity.image !== '') {
      // link profile image to proposal
      await linkMedia({ authenticationContext }, route.params.uri as string, identity.image)
    }
  }

  try {
    await stowageService.setDocument('template', 'default', updatedTemplate)
    userTemplate.value = updatedTemplate
  } catch (e) {
    console.error(e)
  }

  isTemplateSaving.value = false
  identityModalVisible.value = false
}

const updateTheme = async (theme: ThemeTemplate) => {
  isTemplateSaving.value = true
  const updatedTemplate: Template = ObjectUtil.deepCopy({ ...userTemplate.value, theme })

  try {
    await stowageService.setDocument('template', 'default', updatedTemplate)
    userTemplate.value = updatedTemplate
  } catch (e) {
    console.error(e)
  }

  isTemplateSaving.value = false
  themeModalVisible.value = false
}
</script>

<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false">
      <!-- p>Meet us at <strong>Cairns</strong>!</p -->
    </Banner>
    <OneColumnWithHeader
      :class-names="
        bannerOpen
          ? {
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #header>
        <div
          class="sticky top-0 z-40 flex justify-between h-16 shrink-0 items-center gap-x-2 sm:gap-x-6 lg:gap-x-4 border-b shadow-sm px-2 sm:px-6 lg:px-8 border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800"
        >
          <!-- nunununununununun -->
          <!--   Breadcrumbs      -->
          <!-- nunununununununun -->
          <div class="min-w-fit">
            <nav>
              <ol class="flex flex-row flex-nowrap shrink-0 items-center">
                <li class="mr-2">
                  <RouterLink
                    aria-current="page"
                    class="text-sm font-medium hover:fill-primary-600 dark:hover:fill-primary-500"
                    :to="{ name: 'trips-library-presentations' }"
                    @click="$mixPanel.track(NAVIGATE_TO_HOME, { from: 'Route Editor' })"
                  >
                    <SolidHome
                      class="size-4 transition-all hover:fill-primary-600 hover:stroke-primary-600 dark:hover:fill-primary-500 dark:hover:stroke-primary-500 fill-gray-500 stroke-gray-500 dark:fill-gray-400 dark:stroke-gray-400"
                    />
                  </RouterLink>
                </li>
                <li class="hidden lg:block mr-2">
                  <RouterLink
                    aria-current="page"
                    class="text-sm transition-all cursor-pointer text-gray-500 dark:text-gray-400 hover:text-primary-600 dark:hover:text-primary-500"
                    :to="{ name: 'trips-library-routes' }"
                    @click="$mixPanel.track(NAVIGATE_TO_HOME, { from: 'Route Editor' })"
                  >
                    Routes
                  </RouterLink>
                </li>
                <li class="hidden lg:block mr-2 text-sm text-gray-500 dark:text-gray-400">/</li>
                <li>
                  <input
                    :ref="(el) => (internalNameInputRef = el as HTMLInputElement)"
                    type="text"
                    class="px-2 w-16 md:w-36 h-8 text-nowrap p-0 overflow-hidden lg:w-52 border rounded-md text-sm bg-transparent text-ellipsis focus:border text-gray-700 dark:text-gray-300 focus:border-primary-600 dark:focus:border-primary-500"
                    :class="{ 'cursor-not-allowed': !isEditSaved }"
                    :disabled="!isEditSaved"
                    :value="itinerary?.internalName || ls.get<ItineraryIndexItem>(route.params.uri as string)?.line_1"
                    @blur="updateInternalName($event)"
                    @click="
                      ;[
                        ($event.target as HTMLInputElement).select(),
                        $mixPanel.track(CHANGE_ROUTE_NAME, { from: 'Route Editor' }),
                      ]
                    "
                  />
                </li>
              </ol>
            </nav>
          </div>

          <div class="flex justify-center items-center gap-x-2">
            <!-- Diff sync saved state -->
            <span
              class="w-12 font-medium text-xs"
              :class="isEditSaved ? 'text-gray-900 dark:text-gray-50' : 'text-gray-500 dark:text-gray-400'"
            >
              {{ isEditSaved ? 'Saved' : 'Saving...' }}
            </span>
            <RouterLink
              target="_blank"
              class="whitespace-nowrap w-full sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white focus:ring-primary-300 dark:focus:ring-primary-300"
              :to="{
                name: 'trips-editor-presentation-new',
                query: {
                  item: JSON.stringify(
                    itinerary
                      ? {
                          line_1: itinerary.internalName,
                          line_7: collectPlaces(itinerary),
                          type: 'itinerary',
                          uri: itinerary.uri,
                        }
                      : '',
                  ),
                },
              }"
              :class="
                validItinerary
                  ? 'bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800'
                  : 'pointer-events-none bg-gray-200 dark:bg-gray-400'
              "
              @click="validItinerary ? $mixPanel.track(NEW_PRESENTATION, { from: 'Route Editor' }) : undefined"
            >
              <SolidDocumentAdd class="size-5 mr-2 self-center" />
              Add Route to New Presentation
            </RouterLink>

            <AccountMenu
              class="z-20"
              :name="authenticationContext.getUser()?.given_name"
              @logout="$authenticationContext.logout()"
            >
              <template #contextualLinks>
                <MenuItem v-slot="{ active }">
                  <li
                    class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                    :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                    @click="
                      ;[
                        (identityModalVisible = true),
                        $mixPanel.track(OPEN_PROFILE_COMPANY_SETTINGS, { from: 'Route Editor' }),
                      ]
                    "
                  >
                    <OutlinePerson
                      class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                    />
                    Profile &amp; Company
                  </li>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <li
                    class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                    :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                    @click="
                      ;[
                        (themeModalVisible = true),
                        $mixPanel.track(OPEN_STYLE_BRANDING_SETTINGS, { from: 'Route Editor' }),
                      ]
                    "
                  >
                    <HiOutlineSwatch
                      class="size-4 shrink-0 stroke-gray-500 dark:stroke-gray-400 group-hover:stroke-gray-900 dark:group-hover:stroke-gray-50"
                    />
                    Style & Branding
                  </li>
                </MenuItem>
                <MenuItem v-slot="{ active }" class="border-b border-b-gray-200 dark:border-b-gray-600">
                  <li
                    class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                    :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                  >
                    <OutlineDocumentText
                      class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                    />
                    <a href="https://ankor.io/terms-conditions" target="_blank"> Terms &amp; Conditions </a>
                  </li>
                </MenuItem>
              </template>
            </AccountMenu>
          </div>
        </div>
      </template>
      <template #default>
        <div v-if="$route.name === 'trips-editor-routes-new'"><Loader /></div>
        <div v-if="$route.name === 'trips-editor-routes-duplicate'"><Loader /></div>
        <template v-if="$route.name === 'trips-editor-routes-edit'">
          <div class="h-[calc(100vh-7rem)]">
            <div v-if="!canAccess">You don't have permission to view this document.</div>

            <Loading v-else-if="!itinerary" />

            <!-- Itinerary Builder -->
            <div
              v-else
              class="h-full bg-gray-50 border border-gray-200 rounded-lg flex dark:bg-gray-800 dark:border-gray-600"
              @click.stop="
                ;[
                  (activePanel = 'left'),
                  activePanel !== 'left'
                    ? $mixPanel.track(EXPAND_CONTENT_PANEL, { from: 'Route Editor', panel: 'Location' })
                    : undefined,
                ]
              "
            >
              <!-- map container -->
              <ItineraryMapContainer
                class="pl-6 py-6 transition-all ease-out duration-300 grow shrink-0"
                :uri="(route.params.uri as string)"
                :class="[
                  activePanel === 'left' ? 'h-full basis-[calc(100%-42rem)] opacity-100' : 'h-80 basis-96 opacity-80',
                ]"
                :access-token="accessToken"
                :initial-zoom="4"
                :projection-type="mapProjectType"
                :center="[-75.818333, 14.525556]"
                :segments="itinerary.segments"
                :isActive="activePanel === 'left'"
                :index="index"
                :sources="sources"
                :hasMapLoaded="hasMapLoaded"
                @add:marker="addStop($event)"
                @adding:marker="isEditSaved = false"
                @delete:marker="deleteStop($event)"
                @update:bbox="bbox = $event"
                @update:center="center = $event"
                @update:map:loaded="hasMapLoaded = $event"
                @deleted:place:uri="handlePlaceDelete($event)"
                @click.stop="
                  ;[
                    (activePanel = 'left'),
                    activePanel !== 'left'
                      ? $mixPanel.track(EXPAND_CONTENT_PANEL, { from: 'Route Editor', panel: 'Location' })
                      : undefined,
                  ]
                "
              />

              <!-- segments container -->
              <ItinerarySegments
                class="py-7 pl-8 grow-0 shrink-0 basis-80 overflow-y-auto overflow-x-hidden"
                :overview-heading="itinerary.overviewHeading || 'Overview'"
                :selected-place-uri="selectedPlaceUri"
                :selected-segment-index="selectedSegmentIndex"
                :selected-stop-index="selectedStopIndex"
                :segments="itinerary.segments"
                @update:segments="updateSegments"
                @update:selection="updateSelection"
              />

              <!-- editable content container -->
              <div
                class="h-[calc(100vh-8rem)] text-sm pb-6 px-8 transition-[flex-basis] ease-out duration-300 grow shrink-0 overflow-y-auto border-l border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                :class="
                  activePanel === 'right'
                    ? 'basis-[calc(100%-55rem)] opacity-100'
                    : 'basis-80 opacity-80 dark:opacity-100'
                "
                @click.stop="
                  ;[
                    (activePanel = 'right'),
                    activePanel !== 'right'
                      ? $mixPanel.track(EXPAND_CONTENT_PANEL, { from: 'Route Editor', panel: 'Overview' })
                      : undefined,
                  ]
                "
              >
                <!-- Overview -->
                <template v-if="selectedPlaceUri === null">
                  <div
                    class="fixed z-10 h-16 pt-8 pr-8 border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-gray-700"
                    :class="[activePanel === 'right' ? 'w-[calc(100%-59rem)]' : 'w-72']"
                  >
                    <!-- overview label -->
                    <div
                      class="flex items-center gap-x-3.5"
                      @click="showEditableOverviewHeading()"
                      :class="{ hidden: isEditingOverviewHeading }"
                    >
                      <span class="text-2xl font-bold line-clamp-1">
                        {{ itinerary.overviewHeading || 'Overview' }}
                      </span>
                      <SolidPencil class="w-3 h-3 text-gray-500 dark:text-white" />
                    </div>

                    <!-- editable overview label -->
                    <div class="relative" :class="{ hidden: !isEditingOverviewHeading }">
                      <input
                        type="text"
                        class="block px-0 py-0 w-full leading-none text-2xl font-bold text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-600 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder=" "
                        :ref="(el: any) => (overviewHeadingInputRef = el)"
                        :value="itinerary.overviewHeading || 'Overview'"
                        @blur="updateOverviewHeading"
                      />
                    </div>
                  </div>

                  <!-- View all route Images -->
                  <div>
                    <div class="mt-20 font-semibold">
                      <button
                        type="button"
                        class="px-5 py-2.5 text-white bg-primary-600 hover:bg-primary-700 disabled:cursor-not-allowed disabled:bg-gray-300 focus:outline-none font-medium rounded-lg text-sm text-center dark:disabled:bg-primary-300"
                        :disabled="allItineraryAssets.length === 0"
                        @click="showCarouselModal = true"
                      >
                        View all Route Images
                      </button>
                    </div>
                    <!-- itinerary assets -->
                    <div class="my-4 font-semibold">Overview images</div>
                  </div>

                  <ItineraryAssets
                    class="mt-4"
                    :uri="route.params.uri as string"
                    :images="itinerary.images || []"
                    @update:assets="updateItineraryAssets"
                  />

                  <div class="divide-y divide-gray-200 dark:divide-gray-500 mr-0.5">
                    <!-- itinerary description wrapper -->
                    <div class="mt-8">
                      <label for="itin-desc" class="block font-medium text-gray-900 dark:text-white">
                        Route overview
                      </label>
                      <MultiLineTextEditor
                        class="p-2.5 mt-3 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                        placeholder="Write overview here..."
                        :value="itinerary.description"
                        @update:value="updateItineraryDescription"
                      />
                    </div>

                    <!-- segment description wrapper -->
                    <div class="mt-8">
                      <div
                        v-for="(segment, segmentIndex) of itinerary.segments"
                        :key="`segment-description-${segmentIndex}`"
                        class="mt-8"
                      >
                        <!-- segment label -->
                        <label
                          class="block mt-8 text-sm font-medium text-gray-900 dark:text-white"
                          :for="`segment-desc-${segmentIndex}`"
                        >
                          {{ segment.label }} Overview
                        </label>

                        <!-- segment description -->
                        <MultiLineTextEditor
                          placeholder="Write description here..."
                          class="p-2.5 mt-3 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                          :value="segment.description"
                          @update:value="updateSegmentDescription($event, segmentIndex)"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <!-- Stops / Place -->
                <template v-for="(segment, segmentIndex) in itinerary.segments" :key="segment.label">
                  <template v-if="segmentIndex === selectedSegmentIndex">
                    <template v-for="(stop, stopIndex) in segment.stops" :key="stop.place.uri">
                      <template v-if="stopIndex === selectedStopIndex">
                        <div
                          class="fixed z-30 h-26 pt-8 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700"
                          :class="[activePanel === 'right' ? 'w-[calc(100%-56rem)]' : 'w-80']"
                        >
                          <div class="flex items-center justify-between">
                            <!-- place name -->
                            <div
                              class="flex items-center gap-x-3.5"
                              :class="[
                                { hidden: isEditingPlaceName },
                                { 'select-none cursor-text text-gray-600 dark:text-gray-500': !stop.place.name },
                              ]"
                              @click="showEditablePlaceName()"
                            >
                              <span class="text-2xl font-bold line-clamp-1">
                                {{ stop.place.name || 'Enter a place name...' }}
                              </span>
                              <SolidPencil class="w-4 h-4 text-gray-500 dark:text-gray-400" />
                            </div>
                          </div>

                          <!-- editable place name -->
                          <div class="relative" :class="{ hidden: !isEditingPlaceName }">
                            <input
                              type="text"
                              class="block px-0 py-0 w-full leading-none text-2xl font-bold text-gray-900 dark:text-white bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-600"
                              placeholder=" "
                              :ref="(el: any) => (placeNameInputRef = el)"
                              :value="stop.place.name"
                              @blur="updatePlaceName($event, segmentIndex, stopIndex)"
                            />
                          </div>

                          <!-- approx nm from last -->
                          <div class="relative flex my-1 py-1">
                            <input
                              type="text"
                              id="floating_outlined_internal"
                              placeholder="Enter a place name..."
                              class="peer h-6 w-32 block px-2.5 pb-2 pt-2.5 text-xs text-gray-900 dark:text-white bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-600"
                              :value="selectedStopRef?.pathFromPrevious?.distance?.value || 0"
                              @blur="updateDistanceFrom($event, segmentIndex, stopIndex)"
                            />
                            <label
                              for="floating_outlined_internal"
                              class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2.5 z-10 origin-[0] bg-white dark:bg-gray-700 px-2 peer-focus:px-2 peer-focus:text-primary-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            >
                              Approx nm from last
                            </label>
                          </div>
                        </div>

                        <!-- Wrapper for content editor -->
                        <div class="mt-28">
                          <div
                            v-show="!isAnkorPlace(stop.place)"
                            class="mt-2 flex justify-end cursor-pointer text-gray-500 dark:text-gray-400 hover:text-primary-600 dark:hover:text-primary-500"
                            @click.stop="advancedSettingsOpenRef = !advancedSettingsOpenRef"
                          >
                            <OutlineChevronDown
                              class="w-4 h-4 transition-all"
                              :class="advancedSettingsOpenRef ? 'rotate-180' : 'rotate-0'"
                            />
                          </div>
                          <div
                            class="overflow-hidden w-full transition-all duration-300 ease-in-out text-gray-900 dark:text-white"
                            :class="
                              advancedSettingsOpenRef
                                ? 'h-80 pb-1 mb-8 max-h-full border-b border-gray-200 dark:border-gray-600'
                                : 'h-0'
                            "
                            @click.stop
                          >
                            <h3 class="mb-4 text-xl font-bold">Advanced Settings</h3>
                            <div
                              class="grid gap-4 text-sm font-medium w-full"
                              :class="activePanel === 'right' ? 'grid-cols-2' : 'grid-cols-1'"
                            >
                              <div>
                                <label for="latitude" class="block mb-2 text-sm font-medium">Latitude</label>
                                <input
                                  type="number"
                                  name="latitude"
                                  id="latitude"
                                  min="-90.00"
                                  max="90.00"
                                  step="0.000000000000001"
                                  v-model="stop.place.location.coordinates.latitude"
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                  class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 placeholder:dark:text-gray-500 bg-gray-50 rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="0.00"
                                  @focusout="updateAdvancePlaceSettings(segmentIndex, stopIndex, stop.place)"
                                />
                              </div>
                              <div>
                                <label for="latitude" class="block mb-2 text-sm font-medium">Longitude</label>
                                <input
                                  type="number"
                                  name="latitude"
                                  id="latitude"
                                  min="-90.00"
                                  max="90.00"
                                  step="0.000000000000001"
                                  v-model="stop.place.location.coordinates.longitude"
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                  class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 placeholder:dark:text-gray-500 bg-gray-50 rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="0.00"
                                  @focusout="updateAdvancePlaceSettings(segmentIndex, stopIndex, stop.place)"
                                />
                              </div>
                              <div>
                                <label for="country" class="block mb-2">Country</label>
                                <input
                                  type="text"
                                  name="country"
                                  id="country"
                                  v-model="stop.place.location.country"
                                  class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 placeholder:dark:text-gray-500 bg-gray-50 rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Designated country having a code under ISO 3166-1"
                                  @focusout="updateAdvancePlaceSettings(segmentIndex, stopIndex, stop.place)"
                                />
                              </div>
                              <div>
                                <label for="region" class="block mb-2 text-sm font-medium">Region</label>
                                <input
                                  type="text"
                                  name="region"
                                  id="region"
                                  v-model="stop.place.location.region"
                                  class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 placeholder:dark:text-gray-500 bg-gray-50 rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Sub-national feature / province"
                                  @focusout="updateAdvancePlaceSettings(segmentIndex, stopIndex, stop.place)"
                                />
                              </div>
                              <div>
                                <label for="district" class="block mb-2 text-sm font-medium">District</label>
                                <input
                                  type="text"
                                  name="district"
                                  id="district"
                                  v-model="stop.place.location.district"
                                  class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 placeholder:dark:text-gray-500 bg-gray-50 rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="District"
                                  @focusout="updateAdvancePlaceSettings(segmentIndex, stopIndex, stop.place)"
                                />
                              </div>
                              <div>
                                <label for="address" class="block mb-2 text-sm font-medium">Address</label>
                                <input
                                  type="text"
                                  name="address"
                                  id="address"
                                  v-model="stop.place.location.address"
                                  class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 placeholder:dark:text-gray-500 bg-gray-50 rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder="Residential / Business Address"
                                  @focusout="updateAdvancePlaceSettings(segmentIndex, stopIndex, stop.place)"
                                />
                              </div>
                            </div>
                          </div>

                          <!-- place assets -->
                          <p class="mt-2 font-semibold">All photos</p>

                          <ItineraryAssets
                            class="mt-4"
                            :uri="stop.place.uri"
                            :images="stop.place.images || []"
                            @update:assets="updatePlaceAssets($event, segmentIndex, stopIndex)"
                          />

                          <!-- place description wrapper-->
                          <div class="mt-8">
                            <label for="place-desc" class="block font-medium text-gray-900 dark:text-white">
                              Location description
                            </label>
                            <MultiLineTextEditor
                              class="p-2.5 mt-3 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                              placeholder="Write description here..."
                              :value="stop.place.description"
                              @update:value="updatePlaceDescription($event, segmentIndex, stopIndex)"
                            />
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </template>
      </template>
    </OneColumnWithHeader>

    <!-- Identity / Profile & Company modal -->
    <Teleport v-if="identityModalVisible" to="body">
      <GlobalIdentityModal
        :identity="userTemplate.identity"
        :userTemplateUri="userTemplateUri"
        :isSaving="isTemplateSaving"
        @update:identity="
          ;[updateIdentity($event), $mixPanel.track(CONFIRM_PROFILE_COMPANY_SETTINGS, { from: 'Route Editor' })]
        "
        @close:modal="identityModalVisible = false"
      />
    </Teleport>

    <!-- Style modal -->
    <Teleport v-if="themeModalVisible" to="body">
      <GlobalStyleModal
        :theme="userTemplate.theme"
        :isSaving="isTemplateSaving"
        @update:theme="
          ;[updateTheme($event), $mixPanel.track(CONFIRM_STYLE_BRANDING_SETTINGS, { from: 'Route Editor' })]
        "
        @close:modal="themeModalVisible = false"
      />
    </Teleport>

    <!-- Show image carousel modal (all itinerary images) -->
    <Teleport v-if="showCarouselModal" to="body">
      <ImageCarouselModalEditor :slides="allItineraryAssets" @close="showCarouselModal = false" />
    </Teleport>
  </div>
</template>
