import { MMKCalendar } from '@ankor-io/common/booking-manager/types'
import { CalendarEvent } from '@ankor-io/common/vessel/types'

import { Env } from '../../env'
import { mapper as bookingManagerMapper } from './booking-manager'
import { mapper as icsMapper } from './ics'

export type Mappers = {
  [MapperTypes.BOOKING_MANAGER]: Mapper<
    MMKCalendar[],
    { companyId: string; provider: MapperTypes; year: number; month: number },
    Env
  >
  [MapperTypes.ICS_SUBSCRIPTION]: Mapper<string, { vesselUri: string; calendarSubscriptionSettingsUri: string }, Env>
  [MapperTypes.ICS_FILE_IMPORT]: Mapper<string, { vesselUri: string }, Env>
}

export type MapResult = {
  ankorCalendarEvents: Record<string, CalendarEvent[]>
}

export type Mapper<T, S, Env> = {
  map: (
    content: T,
    state: { provider: MapperTypes } & S, // Optional param in case the mapper needs to generate a uri based on company id and provider
    env: Env,
  ) => MapResult | Promise<MapResult>
}

export type IcsMapResult = {
  ankorCalendarEvents: Record<string, CalendarEvent[]>
}

export type IcsMapper = {
  map: (
    icsCalendar: string,
    state: { vesselUri: string; calendarSubscriptionSettingsUri?: string },
    env: Env,
  ) => IcsMapResult
}

export enum MapperTypes {
  BOOKING_MANAGER = 'booking_manager',
  ICS_SUBSCRIPTION = 'ics_subscription',
  ICS_FILE_IMPORT = 'ics_file_import',
}

export const provider: Mappers = {
  [MapperTypes.BOOKING_MANAGER]: bookingManagerMapper,
  [MapperTypes.ICS_SUBSCRIPTION]: icsMapper,
  [MapperTypes.ICS_FILE_IMPORT]: icsMapper,
}
