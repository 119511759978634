import { GrowthBook } from '@growthbook/growthbook'
import { inject } from 'vue'

import { SectionDefinition, SectionType } from '@ankor-io/common/proposal/SectionType'
import { SlideType } from '@ankor-io/common/proposal/SlideType'
import {
  SolidAddAdditionalNotesSection,
  SolidAddBusinessCardSection,
  SolidAddButtonSection,
  SolidAddChecklistSection,
  SolidAddDayInformationSection,
  SolidAddDisclaimerSection,
  SolidAddHeaderSection,
  SolidAddHeroSection,
  SolidAddImageSection,
  SolidAddMapSection,
  SolidAddNavBarSection,
  SolidAddOverviewSection,
  SolidAddSpaceSection,
  SolidAddTripSummarySection,
  SolidTag,
  SolidVesselCrew,
  SolidVesselHero,
  SolidVesselSpecifications,
  SolidVesselToysAmenities,
  SolidVideoCamera,
  SolidYachtSummary,
} from '@ankor-io/icons/solid'

import { WHEELHOUSE_20230919_YACHT_SUMMARY_CARD_SECTION } from '@/utils/growthbook/constants'

/**
 * Supported Icon variants
 */
enum IconVariants {
  SOLID = 'solid',
  OUTLINE = 'outline',
}

/**
 * All the section types.
 */
export const getSectionTypes = (): Record<SectionType, SectionDefinition> => {
  const growthbook: GrowthBook = inject('growthbook')!

  return {
    [SectionType.YACHT_SUMMARY_CARD]: {
      label: 'Yacht summary',
      type: SectionType.YACHT_SUMMARY_CARD,
      targetType: [SlideType.ITINERARY],
      icon: SolidYachtSummary,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Yacht Summary',
      enabled: growthbook.isOn(WHEELHOUSE_20230919_YACHT_SUMMARY_CARD_SECTION),
      openOptionsPanelOnAdd: true,
      disableSaveLayout: true,
    },
    [SectionType.VESSEL_HERO]: {
      label: 'Hero',
      type: SectionType.VESSEL_HERO,
      targetType: [SlideType.VESSEL],
      icon: SolidVesselHero,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Hero',
      openOptionsPanelOnAdd: false,
    },
    [SectionType.VESSEL_TOYS_AMENITIES]: {
      label: 'Toys & Amenities',
      type: SectionType.VESSEL_TOYS_AMENITIES,
      targetType: [SlideType.VESSEL],
      icon: SolidVesselToysAmenities,
      iconVariant: 'solid',
      optionsPanel: false,
      optionsPanelTitle: 'Toys & Amenities',
      enabled: true,
      openOptionsPanelOnAdd: false,
    },
    [SectionType.VESSEL_OVERVIEW]: {
      label: 'Overview',
      type: SectionType.VESSEL_OVERVIEW,
      targetType: [SlideType.VESSEL],
      icon: SolidAddOverviewSection,
      iconVariant: 'solid',
      optionsPanel: false,
      optionsPanelTitle: 'Overview',
      openOptionsPanelOnAdd: false,
    },
    [SectionType.VESSEL_CREW]: {
      label: 'Crew',
      type: SectionType.VESSEL_CREW,
      targetType: [SlideType.VESSEL],
      icon: SolidVesselCrew,
      iconVariant: 'solid',
      optionsPanel: false,
      optionsPanelTitle: 'Crew',
      openOptionsPanelOnAdd: false,
    },
    [SectionType.VESSEL_SPECIFICATIONS]: {
      label: 'Specifications',
      type: SectionType.VESSEL_SPECIFICATIONS,
      targetType: [SlideType.VESSEL],
      icon: SolidVesselSpecifications,
      iconVariant: 'solid',
      optionsPanel: false,
      optionsPanelTitle: 'Specifications',
      openOptionsPanelOnAdd: false,
    },
    [SectionType.VESSEL_PRICING]: {
      label: 'Pricing',
      type: SectionType.VESSEL_PRICING,
      targetType: [SlideType.VESSEL],
      icon: SolidTag,
      iconVariant: 'solid',
      optionsPanel: false,
      optionsPanelTitle: 'Pricing',
      openOptionsPanelOnAdd: false,
      disableSaveLayout: false,
    },
    [SectionType.ITINERARY_HERO]: {
      label: 'Hero Image',
      type: SectionType.ITINERARY_HERO,
      targetType: [SlideType.ITINERARY],
      icon: SolidAddHeroSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Hero Options',
    },
    [SectionType.HEADING]: {
      label: 'Heading',
      type: SectionType.HEADING,
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      icon: SolidAddHeaderSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Titles',
    },
    [SectionType.ITINERARY_OVERVIEW]: {
      label: 'Overview',
      type: SectionType.ITINERARY_OVERVIEW,
      targetType: [SlideType.ITINERARY],
      icon: SolidAddOverviewSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Overview',
    },
    [SectionType.ITINERARY_MAP]: {
      label: 'Route Map',
      type: SectionType.ITINERARY_MAP,
      targetType: [SlideType.ITINERARY],
      icon: SolidAddMapSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Map',
    },
    [SectionType.ITINERARY_SUMMARY]: {
      label: 'Summary',
      type: SectionType.ITINERARY_SUMMARY,
      targetType: [SlideType.ITINERARY],
      icon: SolidAddTripSummarySection,
      iconVariant: 'solid',
      optionsPanel: false,
    },
    [SectionType.SPACER]: {
      label: 'Space',
      type: SectionType.SPACER,
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      icon: SolidAddSpaceSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Space',
    },
    [SectionType.ADDITIONAL_NOTES]: {
      label: 'Additional Notes',
      type: SectionType.ADDITIONAL_NOTES,
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      icon: SolidAddAdditionalNotesSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Text block with/without image',
    },
    [SectionType.BUTTON]: {
      label: 'Button',
      type: SectionType.BUTTON,
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      icon: SolidAddButtonSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Button',
    },
    [SectionType.EMBED]: {
      label: 'Embed Video',
      type: SectionType.EMBED,
      icon: SolidVideoCamera,
      iconVariant: IconVariants.SOLID,
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      optionsPanel: true,
      optionsPanelTitle: 'Embed Video',
    },
    [SectionType.ITINERARY_CHECKLIST]: {
      label: 'Checklist',
      type: SectionType.ITINERARY_CHECKLIST,
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      icon: SolidAddChecklistSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Before you board',
    },
    [SectionType.PROFILE_COMPANY]: {
      label: 'Business Card',
      type: SectionType.PROFILE_COMPANY,
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      icon: SolidAddBusinessCardSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Profile & company',
    },
    [SectionType.ALL_DAY_CARDS]: {
      label: 'All Day Cards',
      type: SectionType.ALL_DAY_CARDS,
      targetType: [SlideType.ITINERARY],
      icon: SolidAddDayInformationSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Day Cards',
    },
    [SectionType.DISCLAIMERS]: {
      label: 'Disclaimer',
      type: SectionType.DISCLAIMERS,
      icon: SolidAddDisclaimerSection,
      iconVariant: 'solid',
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      optionsPanel: true,
      optionsPanelTitle: 'Disclaimers',
    },
    [SectionType.IMAGE]: {
      label: 'Image',
      type: SectionType.IMAGE,
      targetType: [SlideType.ITINERARY, SlideType.VESSEL],
      icon: SolidAddImageSection,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Images',
    },
    [SectionType.VESSEL_SHOWCASE]: {
      label: 'Yacht',
      type: SectionType.VESSEL_SHOWCASE,
      targetType: [SlideType.ITINERARY],
      icon: SolidYachtSummary,
      iconVariant: 'solid',
      optionsPanel: true,
      optionsPanelTitle: 'Yacht',
      openOptionsPanelOnAdd: false,
      disableSaveLayout: true,
    },
    //
    // disabled
    //
    [SectionType.NAV_BAR]: {
      label: 'Nav bar',
      type: SectionType.NAV_BAR,
      targetType: [SlideType.ITINERARY],
      icon: SolidAddNavBarSection,
      iconVariant: 'solid',
      optionsPanel: false,
      enabled: false,
      autoRemove: true,
      disableSaveLayout: true,
    },
  }
}

/**
 * Find all the sections for a particular type.
 * @param slideType the slide type
 * @returns a filtered list of sections.
 */
export const sectionsFor = (slideType: SlideType) => {
  return (
    Object.values(getSectionTypes())
      // remove not enabled (default is they are enabled)
      .filter((def) => def.enabled ?? true)
      // remove autoRemoved (default is they are included)
      .filter((def) => !def.autoRemove)
      // filter for specific slides.
      .filter((def) => def.targetType.includes(slideType))
  )
}
