<script lang="ts" setup>
import { Ref, computed, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { State } from '@ankor-io/common/events/Editor'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { SolidUser } from '@ankor-io/icons/solid'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

import { Crew } from './types'

type Props = {
  /**
   * The uri of the entity the asset belongs to
   */
  uri: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The crew member object
   */
  crewMember: Crew
  /**
   * The index of the crew array
   */
  index: number
}

const props = defineProps<Props>()

const isClamped: Ref<boolean> = ref(true)
const stateRef: Ref<State> = ref(State.READ)

const emit = defineEmits<{
  (e: 'update:value', value: { data: Crew; index: number }): void
}>()

const avatarPath = computed(
  () => props.crewMember.avatar && replacePathToMediaUris(props.proposalUri, props.crewMember.avatar)[0],
)

/**
 * Update crew member
 * @param data
 */
const update = (data: Crew) => {
  emit('update:value', { data, index: props.index })
}
</script>
<template>
  <div
    class="min-w-[19rem] @lg:min-w-0 flex flex-col rounded-lg border shadow-sm bg-white border-gray-200 dark:border-gray-600"
  >
    <div class="flex overflow-hidden rounded-t-lg h-64 @lg:h-80">
      <AssetViewerPlaceholder v-if="avatarPath" class="w-full h-full object-cover" :url="`/media/${avatarPath}`" />
      <SolidUser v-else class="w-full bg-primary-100 fill-primary-400" />
    </div>

    <div class="p-4 rounded-b-lg">
      <h1 class="!text-2xl font-bold mb-2">
        <SingleLineTextEditor
          placeholder="Enter name"
          :value="props.crewMember.name"
          @update:value="update({ ...props.crewMember, name: $event })"
        />
      </h1>

      <h1 class="!text-base font-bold mb-4">
        <SingleLineTextEditor
          placeholder="Enter role"
          :value="props.crewMember.roles?.join(',')"
          @update:value="update({ ...props.crewMember, roles: $event.split(',').map((role) => role.trim()) })"
        />
      </h1>

      <p :class="isClamped ? 'line-clamp-6' : 'line-clamp-none'" class="mb-4">
        <MultiLineTextEditor
          :value="props.crewMember.bio"
          placeholder="Enter bio here"
          @click.stop="stateRef = State.EDIT"
          @on:blur="stateRef = State.READ"
          @update:value="update({ ...props.crewMember, bio: $event })"
        />
      </p>

      <span class="cursor-pointer text-theme-primary" @click="isClamped = !isClamped">
        {{ isClamped ? 'Show more' : 'Show less' }}
      </span>
    </div>
  </div>
</template>
