<script setup lang="ts">
import { MenuItem } from '@headlessui/vue'
import { Mixpanel } from 'mixpanel-browser'
import { Ref, inject, onMounted, ref } from 'vue'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import TwoColumnWithHeader from '@ankor-io/blocks/layouts/TwoColumnWithHeader.vue'
import { Template } from '@ankor-io/common/proposal/Proposal'
import { OutlineDocumentText } from '@ankor-io/icons/outline'
import { SolidCommodore, SolidContracts, SolidRadar, SolidTrips } from '@ankor-io/icons/solid'

import { getDefaultProposalTemplate, getUserTemplate } from '@/services/proposal/ProposalService'
import { useStowageService } from '@/services/stowage/StowageService'
import { LOGOUT, SELECT_ANKOR_APP } from '@/utils/mixPanel/constants'

const stowageService = useStowageService()
const mixPanel: Mixpanel | undefined = inject('mixPanel')!
const authenticationContext: any = inject('authenticationContext')!

const [env, tld, _appName, branchName] = location.hostname.split('.').reverse()
const apps = [
  {
    name: 'Trips',
    icon: SolidTrips,
    appName: 'app',
    onClick: () => mixPanel?.track(SELECT_ANKOR_APP, { app: 'Trips' }),
  },
  {
    name: 'Yacht Listings',
    icon: SolidRadar,
    appName: 'radar',
    onClick: () => mixPanel?.track(SELECT_ANKOR_APP, { app: 'Radar' }),
  },
  {
    name: 'Fleet Manager',
    icon: SolidCommodore,
    appName: 'calendars',
    onClick: () => mixPanel?.track(SELECT_ANKOR_APP, { app: 'Calendars' }),
  },
  {
    name: 'Contracts',
    icon: SolidContracts,
    appName: 'contracts',
    onClick: () => mixPanel?.track(SELECT_ANKOR_APP, { app: 'Contracts' }),
  },
].map((app) => ({
  ...app,
  href: `https://${[branchName, app.appName, tld, env].filter((part) => !!part).join('.')}`,
}))

const bannerOpen: Ref<boolean> = ref(false)

const isTemplateLoading: Ref<boolean> = ref(true)
const userTemplate: Ref<Template> = ref({} as Template)

onMounted(async () => await getCurrentUserTemplate())

const getCurrentUserTemplate = async () => {
  // fetch the default template
  const template: Template | null = await stowageService.getDocument<Template>('template', 'default')
  // use the default template if the user template is not found
  userTemplate.value = template ? getUserTemplate(template) : getDefaultProposalTemplate()
  isTemplateLoading.value = false
}
</script>
<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false"></Banner>
    <TwoColumnWithHeader
      :navigation="[]"
      :apps="apps"
      :class-names="
        bannerOpen
          ? {
              sidebar: 'lg:top-[2.75rem]',
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #appGroupTitle>
        <div class="text-xs/6 ml-2 font-semibold text-gray-900 dark:text-gray-50">Apps</div>
      </template>
      <template #header>
        <div class="flex flex-1 items-center justify-end gap-x-4 self-stretch lg:gap-x-6">
          <!-- Profile dropdown -->
          <AccountMenu
            :name="authenticationContext.getUser()?.given_name"
            @logout=";[$authenticationContext.logout(), $mixPanel.track(LOGOUT, { from: 'Home' })]"
          >
            <template #contextualLinks>
              <MenuItem v-slot="{ active }" class="border-b border-b-gray-200 dark:border-b-gray-600">
                <li
                  class="group flex items-center gap-x-2 cursor-pointer w-full text-left px-3 py-1 text-sm/6 text-gray-900 dark:text-white"
                  :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
                >
                  <OutlineDocumentText
                    class="size-4 shrink-0 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-50"
                  />
                  <a href="https://ankor.io/terms-conditions" target="_blank"> Terms &amp; Conditions </a>
                </li>
              </MenuItem>
            </template>
          </AccountMenu>
        </div>
      </template>
      <template #default>
        <RouterView name="content" />
      </template>
    </TwoColumnWithHeader>
  </div>
</template>
