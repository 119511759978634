<script lang="ts" setup>
import { Ref, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import { SolidUser } from '@ankor-io/icons/solid'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

import { Crew } from './types'

type Props = {
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The crew member object
   */
  crewMember: Crew
  /**
   * The index of the crew array
   */
  index: number
}

const props = defineProps<Props>()

const isClamped: Ref<boolean> = ref(true)
</script>
<template>
  <div
    class="min-w-[19rem] @lg:min-w-0 flex flex-col rounded-lg border shadow-sm bg-white border-gray-200 dark:border-gray-600"
  >
    <div class="flex overflow-hidden rounded-t-lg h-64 @lg:h-80">
      <AssetViewer
        v-if="props.crewMember.avatar"
        class="w-full h-full object-cover"
        loading-strategy="eager"
        :url="`/media/${props.crewMember.avatar}`"
      />
      <SolidUser v-else class="w-full bg-primary-100 fill-primary-400" />
    </div>

    <div v-if="props.crewMember.name || props.crewMember.roles || props.crewMember.bio" class="p-4 rounded-b-lg">
      <h1 v-if="props.crewMember.name" class="!text-2xl font-bold mb-2">
        <SingleLineTextEditor :value="props.crewMember.name" :is-editable="false" />
      </h1>

      <h1 v-if="props.crewMember.roles" class="!text-base font-bold mb-4">
        <SingleLineTextEditor
          v-if="props.crewMember.roles"
          :value="props.crewMember.roles.join(',')"
          :is-editable="false"
        />
      </h1>

      <p v-if="props.crewMember.bio" :class="isClamped ? 'line-clamp-6' : 'line-clamp-none'" class="mb-4">
        <MultiLineTextEditor :value="props.crewMember.bio" placeholder="Enter bio here" :is-editable="false" />
      </p>

      <span class="cursor-pointer text-theme-primary" @click="isClamped = !isClamped">
        {{ isClamped ? 'Show more' : 'Show less' }}
      </span>
    </div>
  </div>
</template>
