<script setup lang="ts">
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import { LayoutTemplate } from '@/sections/types'
import GalleryTab from '@/sections/vessel/hero/GalleryTab.vue'

import { VesselHeroData } from './types'

type VesselHeroConfigurationProps = {
  id: string
  uri: string
  proposalUri: string
  data: VesselHeroData
  layout: LayoutTemplate
}

const props = defineProps<VesselHeroConfigurationProps>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<VesselHeroData>): void
}>()

const update = (event: { field: string; value: string; layout?: LayoutTemplate }): void => {
  // deep copy the data
  const data: VesselHeroData = ObjectUtil.deepCopy(props.data)

  if (event.field === 'images') {
    const images = data.images || []
    data.images = [...images, event.value]
  }

  if (event.field === 'hero') {
    data.hero = event.value
  }

  emit('update:value', { sectionId: props.id, data: data })
}

const deleteImage = (event: { value: string; index: number; layout?: LayoutTemplate }): void => {
  // deep copy the data
  const data: VesselHeroData = ObjectUtil.deepCopy(props.data)

  // Change the hero image to
  let shouldUpdateHero = false
  if (data.images[event.index] === data.hero) {
    shouldUpdateHero = true
  }

  data.images.splice(event.index, 1)
  if (shouldUpdateHero) {
    data.hero = data.images[0] || ''
  }

  emit('update:value', { sectionId: props.id, data: data })
}

const updateByReorder = (event: { from: number; to: number; images: string[] }): void => {
  const data: VesselHeroData = ObjectUtil.deepCopy(props.data)
  if (data.images.length === 1 || event.from === event.to) {
    return
  }

  data.images = event.images
  emit('update:value', { sectionId: props.id, data: data })
}
</script>
<template>
  <GalleryTab
    :proposalUri="props.proposalUri"
    :images="props.data.images || []"
    @update:value="update"
    @delete:value="deleteImage"
    @update:reorder="updateByReorder"
  />
</template>
