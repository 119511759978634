import { getDictionary, saveRawEventsToStowage } from '@ankor-io/calendars-endpoint/src/utils'
import { getYachtPairings } from '@ankor-io/calendars-endpoint/src/yacht.pairings.sql'
import { Dictionary } from '@ankor-io/calendars-scheduler/services/types'
import { BasesDictionary, BookingManagerVesselPairing, MMKCalendar } from '@ankor-io/common/booking-manager/types'
import { Logger, LoggerFactory } from '@ankor-io/common/logging/Log'
import { CalendarEvent, EventSourceEnums, EventTypeEnums } from '@ankor-io/common/vessel/types'

import { Mapper, MapperTypes } from '..'
import { Env } from '../../../env'

const logger: Logger = LoggerFactory.getLogger('calendars-endpoint-booking-manager-mapper')

export const mapper: Mapper<
  MMKCalendar[],
  { companyId: string; provider: MapperTypes; year: number; month: number },
  Env
> = {
  map: async (
    mmkCalendars: MMKCalendar[],
    state: { companyId: string; provider: MapperTypes; year: number; month: number },
    env: Env,
  ) => {
    const companyUri: string = `c::${state.companyId}`
    const bookingManagerVesselPairings: BookingManagerVesselPairing[] = (
      (await getYachtPairings(env, companyUri)) || []
    ).filter((yacht) => yacht.paired)

    const basesDictionary =
      (await getDictionary<BasesDictionary[]>(state.companyId, MapperTypes.BOOKING_MANAGER, Dictionary.BASES, env)) ||
      []

    const all = bookingManagerVesselPairings.map((bookingManagerVesselPairing: BookingManagerVesselPairing) => {
      // Filter reservations with the booking manager yacht id to the reservation yachtId
      const pairedYachtReservations = mmkCalendars.filter(
        (mmkCalendar) => `bm_${mmkCalendar.yachtId}` === bookingManagerVesselPairing.id.toString(),
      )
      if (!pairedYachtReservations.length) {
        logger.info(`No reservations found for booking manager id: ${bookingManagerVesselPairing.id}`)
        return {
          id: bookingManagerVesselPairing.vesselUri!,
          ankor: [],
          raw: [],
        }
      }

      const events = pairedYachtReservations.map((pairedYachtReservation: MMKCalendar) => {
        const ankorEvent: CalendarEvent = transformMMKCalendarToAnkorCalendarEvent(
          pairedYachtReservation,
          basesDictionary,
        )

        return ankorEvent
      })

      return {
        id: bookingManagerVesselPairing.vesselUri!,
        ankor: events,
        raw: pairedYachtReservations,
      }
    })

    const record = arrayToRecord<MMKCalendar, CalendarEvent>(all)

    const rawBookingManagerReservations: Record<string, MMKCalendar[]> = Object.entries(record).reduce(
      (acc, [id, value]) => {
        acc[id] = value.rawBookingManagerReservations
        return acc
      },
      {} as Record<string, MMKCalendar[]>,
    )
    const ankorCalendarEvents: Record<string, CalendarEvent[]> = Object.entries(record).reduce((acc, [id, value]) => {
      acc[id] = value.ankorCalendarEvents
      return acc
    }, {} as Record<string, CalendarEvent[]>)

    // Save raw events into Stowage per vessel
    // eg c::123::vessel::345::feed::booking_manager::calendar::document
    await saveRawEventsToStowage(rawBookingManagerReservations, env, state)

    return {
      ankorCalendarEvents,
    }
  },
}

function arrayToRecord<R, A>(
  items: Array<{ id: string; raw: R[]; ankor: A[] }>,
): Record<string, { rawBookingManagerReservations: R[]; ankorCalendarEvents: A[] }> {
  return items.reduce(
    (
      record: Record<string, { rawBookingManagerReservations: R[]; ankorCalendarEvents: A[] }>,
      item: { id: string; raw: R[]; ankor: A[] },
    ) => {
      record[item.id] = {
        rawBookingManagerReservations: record[item.id]
          ? [...record[item.id].rawBookingManagerReservations, ...item.raw]
          : item.raw,
        ankorCalendarEvents: record[item.id] ? [...record[item.id].ankorCalendarEvents, ...item.ankor] : item.ankor,
      }
      return record
    },
    {} as Record<string, { rawBookingManagerReservations: R[]; ankorCalendarEvents: A[] }>,
  )
}

export const transformMMKCalendarToAnkorCalendarEvent = (
  calendar: MMKCalendar,
  basesDictionary: BasesDictionary[],
): CalendarEvent => {
  let embark
  let disembark

  if (calendar.baseFromId) {
    const baseFrom = basesDictionary.find((base) => base.id === calendar.baseFromId)

    if (baseFrom) {
      embark = {
        name: baseFrom.name,
        country: baseFrom.country,
        coordinates: {
          latitude: Number(baseFrom.latitude),
          longitude: Number(baseFrom.longitude),
        },
      }
    }
  }

  if (calendar.baseToId) {
    const baseTo = basesDictionary.find((base) => base.id === calendar.baseFromId)

    if (baseTo) {
      disembark = {
        name: baseTo.name,
        country: baseTo.country,
        coordinates: {
          latitude: Number(baseTo.latitude),
          longitude: Number(baseTo.longitude),
        },
      }
    }
  }

  const startDate: Date = new Date(calendar.dateFrom)

  let interval = `${startDate.toISOString()}`

  let isAllDay = true
  if (calendar.dateTo) {
    const endDate: Date = new Date(calendar.dateTo)

    interval += `/${endDate.toISOString()}`
    isAllDay = (endDate.getTime() - startDate.getTime()) % 86400000 === 0
  } else {
    interval += `/P1D`
  }

  const calendarEvent: CalendarEvent = {
    id: `bm_${calendar.id.toString()}`, // prefixing booking manager id with "bm_". This ensures there's not conflicts with ids from other systems
    title: calendar.productName,
    interval: interval,
    allDay: isAllDay,
    eventType: EventTypeEnums.BOOKED,
    notes: calendar.remarks,
    embark: embark,
    disembark: disembark,
    reference: `BM - ${calendar.reservationCode}`,
    variantId: undefined, // TODO: Find the corresponding variantId and map here. Need the yacht to lookup
    variantLabel: undefined, // TODO: Find the corresponding variantLabel and map here. Need the yacht to lookup
    source: EventSourceEnums.BOOKING_MANAGER,
    showTitle: true,
  }
  return calendarEvent
}
