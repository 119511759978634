<script lang="ts" setup>
import { Ref, computed, inject, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import ImageCarouselModalEditor from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModalEditor.vue'
import { ChangeEvent, State } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { OutlinePhoto } from '@ankor-io/icons/outline'

import ResponsiveImageEditor from '@/components/image/ResponsiveImageEditor.vue'
import { AuthenticationContext } from '@/iam/types'
import { HeroSectionEditorData } from '@/sections/hero/HeroSectionEditor'
import AssetDropOnly from '@/sections/hero/components/AssetDropOnly.vue'
import OverlappedHeroTitleEditor from '@/sections/hero/single/HeroTitleEditor.vue'
import EmbeddedHeroTitleEditor from '@/sections/hero/triple-stacked/HeroTitleEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import { linkMedia } from '@/services/MediaService'
import { removeDragCursor } from '@/utils/dragcursor'

type Props = {
  id: string
  uri: string
  proposalUri: string
  data: HeroSectionEditorData
  layout: LayoutTemplate
  state: State
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const carousel: Ref<typeof ImageCarouselModalEditor | null> = ref(null)
const showCarouselModal: Ref<boolean> = ref(false)
const uploadState: Ref<string | null> = ref(null)
const dragoverElement: Ref<string | null> = ref(null)
const draggedHeroImage: Ref<string | null> = ref(null)

const emit = defineEmits<{
  (e: 'open', value: string): void
  (e: 'update:value', value: ChangeEvent<any>): void
  (e: 'update:state', value: State): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

props.lifecycle({
  onHydrated: async () => {
    if (props.data.images?.length) {
      await linkMedia({ authenticationContext }, props.proposalUri, props.data.images)
    }

    if (!props.layout.type) {
      emit('update:layout', { sectionId: props.id, layout: { type: 'single', options: { ...props.layout.options } } })
    }
  },
} as unknown as EditableLifecycleHooks)

props.lifecycle({
  onHydrated: () => {},
} as unknown as EditableLifecycleHooks)

const heroImagesMediaUri =
  computed(() => props.data.heroImages && replacePathToMediaUris(props.proposalUri, ...props.data.heroImages)) || []
const imagesMediaUri =
  computed(() => props.data.images && replacePathToMediaUris(props.proposalUri, ...props.data.images)) || []

const update = (event: { field: string; value: string; imageIndex: number }): void => {
  const data: HeroSectionEditorData = ObjectUtil.deepCopy(props.data)

  if (event.field === 'heroImages') {
    if (data.heroImages.length < 3) {
      for (let i = data.heroImages.length; i < 3; i++) {
        data.heroImages.push('')
      }
    }

    data.heroImages[event.imageIndex] = event.value
  }

  emit('update:value', { sectionId: props.id, data: data })
}

// Updates 2 values, whereas update just updates a single value
const updateByReorder = (event: { from: number; to: number }): void => {
  const data: HeroSectionEditorData = ObjectUtil.deepCopy(props.data)
  // Prevent update if there is only one image
  if (data.heroImages.length === 1 || event.from === event.to || props.layout.type === 'single') {
    return
  }

  const value = ObjectUtil.deepCopy(data.heroImages)
  data.heroImages[event.to] = value[event.from]
  data.heroImages[event.from] = value[event.to]

  emit('update:value', { sectionId: props.id, data: data })
}

const drag = (event: DragEvent): void => {
  if (draggedHeroImage.value !== null) {
    return
  }

  const target = event.target as HTMLElement
  draggedHeroImage.value = target.id
}

const dragover = (event: DragEvent): void => {
  const target = event.target as HTMLElement
  // dropzoneLabel is the incorrect target, so ignore it
  if (dragoverElement.value === target.id || target.id.includes('dropzoneLabel')) {
    return
  }

  if (target.tagName === 'svg') {
    dragoverElement.value = target.parentElement!.id
  } else {
    dragoverElement.value = target.id
  }
}

const drop = (id?: string): void => {
  removeDragCursor()
  if (id || draggedHeroImage.value) {
    const element = document.getElementById(id || draggedHeroImage.value!) as HTMLImageElement
    const prevElementIndex = dragoverElement.value?.split(':')[1] // heroIndex:0

    if (draggedHeroImage.value !== null) {
      updateByReorder({
        from: Number(draggedHeroImage.value.split(':')[1]),
        to: Number(prevElementIndex),
      })
    } else {
      // selectedImage includes entire url path (https://ankor..../1280w)
      const selectedImage = element.dataset.imagePath || element.src
      const url = new URL(selectedImage, window.location.origin)
      const mediaUri = url.pathname.split('/')[2]
      update({ field: 'heroImages', value: mediaUri, imageIndex: Number(prevElementIndex) })
    }
  }

  draggedHeroImage.value = null
  dragoverElement.value = null
}

const openCarousel = () => {
  showCarouselModal.value = true
  carousel.value?.focus()
}
</script>
<template>
  <!-- TRIPLE STACKED LAYOUT -->
  <div
    v-if="props.layout.type === 'triple_stacked'"
    class="itinerary-hero-triple flex flex-col gap-1 relative rounded-lg @sm:rounded-2xl overflow-clip"
  >
    <EmbeddedHeroTitleEditor
      :id="id"
      :data="{
        heading: data.heading,
        subheading: data.subheading,
      }"
      :state="state"
      @update:value="emit('update:value', $event)"
    />

    <!-- Main hero -->
    <AssetDropOnly
      class="w-full h-full"
      :image-index="0"
      :upload-state="uploadState"
      @file:dropped="drop"
      @image:dragover="dragover"
    >
      <div class="h-[22.5rem] @sm:h-full">
        <AssetViewerPlaceholder
          id="heroIndex:0"
          class="w-full col-span-2 object-cover"
          :url="`/media/${heroImagesMediaUri?.[0]}`"
          @drag="drag"
        />
      </div>
    </AssetDropOnly>

    <div class="flex gap-1">
      <!-- Bottom left hero image -->
      <ResponsiveImageEditor
        v-if="heroImagesMediaUri?.[1]"
        id="heroIndex:1"
        :url="`/media/${heroImagesMediaUri[1]}`"
        :index="1"
        :upload-state="uploadState"
        @image:drag="drag"
        @image:drop="drop"
        @image:dragover="dragover"
      />
      <AssetDropOnly
        v-else
        :image-index="1"
        :upload-state="uploadState"
        :style="{ flex: '2 1 0%' }"
        @file:dropped="drop"
        @image:dragover="dragover"
      >
        <AssetViewerPlaceholder id="heroIndex:1" />
      </AssetDropOnly>

      <!-- Bottom right hero image -->
      <ResponsiveImageEditor
        v-if="heroImagesMediaUri?.[2]"
        id="heroIndex:2"
        :url="`/media/${heroImagesMediaUri[2]}`"
        :index="2"
        :upload-state="uploadState"
        @image:drag="drag"
        @image:drop="drop"
        @image:dragover="dragover"
      />
      <AssetDropOnly
        v-else
        :image-index="2"
        :upload-state="uploadState"
        :style="{ flex: '2 1 0%' }"
        @file:dropped="drop"
        @image:dragover="dragover"
      >
        <AssetViewerPlaceholder id="heroIndex:2" />
      </AssetDropOnly>
    </div>

    <!-- Show all button -->
    <button
      v-if="layout.options?.enableGallery && imagesMediaUri.length"
      type="button"
      class="absolute z-10 bottom-5 right-5 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg @sm:rounded-2xl text-sm px-3 py-2 text-center inline-flex items-center"
      @click="openCarousel"
    >
      <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
      Show all photos
    </button>
  </div>

  <!-- SINGLE LAYOUT -->
  <div v-else class="itinerary-hero-single mt-24 relative">
    <!-- Hero Title -->
    <div class="mx-4 @sm:mx-8 relative">
      <OverlappedHeroTitleEditor
        :id="id"
        :data="{
          heading: data.heading,
          subheading: data.subheading,
        }"
        :state="state"
        @update:value="emit('update:value', $event)"
      />
    </div>

    <!-- Main hero -->
    <AssetDropOnly
      class="w-full h-full"
      :image-index="0"
      :upload-state="uploadState"
      @file:dropped="drop"
      @image:dragover="dragover"
    >
      <div class="h-[22.5rem] @sm:h-full">
        <AssetViewerPlaceholder
          id="heroIndex:0"
          class="w-full col-span-2 object-cover overflow-hidden rounded-lg @sm:rounded-2xl"
          :url="`/media/${heroImagesMediaUri?.[0]}`"
          @drag="drag"
        />
      </div>
    </AssetDropOnly>

    <!-- Show all button -->
    <button
      v-if="layout.options?.enableGallery && imagesMediaUri.length"
      type="button"
      class="absolute bottom-20 right-8 @sm:right-12 z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
      @click="openCarousel"
    >
      <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
      Show all photos
    </button>
  </div>

  <Teleport v-if="showCarouselModal" to="body">
    <ImageCarouselModalEditor :slides="imagesMediaUri" @close="showCarouselModal = false" />
  </Teleport>
</template>
