import gql from 'graphql-tag'
import { Component, ComputedOptions, MethodOptions } from 'vue'

import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { formatLength } from '@ankor-io/common/vessel/Formatter'
import { Vessel } from '@ankor-io/common/vessel/types'

import VesselHeroSectionEditorVue from '@/sections/vessel/hero/VesselHeroSectionEditor.vue'
import { VesselHeroData } from '@/sections/vessel/hero/types'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import VesselHeroConfiguration from './VesselHeroConfiguration.vue'

const QUERY = gql`
  query Vessel($URI: String!) {
    vessel(uri: $URI) {
      uri
      yachtType
      blueprint {
        name
        length
        sleeps
        cabins
        builtYear
        images
        make
        model
      }
    }
  }
`

const getImages = (vessel: Vessel): string[] => {
  if (!vessel.blueprint?.images) {
    return []
  }
  return replacePathToMediaUris(vessel.uri, ...vessel.blueprint.images)
}

export class VesselHeroSectionEditor extends ProposalAbstractEditableSection<VesselHeroData, EditableProposal> {
  async fetchData(): Promise<VesselHeroData> {
    const vesselUri = this.getSlideUri()
    const client = await this.client()
    return client
      .query({
        query: QUERY,
        variables: { URI: vesselUri },
      })
      .then(({ data }) => {
        const images = getImages(data.vessel)
        return Promise.resolve({
          name: data.vessel.blueprint?.name || '',
          type: data.vessel.yachtType?.join(', ') || '',
          length: formatLength(data.vessel.blueprint?.length || 0),
          sleeps: (data.vessel.blueprint?.sleeps || 0).toString(),
          cabins: (data.vessel.blueprint?.cabins || 0).toString(),
          crew: (data.vessel.blueprint?.crew || 0).toString(),
          builtYear: (data.vessel.blueprint?.builtYear || 0).toString(),
          hero: images[0],
          images: images,
          model: data.vessel.blueprint?.model || '',
          builder: data.vessel.blueprint?.make || '',
        })
      })
      .catch((err) => {
        console.error(`Error fetching vessel with URI: ${this.getSlideUri()}`, err)
        return Promise.resolve({
          name: '',
          type: '',
          length: '',
          sleeps: '',
          cabins: '',
          crew: '',
          builtYear: '',
          hero: '',
          images: [],
          model: '',
          builder: '',
        })
      })
  }

  getType(): SectionType {
    return SectionType.VESSEL_HERO
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return VesselHeroSectionEditorVue
  }

  getConfigurationComponent(): Component<any, any, any, ComputedOptions, MethodOptions> | null {
    return VesselHeroConfiguration
  }
}
