<script setup lang="ts">
import { Ref, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import { State } from '@ankor-io/common/events/Editor'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

type Props = {
  /**
   * The place name
   */
  name: string
  /**
   * The place description
   */
  description: string
  /**
   * The location image
   */
  image?: string
  /**
   * A boolean representing whether this card is selected or not
   */
  selected: boolean
  /**
   * Represents the segment label
   */
  label: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: { field: string; value: string }): void
}>()

const stateRef: Ref<State> = ref(State.READ)
const isClamped: Ref<boolean> = ref(true)

const toggleClamp = () => {
  isClamped.value = !isClamped.value
}
</script>
<template>
  <div
    class="rounded-lg @sm:rounded-2xl overflow-y-auto bg-white w-full max-w-sm min-h-77 border border-gray-200 dark:border-gray-600"
    :class="selected ? 'shadow-[0_0_5px_5px] mt-1.5 shadow-theme-primary' : 'shadow-md'"
  >
    <div class="h-40">
      <AssetViewerPlaceholder
        class="w-full object-cover"
        :url="`/media/${props.image}`"
        :width-descriptors="['640w']"
      />
    </div>
    <div class="px-4 py-3">
      <h1 class="!text-xl font-bold flex flex-wrap gap-x-1">
        <span class="cursor-default">{{ props.label }}:</span>
        <SingleLineTextEditor
          class="self-center"
          placeholder="Enter a place name"
          :value="props.name"
          @update:value="emit('update:value', { field: 'name', value: $event })"
        />
      </h1>
      <div class="text-base">
        <p :class="isClamped && stateRef === State.READ ? 'line-clamp-3' : 'line-clamp-none'">
          <MultiLineTextEditor
            placeholder="Enter a place description"
            :value="props.description"
            @click.stop="stateRef = State.EDIT"
            @on:blur="stateRef = State.READ"
            @update:value="emit('update:value', { field: 'description', value: $event })"
          />
        </p>
        <template v-if="stateRef === State.READ && props.description">
          <span v-if="isClamped" class="cursor-pointer text-theme-primary" @click="toggleClamp"> Show more </span>
          <span v-else class="cursor-pointer text-theme-primary" @click="toggleClamp"> Show less </span>
        </template>
      </div>
    </div>
  </div>
</template>
