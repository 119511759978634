<script setup lang="ts">
import { Ref, inject, ref } from 'vue'

import { Trip } from '@ankor-io/common/trip/Trip'

import { Config, ConfigKey } from '@/config/types'

const props = defineProps<{
  trip: Trip
}>()

const config: Config = inject(ConfigKey)!

const url: Ref<string> = ref(`${config.WAVE_URL}/${props.trip.bookingId}/proposal`)
const linkCopied: Ref<boolean> = ref(false)

const copyLink = () => {
  navigator.clipboard.writeText(url.value)
  linkCopied.value = true
}
</script>
<template>
  <div>
    <h2 class="text-lg font-semibold mb-5 text-gray-900 dark:text-white">Share Brochure</h2>

    <div class="flex flex-col md:flex-row gap-4 text-gray-500 max-w-md">
      Share your brochure by copying the link below.
    </div>

    <div class="flex justify-between items-center space-x-2 rounded-b pt-5">
      <div class="relative flex-1">
        <input
          type="text"
          id="brochure-link"
          disabled
          class="block px-2.5 pb-2 pt-2.5 w-full text-sm bg-transparent rounded-lg border-1 appearance-none focus:outline-none focus:ring-0 peer text-gray-900 dark:text-white"
          placeholder=" "
          :value="url"
        />
        <label
          for="brochure-link"
          class="absolute text-sm cursor-text text-gray-500 dark:text-gray-400 duration-300 transform left-10 -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 dark:bg-gray-800 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1 peer-focus:left-1 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
        >
          Brochure Link
        </label>
      </div>
      <button
        id="confirm-modal"
        type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        @click="copyLink"
      >
        <span v-if="!linkCopied">Copy</span>
        <span v-else>Copied</span>
      </button>
    </div>
  </div>
</template>
